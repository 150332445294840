import "./HotDates.css";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Customized,
} from "recharts";
import { useNavigate } from 'react-router-dom';

const formatDate = (dateString) => {
    const date = new Date(dateString);
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();
    return `${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}-${year}`;
  };

  const CustomDot = ({ cx, cy, payload }) => {
    const navigate = useNavigate()
    const handleClick = () => {
      localStorage.setItem('chartData', JSON.stringify(payload));
      // window.open('/InstantCorrectionPro');
      navigate('/InstantAutomationPro');

    };
  
    return (
      <circle
        cx={cx}
        cy={cy}
        r={5}
        fill="#8884d8"
        stroke="none"
        style={{ cursor: 'pointer' }}
        // onClick={() => navigate(`/InstantCorrectionPro/`, { state: { data: payload } })}
        onClick={handleClick}
      />
    );
  };

const Chart1 = ({ aspect, title, chartdata1 }) => {
  const axisTickStyle = {
    fontSize: 13,
    fontWeight: 'bold',
    fill: 'black'
  };
  const tooltipContentStyle = {
    fontFamily: 'Arial, sans-serif',
    fontSize: '13px',
    fontWeight: 'bold',
    color: 'black'
  };

  const tooltipLabelFormatter = (label) => {
    return formatDate(label);
  };

  const tooltipCursorStyle = {
    strokeWidth: 3, // change this value to adjust the thickness
    stroke: 'rgba(0, 0, 0, 0.2)' // change this to adjust the color
  };

  return (
    <div className="chart">
      <div className="title" style={{color:"black", fontWeight:"bold"}}>{"Car Class - "+title}</div>
      <ResponsiveContainer width="100%" aspect={aspect}>
        <LineChart
          width={730}
          height={250}
          data={chartdata1}
          margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
        >
          <defs>
            <linearGradient id="Average" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
            </linearGradient>
            <linearGradient id="carPrice" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
            </linearGradient>
          </defs>
          <XAxis dataKey="name" stroke="gray" tickFormatter={formatDate} tick={axisTickStyle}/>
          <YAxis stroke="gray" tick={axisTickStyle}/>
          <CartesianGrid strokeDasharray="3 3" className="chartGrid" />
          <Tooltip contentStyle={tooltipContentStyle} labelFormatter={tooltipLabelFormatter} cursor={tooltipCursorStyle}/>
          <Line
            type="monotone"
            dataKey="Average"
            stroke="#309FEB"
            strokeWidth={3}
            fillOpacity={1}
            fill="url(#Average)"
            activeDot={<CustomDot />}
          />
          <Line
            type="monotone"
            dataKey="Rate"
            name="Car Price"
            stroke="#FEB444" //FA4F8C
            strokeWidth={3}
            fillOpacity={1}
            fill="url(#Car Price)"
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default Chart1;