import React, { useState, useEffect, useRef } from 'react'
import { Alert } from "@mui/material";
import Select from "react-select";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import Modal from 'react-bootstrap/Modal'

import * as Yup from 'yup';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import Swal from 'sweetalert2';
import { baseURL } from '../backend_url';
import axios from 'axios';
import { Button, Typography } from '@mui/material';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';



export default function UpdateForm({ accessType }) {

    const [newPrepaid, setNewPrepaid] = useState('')
    const [newPayOnArrival, setNewPayOnArrival] = useState('')
    const [listOfExistingCarClasses, setListOfExistingCarClasses] = useState([])
    const [listOfExistingRecords, setListOfExistingRecords] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredRecords, setFilteredRecords] = useState([]);
    const [currentRecords, setCurrentRecords] = useState([]);
    const recordsPerPage = 10;
    const [validationMsg, setValidationMsg] = useState("");
    const [successMsg, setSuccessMsg] = useState("");
    const [selectedSippCode, setSelectedSippCode] = useState("");
    const [loggedInUserDetail, setLoggedInUserDetail] = useState({});
    const [companyName, setCompanyName] = useState("");
    const [companyName2, setCompanyName2] = useState("");
    const [companyDetail, setcompanyDetail] = useState([{}]);
    const [company_id, setcompany_id] = useState("");
    const [lgShow5, setLgShow5] = useState(false);
    const [locationId, setLocationId] = useState("");
    const [locationName, setLocationName] = useState("");
    const [priceOption, setPriceOption] = useState("");
    const [reportOption, setReportOption] = useState("");
    const [locationDetail, setLocationDetail] = useState([{}]);
    const [reportDetails, setReportDetails] = useState([{}]);
    const [rateUpdateId, setRateUpdateId] = useState("");
    const [rateUpdateResponse, setRateUpdateResponse] = useState({});
    const [reportSetUpResponse, setReportSetUpResponse] = useState({});

    // const [currentPage, setCurrentPage] = useState(1);
    // const recordsPerPage = 10;

   



    const companyNameOptions = companyDetail.map((value) => (
        { value: value.company_name, label: value.company_name }
    ));

    const locationNameOptions = locationDetail.map((value) => (
        { value: value.location_name, label: value.location_name === undefined ? value.location_name : value.location_name + " - " + value.location_id.toString().trim().split("-")[1] }
    ));

    const reportOptions = reportDetails.map((value) => (
        { value: value.report_name, label: value.report_name === undefined ? value.report_name : value.report_name }
    ));

    const sippCodeOptions = listOfExistingCarClasses.map((value) => (
        { value: value.SIPP_CODE, label: value.SIPP_CODE === undefined ? value.SIPP_CODE : value.SIPP_CODE }

        // { value: value.SIPP_CODE, label: SIPP_CODE.company_name }
    ));

    const priceOptions = [
        { id: 1, value: "Base Price", label: "Base Price" },
        { id: 2, value: "Total Price", label: "Total Price" },
      ];

    const handleInputChangeCompanyName = characterEntered => {
        setCompanyName(characterEntered.value);
    };
    const handleInputChangeLocationName = (characterEntered) => {
        setLocationName(characterEntered.value);
    };
    const handleInputChangeReportOption = characterEntered => {
        setReportOption(characterEntered.label);
    };
    const handleInputChangePriceOption = characterEntered => {
        setPriceOption(characterEntered.value);
    };
    
    const formatDate = (date) => {
        return [
            padTo2Digits(date.getMonth() + 1),
            padTo2Digits(date.getDate()),
            date.getFullYear(),
        ].join('-');
    }

    const padTo2Digits = (num) => {
        return num.toString().padStart(2, '0');
    }

    useEffect(() => {
        const loggedInUser = sessionStorage.getItem("user_token");
        const foundUser = JSON.parse(loggedInUser);
        setLoggedInUserDetail(foundUser);
        if (foundUser.role === 'SuperAdmin') {
            axios.get(baseURL + "/get_company_details_for_users")
              .then((res) => {
                setcompanyDetail(res.data);
              });
          }else{
            fetch(baseURL + "/get_company_details_for_users_accessWise/" + foundUser.access['RateDownload_Account Access'] + "*" + foundUser.account_id + "*" + foundUser.account_type.account_type)
              .then((res) => res.json())
              .then((data) => {
                setcompanyDetail(data);
              });
          }

        fetch(baseURL + `/get_existing_car_classes_standard`)
                .then((res) => res.json())
                .then((data) => {
                    if (data.length > 0) {
                        setListOfExistingCarClasses(data)
                    }
                    else {
                        setListOfExistingCarClasses([])
                    }
                })
    }, []);

    useEffect(() => {
        if (companyDetail && companyName) {
    
          let obj_company_detail = companyDetail.filter(
            (value) => value.company_name === companyName
          )[0];
          let temp_company_id = obj_company_detail._id;
          let company_type = obj_company_detail.account_type;
    
          setcompany_id(temp_company_id);
          setLocationName('')
          setLocationId('')
          setListOfExistingRecords([])
          setLocationDetail([{}])
          const requestOptions = {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          };
    
          fetch(baseURL + "/get_company_location/" + temp_company_id, requestOptions)
            .then((res) => res.json())
            .then((data) => {
    
              let allTemp = data.res;
              if (loggedInUserDetail.role === 'SuperAdmin') {
                if (allTemp.length > 0) {
                  setLocationDetail(allTemp);
                }
              }
              else if (company_type === 'Affiliate' && loggedInUserDetail.account_type.account_type === 'Corporate') {
    
                if (allTemp.length > 0) {
                  setLocationDetail(allTemp);
                }
              }
              else {
                axios.post(baseURL + "/get_individual_user_details", { 'user_email': loggedInUserDetail.userEmail })
                  .then((data) => {
                    if (allTemp.length > 0) {
                      allTemp = allTemp.filter((val) => val !== undefined && data.data[0].location_allocated[val.location_id]);
                      setLocationDetail(allTemp);
                    }
                  }
                  )
              }
            });
        }
      }, [companyName]);

    useEffect(() => {
        let temp_location_id = ''
        if (locationName && locationDetail) {
            temp_location_id = locationDetail.filter((loc) => (loc.location_name === locationName)).map((val) => val.location_id)
            setLocationId(temp_location_id);
        }
        setNewPrepaid('')
        setNewPayOnArrival('')
        fetch(baseURL + `/get_existing_report_setup/${temp_location_id}`)
        .then((res) => res.json())
        .then((data) => {
            if (data.length > 0) {
                setReportDetails(data)
            }
            else {
                setReportDetails([])
            }
        })
        fetch(baseURL + `/get_existing_rate_downloads/${temp_location_id}`)
            .then((res) => res.json())
            .then((data) => {
                console.log(data)
                if (data.length > 0) {
                    setListOfExistingRecords(data)
                     // Calculate the index of the first and last record on the current page
                    // const indexOfLastRecord = currentPage * recordsPerPage;
                    // const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
                    // const currentRecords = listOfExistingRecords.slice(indexOfFirstRecord, indexOfLastRecord);

                    // // Calculate total pages
                    // const totalPages = Math.ceil(listOfExistingRecords.length / recordsPerPage);

                    // // Handler to change page
                    // const paginate = (pageNumber) => setCurrentPage(pageNumber);
                }
                else {
                    setListOfExistingRecords([])
                }
            })
     }, [locationName])

    useEffect(() => {
        const lowerCaseSearchTerm = searchTerm.toLowerCase();
        const filtered = listOfExistingRecords.filter((record) =>
            Object.values(record).some((value) =>
                value.toString().toLowerCase().includes(lowerCaseSearchTerm)
            )
        );
        setFilteredRecords(filtered);
        setCurrentPage(1); // Reset to page 1 whenever the search term changes
    }, [searchTerm, listOfExistingRecords]);

    // Calculate pagination details whenever `filteredRecords` or `currentPage` changes
    useEffect(() => {
        const indexOfLastRecord = currentPage * recordsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
        setCurrentRecords(filteredRecords.slice(indexOfFirstRecord, indexOfLastRecord));
    }, [filteredRecords, currentPage]);

    // Calculate total pages based on the filtered records
    const totalPages = Math.ceil(filteredRecords.length / recordsPerPage);

    // Handler to change the page
    const paginate = (pageNumber) => setCurrentPage(pageNumber);


    // const handleExcelDownload = (e) => {
    //      e.preventDefault();
    //     fetch(baseURL + "/get_rate_update_details_by_id/" + rateUpdateId)
    //         .then((res) => res.json())
    //         .then((data) => {
    //             console.log(data)
    //             downloadExcelFile(data);
    //         });
    //     console.log(rateUpdateId)
    //     console.log(reportOption)
    //     console.log(locationId[0])
    // }

    //     e.preventDefault();

    

    const handleExcelDownload = (e) => {
        e.preventDefault();
        console.log('priceOption',priceOption)

        if (reportOption === "") {
            Swal.fire('Please select Report Name');
            return;
        }else if (priceOption === "") {
            Swal.fire('Please select Base/Total Price option');
            return;
        }else{
            fetch(baseURL + "/get_rate_update_details_by_id/" + rateUpdateId+'_'+priceOption)
            .then((res) => res.json())
            .then((data1) => {
                    console.log('data1',data1)
                    fetch(baseURL + "/get_report_setup_by_id/" + data1.location_id+'_'+reportOption)
                    .then((res) => res.json())
                    .then((data) => {
                        console.log('repotrResponse',data)
                        setReportSetUpResponse(data);
                        data1.reportFields = data.selected_report_fields;
                        const accountId = data1.account_name;
                        const pickUpDate = data1.automationStartDate;
                        const dropOffDate = data1.automationEndDate;
                        const locationId = data1.location_name;
                        // const PickUpDate = 'PickUp Date'
                        // const dropOffDate2 = 'DropOff Date'
 
                        // Iterate through each date in datewise_rate_details
                        for (const date in data1.datewise_rate_details) {
                            data1.datewise_rate_details[date].accountId = accountId;
                            data1.datewise_rate_details[date].pickUpDate = pickUpDate;
                            data1.datewise_rate_details[date].dropOffDate = dropOffDate;
                            data1.datewise_rate_details[date].locationId = locationId;
                        }
                        data1['priceOption'] = priceOption
                        console.log('data1:', data1);
                        setRateUpdateResponse(data1);
                        downloadExcel(data1)
                    })
            }); 
            // const updatedData = { ...rateUpdateResponse };
            // updatedData.report_fields = {some :reportSetUpResponse.selected_report_fields };
            // setRateUpdateResponse(updatedData);
            // console.log("updatedData")
            // console.log(updatedData)
            // console.log(rateUpdateResponse)
            // const fromDate = rateUpdateResponse['automation_start_date']
            // const toDate = rateUpdateResponse['automation_end_date']

            // const uniqueKeys = new Set(Object.keys(rateUpdateResponse['datewise_rate_details']).map((key, value) => value.split('_')[0]));
            // const carClass = Array.from(uniqueKeys);
            // console.log(carClass);

            // for (let i = new Date(fromDate).valueOf(); i <= new Date(toDate).valueOf(); i += (1 * 24 * 60 * 60 * 1000)) {
            //     let date = formatDate(new Date(i))
            //         for (let k = 0; k < carClass.length; k++) {
            //             let key = date + "_" + rateCodes[j] + "_" + carClass[k][0];
            //             csvData.push({
            //                 "Sl. No.": counter++,
            //                 "Account Name": accountName,
            //                 "Location Name": locationName,
            //                 "Date": new Date(i).getDate() + "-" + calender[new Date(i).getMonth()] + '-' + new Date(i).getFullYear(),
            //                 "Rate Code": rateCodes[j],
            //                 "Rate Code Name": rateCodeNames[rateCodes[j]],
            //                 "Car Class": carClass[k][0],
            //                 "1 Day": rates[key + "_1Day"] ?? '',
            //                 "2 Day": rates[key + "_2Day"] ?? '',
            //                 "3 Day": rates[key + "_3Day"] ?? '',
            //                 "4 Day": rates[key + "_4Day"] ?? '',
            //                 "5 Day": rates[key + "_5Day"] ?? '',
            //                 "6 Day": rates[key + "_6Day"] ?? '',
            //                 "Daily X-Day": rates[key + "_DailyXDay"] ?? '',
            //                 "Weekly": rates[key + "_Weekly"] ?? '',
            //                 "Weekly X-Day": rates[key + "_WeeklyXDay"] ?? '',
            //                 "Monthly": rates[key + "_Monthly"] ?? '',
            //                 "Monthly X-Day": rates[key + "_MonthlyXDay"] ?? '',
            //                 "Weekend": rates[key + "_Weekend"] ?? '',
            //                 "Hourly": rates[key + "_Hourly"] ?? '',
            //                 "Minute": rates[key + "_Minute"] ?? '',
            //                 "Per Mile/Km": rates[key + "_PerKmMile"] ?? ''
            //             })
            //         }
            // }


            
        }
    }; 
    
    // const downloadExcel4 = (data) => {
    //     const wb = XLSX.utils.book_new();
    
    //     Object.keys(data.datewise_rate_details).forEach(date => {
    //       const rates = data.datewise_rate_details[date];
    //       const wsData = [];
    //       const wsCols = [];
    
    //       Object.entries(rates).forEach(([key, value]) => {
    //         wsCols.push({ wch: key.length });
    //       });
    
    //       wsData.push(Object.keys(rates));
    //       wsData.push(Object.values(rates));
    
    //       const ws = XLSX.utils.aoa_to_sheet(wsData);
    //       ws['!cols'] = wsCols;
    
    //       XLSX.utils.book_append_sheet(wb, ws, date);
    //     });
    
    //     XLSX.writeFile(wb, 'data.xlsx');3
    //   };

    // const downloadExcel = (data) => {
    //     const wb = XLSX.utils.book_new();
    
    //     Object.keys(data.datewise_rate_details).forEach(date => {
    //         const rates = data.datewise_rate_details[date];
    //         const wsData = [];
    //         const wsCols = [];
    
    //         // Collecting unique car class prefixes dynamically
    //         const carClassPrefixes = new Set();
    //         Object.keys(rates).forEach(key => {
    //             const prefix = key.split('_')[0];
    //             carClassPrefixes.add(prefix);
    //         });
    
    //         // Sorting car class prefixes
    //         const sortedCarClassPrefixes = [...carClassPrefixes].sort();
    
    //         // Creating header row for car classes
    //         const carClassHeaderRow = ['Car Class'];
    //         sortedCarClassPrefixes.forEach(prefix => {
    //             Object.keys(rates).forEach(key => {
    //                 if (key.startsWith(prefix)) {
    //                     const header = key.split('_')[1];
    //                     if (!carClassHeaderRow.includes(header)) {
    //                         carClassHeaderRow.push(header);
    //                     }
    //                 }
    //             });
    //         });
    //         wsData.push(carClassHeaderRow);
    
    //         // Creating data rows for car classes
    //         sortedCarClassPrefixes.forEach(prefix => {
    //             const carClassRow = [prefix];
    //             carClassHeaderRow.slice(1).forEach(header => {
    //                 Object.keys(rates).forEach(key => {
    //                     if (key.startsWith(prefix) && key.includes(header)) {
    //                         carClassRow.push(rates[key]);
    //                     }
    //                 });
    //             });
    //             wsData.push(carClassRow);
    //         });
    
    //         // Adding remaining rows for non-car class data
    //         Object.keys(rates).forEach(key => {
    //             if (!key.includes('_')) {
    //                 const row = [key, rates[key]];
    //                 wsData.push(row);
    //             }
    //         });
    
    //         // Creating worksheet
    //         const ws = XLSX.utils.aoa_to_sheet(wsData);
    //         ws['!cols'] = wsCols;
    
    //         // Appending worksheet to workbook
    //         XLSX.utils.book_append_sheet(wb, ws, date);
    //     });
    
    //     XLSX.writeFile(wb, 'data.xlsx');
    // };

    // const downloadExcel = (data) => {
    //     const wb = XLSX.utils.book_new();
    
    //     Object.keys(data.datewise_rate_details).forEach(date => {
    //         const rates = data.datewise_rate_details[date];
    //         const wsData = [];
    //         const wsCols = [];
    
    //         // Collecting unique car class prefixes dynamically
    //         const carClassPrefixes = new Set();
    //         Object.keys(rates).forEach(key => {
    //             const prefix = key.split('_')[0];
    //             carClassPrefixes.add(prefix);
    //         });
    
    //         // Sorting car class prefixes
    //         const sortedCarClassPrefixes = [...carClassPrefixes].sort();
    
    //         // Creating header row for car classes
    //         const carClassHeaderRow = ['Car Class'];
    //         sortedCarClassPrefixes.forEach(prefix => {
    //             Object.keys(rates).forEach(key => {
    //                 if (key.startsWith(prefix)) {
    //                     const header = key.split('_')[1];
    //                     if (!carClassHeaderRow.includes(header)) {
    //                         carClassHeaderRow.push(header);
    //                     }
    //                 }
    //             });
    //         });
    //         wsData.push(carClassHeaderRow);
    
    //         // Creating data rows for car classes
    //         sortedCarClassPrefixes.forEach(prefix => {
    //             const carClassRow = [prefix];
    //             carClassHeaderRow.slice(1).forEach(header => {
    //                 Object.keys(rates).forEach(key => {
    //                     if (key.startsWith(prefix) && key.includes(header)) {
    //                         carClassRow.push(rates[key]);
    //                     }
    //                 });
    //             });
    //             wsData.push(carClassRow);
    //         });
    
    //         // Adding additional data rows
    //         const additionalDataHeaders = Object.keys(data).filter(key => key !== 'datewise_rate_details');
    //         additionalDataHeaders.forEach(header => {
    //             const row = [header, data[header]];
    //             wsData.push(row);
    //         });
    
    //         // Creating worksheet
    //         const ws = XLSX.utils.aoa_to_sheet(wsData);
    //         ws['!cols'] = wsCols;
    
    //         // Appending worksheet to workbook
    //         XLSX.utils.book_append_sheet(wb, ws, date);
    //     });
    
    //     XLSX.writeFile(wb, 'data.xlsx');
    // };
    // const downloadExcel = (data) => {
    //     const wb = XLSX.utils.book_new();
    //     console.log('data:', data)
    //     Object.keys(data.datewise_rate_details).forEach(date => {
    //         const rates = data.datewise_rate_details[date];
    //         const reportFieldData = data.reportFields;
    //         // console.log('reportFieldData:', reportFieldData)
    //         const reportFields = [];

    //         reportFieldData.forEach(item => {
    //             reportFields.push(item.name);
    //         });
    //         const reportFieldsSize =  reportFields.length
    //         const wsData = [];
    //         const wsCols = [];
    //         const additionalDataHeaders = reportFields
    //         console.log('additionalDataHeaders1:', additionalDataHeaders)            
    //         const replacementMap = {
    //             'LOR 1': '1Day',
    //             'LOR 2': '2Day',
    //             'LOR 3': '3Day',
    //             'LOR 4': '4Day',
    //             'LOR 5': '5Day',
    //             'LOR 6': '6Day',
    //         }
    //         const replacedData = additionalDataHeaders.map(item => replacementMap[item] || item);

    //         var index = additionalDataHeaders.findIndex(element => /\d/.test(element));
    //         console.log("index:", index)
    //         if (index !== -1) {
    //             additionalDataHeaders.splice(index, 0, 'Car Class');
    //         }else{
    //             additionalDataHeaders.push('Car Class');
    //         }

    //         const carClassPrefixes = new Set();
    //         Object.keys(rates).forEach(key => {
    //             if (key.includes('_')) {
    //                 const prefix = key.split('_')[0];
    //                 carClassPrefixes.add(prefix);
    //             }
    //         });
    
    //         // Sorting car class prefixes
    //         const sortedCarClassPrefixes = [...carClassPrefixes].sort();
    //         console.log('sortedCarClassPrefixes',sortedCarClassPrefixes)
    
    //         // Creating header row for car classes
    //         sortedCarClassPrefixes.forEach(prefix => {
    //             Object.keys(rates).forEach(key => {
    //                 if (key.startsWith(prefix)) {
    //                     const header = key.split('_')[1];
    //                     if (/\d/.test(header)) {
    //                         const number = parseInt(header.match(/\d+/)[0]);
    //                         if (reportFields.includes('LOR'+''+ number)) {
    //                             if (!additionalDataHeaders.includes(header)) {
    //                                 additionalDataHeaders.push(header);
    //                             }
    //                         }    
    //                     }
    //                     // else{
                
    //                     // } 
    //                 }
    //             });
    //         });
    //         console.log('additionalDataHeaders',additionalDataHeaders)
    //         const additionalDataHeaders2 = additionalDataHeaders.map(item => replacementMap[item] || item);
    //         console.log('additionalDataHeaders2',additionalDataHeaders2)
    //         // console.log('additionalDataHeaders2:', additionalDataHeaders2)
    //         // Pushing the headers to worksheet data
    //         wsData.push(additionalDataHeaders2);
    //         const replacementMap2 = {
    //             'Account': 'accountId',
    //             'Location': 'locationId',
    //             'PickUp Date': 'pickUpDate',
    //             'DropOff Date': 'dropOffDate',
    //             // 'LOR 5': '5Day',
    //             // 'LOR 6': '6Day',
    //         }
    //         const replacedData2 = replacedData.map(item => replacementMap2[item] || item);

    //         var index = replacedData2.findIndex(element => /\d/.test(element));
    //         console.log("index:", index)
    //         if (index !== -1) {
    //             replacedData2.splice(index, 0, 'Car Class');
    //         }else{
    //             replacedData2.push('Car Class');
    //         }
    //         console.log('replacedData2:',replacedData2)
    //         console.log('sortedCarClassPrefixes:',sortedCarClassPrefixes)
    //         sortedCarClassPrefixes.forEach(prefix => {
    //             const carClassRow = [];
    //             console.log('replacedData:',replacedData)
    //             console.log('replacedData2:',replacedData2)
    //             replacedData2.slice(0).forEach(header => {
    //                 let shouldBreak = false;
    //                 if (header == 'Car Class'){
    //                     carClassRow.push(prefix);
    //                     shouldBreak = true;
    //                 }
    //                 if (shouldBreak) {
    //                     return;
    //                 } 
    //                 // XXAR_MonthlyXDay keyParts.includes(headerToCheck)

    //                 Object.keys(rates).forEach(key => {
    //                     let keyParts = key.split('_');
    //                     let secondPart = keyParts[1]; 
    //                     // if (key.startsWith(prefix) && key.includes(header)) {
    //                     if (key.startsWith(prefix) && header.includes(secondPart)) {
    //                         console.log('prefix:', prefix);
    //                         console.log('header:', header);
    //                         console.log('KEY:', key);
    //                         console.log('IM IF');
    //                         console.log('KEY IFF:', rates[key]);
    //                         carClassRow.push(rates[key] ? rates[key] : '');
    //                         shouldBreak = true;
    //                     }
    //                 });

    //                 if(shouldBreak) {
    //                     return;
    //                 }
                
    //                 Object.keys(rates).forEach(key => {
    //                     if (key.includes(header)) {
    //                         console.log('KEY:', key);
    //                         if(key == 'pickUpDate' ||key == 'dropOffDate' ){
    //                             const date = new Date(rates[key]);
    //                             const options = { weekday: 'short', day: '2-digit', month: 'short', year: 'numeric' };
    //                             const formattedDate = date.toLocaleDateString('en-US', options);
    //                             rates[key] = formattedDate
    //                         }else{
    //                             rates[key] = rates[key]
    //                         }
    //                         console.log('KEY22:', rates[key]);
    //                         console.log('IM ELSE');
    //                         carClassRow.push(rates[key] ? rates[key] : '');
    //                         shouldBreak = true;
    //                     }
    //                 });

    //                 if (shouldBreak) {
    //                     return;
    //                 }
    //                 carClassRow.push('');    
    //             });
    //             // console.log('carClassRow:',carClassRow)
    //             // Push the constructed row to wsData
    //             wsData.push(carClassRow);
    //         });
    //         console.log("wsData:",wsData)
    //         // Creating data rows for car classes
    //         // sortedCarClassPrefixes.forEach(prefix => {
    //         //     const carClassRow = [data.account_id || '', data.automationStartDate || '', data.automationId || '', data.automationLors || '', data.automationPickUpTime || '', prefix];
    //         //     additionalDataHeaders.slice(5).forEach(header => {
    //         //         Object.keys(rates).forEach(key => {
    //         //             if (key.startsWith(prefix) && key.includes(header)) {
    //         //                 carClassRow.push(rates[key]);
    //         //             }
    //         //         });
    //         //     });
    //         //     wsData.push(carClassRow);
    //         // });
    
    //         // Creating worksheet
    //         const ws = XLSX.utils.aoa_to_sheet(wsData);
    //         ws['!cols'] = wsCols;
    
    //         // Appending worksheet to workbook
    //         XLSX.utils.book_append_sheet(wb, ws, date);
    //     });
    //     console.log('data.priceOption',data.priceOption)
    //     let downloadedOption = ''
    //     if(data.priceOption == 'Base Price'){
    //         downloadedOption = 'BasePrice.xlsx'
    //     }else{
    //         downloadedOption = 'TotalPrice.xlsx'
    //     }
    //     // console.log('downloadedOption',downloadedOption)
    
    //     // XLSX.writeFile(wb, 'data.xlsx');
    //     XLSX.writeFile(wb, downloadedOption);
    //     setLgShow5(false)
    // };

    const downloadExcel = (data) => {
        const wb = XLSX.utils.book_new();
        
        const replacementMap = {
            'LOR 1': '1Day', 'LOR 2': '2Day', 'LOR 3': '3Day',
            'LOR 4': '4Day', 'LOR 5': '5Day', 'LOR 6': '6Day',
            'Account': 'Account Name', 'Location': 'Location Name',
            'pickUpDate': 'PickUp Date'
        };
    
        Object.keys(data.datewise_rate_details).forEach(date => {
            const rates = data.datewise_rate_details[date];
            const reportFields = data.reportFields.map(item => item.name);
            
            // Map headers and include additional headers
            const additionalDataHeaders = reportFields.map(item => replacementMap[item] || item);
            
            // Insert "Car Class" in the appropriate position
            const index = additionalDataHeaders.findIndex(element => /\d/.test(element));
            if (index !== -1) additionalDataHeaders.splice(index, 0, 'Car Class');
            else additionalDataHeaders.push('Car Class');
    
            // Collect distinct car class prefixes
            const carClassPrefixes = new Set(Object.keys(rates)
                .filter(key => key.includes('_'))
                .map(key => key.split('_')[0])
            );
    
            // Sort car class prefixes
            const sortedCarClassPrefixes = [...carClassPrefixes].sort();
    
            // Add headers for the spreadsheet row
            const wsData = [additionalDataHeaders.map(item => replacementMap[item] || item)];
            
            sortedCarClassPrefixes.forEach(prefix => {
                const carClassRow = [];
                wsData[0].forEach(header => {
                    if (header === 'Car Class') {
                        carClassRow.push(prefix);
                    } else if (header === 'Location Name') {
                        carClassRow.push(data.location_name);
                    } else if (header === 'Account Name') {
                        carClassRow.push(data.account_name);
                    } else if (rates[`${prefix}_${header}`]) {
                        carClassRow.push(rates[`${prefix}_${header}`] || '');
                    } else if (header === 'PickUp Date') {
                        // const formattedPickUpDate = new Date(date).toLocaleDateString('en-US', {
                        //     weekday: 'short', day: '2-digit', month: 'short', year: 'numeric'
                        // });
                        carClassRow.push(date);
                    } else {
                        carClassRow.push('');
                    }
                });
                wsData.push(carClassRow);
            });
    
            // Append sheet for each date
            const ws = XLSX.utils.aoa_to_sheet(wsData);
            XLSX.utils.book_append_sheet(wb, ws, date);
        });
    
        const downloadedOption = data.priceOption === 'Base Price' ? 'BasePrice.xlsx' : 'TotalPrice.xlsx';
        XLSX.writeFile(wb, downloadedOption);
        setLgShow5(false);
    };
    
    
    const downloadExcel666 = (data) => {
        const wb = XLSX.utils.book_new();
        console.log('data2222', data);
        
        const replacementMap = {
            'LOR 1': '1Day', 'LOR 2': '2Day', 'LOR 3': '3Day',
            'LOR 4': '4Day', 'LOR 5': '5Day', 'LOR 6': '6Day',
            'Account': 'Account Name', 'Location': 'Location Name',
            'pickUpDate': 'PickUp Date'
        };
    
        Object.keys(data.datewise_rate_details).forEach(date => {
            const rates = data.datewise_rate_details[date];
            console.log('ratessss', rates);
            const reportFields = data.reportFields.map(item => item.name);
            console.log('reportFields',reportFields)
            const additionalDataHeaders = reportFields.map(item => replacementMap[item] || item);
            console.log('additionalDataHeaders',additionalDataHeaders)
            
            // Insert "Car Class" in the appropriate position
            const index = additionalDataHeaders.findIndex(element => /\d/.test(element));
            if (index !== -1) additionalDataHeaders.splice(index, 0, 'Car Class');
            else additionalDataHeaders.push('Car Class');
    
            const carClassPrefixes = new Set(Object.keys(rates)
                .filter(key => key.includes('_'))
                .map(key => key.split('_')[0])
            );
            console.log('additionalDataHeaders',additionalDataHeaders)
    
            const sortedCarClassPrefixes = [...carClassPrefixes].sort();
            sortedCarClassPrefixes.forEach(prefix => {
                Object.keys(rates).forEach(key => {
                    const header = key.split('_')[1];
                    if (/\d/.test(header) && reportFields.includes(`LOR${parseInt(header.match(/\d+/)[0])}`)) {
                        if (!additionalDataHeaders.includes(header)) {
                            additionalDataHeaders.push(header);
                        }
                    }
                });
            });
    
            const wsData = [additionalDataHeaders.map(item => replacementMap[item] || item)];
            
            sortedCarClassPrefixes.forEach(prefix => {
                const carClassRow = [];
                wsData[0].forEach(header => {
                    if (header === 'Car Class') carClassRow.push(prefix);
                    else if (rates[`${prefix}_${header}`]) carClassRow.push(rates[`${prefix}_${header}`] || '');
                    else if (header === 'PickUp Date') {
                        const formattedPickUpDate = new Date(date).toLocaleDateString('en-US', {
                            weekday: 'short', day: '2-digit', month: 'short', year: 'numeric'
                        });
                        carClassRow.push(formattedPickUpDate);
                    }else carClassRow.push('');
                });
                wsData.push(carClassRow);
            });
    
            const ws = XLSX.utils.aoa_to_sheet(wsData);
            XLSX.utils.book_append_sheet(wb, ws, date);
        });
    
        const downloadedOption = data.priceOption === 'Base Price' ? 'BasePrice.xlsx' : 'TotalPrice.xlsx';
        XLSX.writeFile(wb, downloadedOption);
        setLgShow5(false);
    };
    
   
    


    // submit data
    const submitHandler = (e) => {
        e.preventDefault();
        setSuccessMsg("");
        setValidationMsg("");

        if (companyName === '') {
            Swal.fire('Please select Account Name to proceed!')
            return
        }else if (locationName === '') {
            Swal.fire('Please select Location Name to proceed!')
            return
        }else if (newPrepaid === '') {
            Swal.fire('Please Enter the other name of Prepaid if any!')
            return
        }else if (newPayOnArrival === '') {
            Swal.fire('Please Enter the other name of Pay On Arrival if any!')
            return
        }

        else {
            fetch(baseURL + '/save_rate_code_mapping', {
                method: 'POST', headers: { 'Content-type': 'application/json' },
                body: JSON.stringify({
                    "account_id": company_id.toString(),
                    "location_id": locationId.toString(),
                    "location_name": locationName.toString(),
                    "prepaid": newPrepaid,
                    "pay_on_arrival": newPayOnArrival,
                    "last_updated_by": loggedInUserDetail.userName,
                    "last_updated_date": new Date()
                })
            })
                .then(resp => resp.json())
                .then(data => {
                    console.log(data)
                    if (data === "Duplicate") {
                        setValidationMsg("SIPP CODE already present")
                        window.scrollTo(0, 0);
                    }
                    else {
                        Swal.fire(data)
                        // setNewPrepaid('')
                        // setNewPayOnArrival('')
                        return
                    }
                })
                .catch(error => console.log(error))
        }
    }

    const downloadExcel4 = (i) => {
        console.log(i)
        setRateUpdateId(i)
        setLgShow5(true)
        setReportOption('')
        setPriceOption('')
       
    }


    return (
        <>
        <Modal
                size="lg"
                show={lgShow5}
                onHide={() => setLgShow5(false)}
                aria-labelledby="example-modal-sizes-title-lg"
                dialogClassName="modal-90w"
            >
                <Modal.Header closeButton >
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Rate Download - Format
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <form >
                        {/* <div className="row pt-3"> */}
                        {/* <div className="row pt-2 mx-2">
                            <div className="col-md-2 mb-3">
                                <label htmlFor="company_name" className="form-label" style={{ marginTop: 5 }}>
                                    <b>Selected Report</b>
                                </label>
                            </div>
                            <div className="col-md-4 mb-3">
                                <Select
                                    value={{ value: companyName2, label: (companyName2 ? companyName2 : "Select Report ") }}
                                    inputId="company_name"
                                    name="company_name"
                                    options={priceOptions}
                                    onChange={handleInputChangeCompanyName}
                                />
                            </div>
                        </div> */}
                        <div className="row pt-2 mx-2" >
                            <div className="col-md-2 mb-3">
                                <label htmlFor="locationName" className="form-label" style={{ marginTop: 5 }}>
                                    <b>Select Report Name</b>
                                </label>
                            </div>
                            <div className="col-md-4 mb-3">
                                <Select
                                    value={{ value: reportOption, label: (reportOption ? reportOption : "Select Report Name") }}
                                    inputId="report_name"
                                    name="report_name"
                                    options={reportOptions}
                                    // isDisabled={true} 
                                    onChange={handleInputChangeReportOption}
                                />
                            </div>
                        </div>
                        <div className="row pt-2 mx-2">
                            <div className="col-md-2 mb-3">
                                <label htmlFor="locationName" className="form-label" style={{ marginTop: 5 }}>
                                    <b>Select Base / Total Price</b>
                                </label>
                            </div>
                            <div className="col-md-4 mb-3">
                                <Select
                                    value={{ value: priceOption, label: (priceOption ? priceOption : "Select Base/Total Price") }}
                                    inputId="price_selection"
                                    name="price_selection"
                                    options={priceOptions}
                                    // isDisabled={true} 
                                    onChange={handleInputChangePriceOption}
                                />
                            </div>
                        </div>
                        <div className="row pt-2 mx-2">
                            <div className="col-md-2 mb-3">
                            </div>
                            <div className="col-md-4 mb-3">
                                <button className="btn btn-success" onClick={handleExcelDownload}>Download Excel</button>
                            </div>
                        </div>
                        {/* <div className="" style={{marginLeft:'270px'}}>
                                <button className="btn btn-success" onClick={handleExcelDownload}>Download Excel</button>
                        </div> */}
                    </form>
                </Modal.Body>
            </Modal>
        <div className="row"  style={{fontSize:'12px', color:'black', fontWeight:'600'}}>
            <div className="row pt-2" style={{ marginLeft: '50px' }}>
                <div className="col-md-2 mb-3">
                    <label htmlFor="company_name" className="form-label" style={{ marginTop: 5 }}>
                    Account Name
                    </label>
                </div>
                <div className="col-md-3 mb-3">
                    <Select
                    value={{ value: companyName, label: (companyName ? companyName : "Select Account Name") }}
                    inputId="company_name"
                    name="company_name"
                    options={companyNameOptions}
                    onChange={handleInputChangeCompanyName}
                    />
                </div>
            </div>
            <div className="row pt-2" style={{ marginLeft: '50px' }}>
                <div className="col-md-2 mb-3">
                    <label htmlFor="locationName" className="form-label" style={{ marginTop: 5 }}>
                    Location Name
                    </label>
                </div>
                <div className="col-md-3 mb-3">
                    <Select
                    value={{ value: locationName, label: (locationName ? locationName : "Select Location Name") }}
                    inputId="locationName"
                    name="locationName"
                    options={locationNameOptions}
                    onChange={handleInputChangeLocationName}
                    />
                </div>
            </div>
            
        </div>
            {listOfExistingRecords.length > 0 ? (
            <>
                <div className="row pt-2 mx-2">
                    <div className="col-md-11">
                        <hr style={{ height: '3px' }} />
                    </div>
                </div>

                <div className="row pt-2 mx-2" style={{ marginLeft: '40px' }}>
                    <div className="col-md-8">
                        <h5 className=" mb-1" style={{ marginLeft: '10px' }}>Existing Records:</h5>

                        <table className="table table-hover" style={{ marginLeft: '40px', marginTop: '15px' }}>
                            <thead>
                                <tr>
                                    <th style={{ backgroundColor: 'orange', textAlign: 'center' }} scope="col">Name</th>
                                    {/* <th style={{ backgroundColor: 'orange', textAlign: 'center' }} scope="col">Run Date</th> */}
                                    <th style={{ backgroundColor: 'orange', textAlign: 'center' }} scope="col">From Date</th>
                                    <th style={{ backgroundColor: 'orange', textAlign: 'center' }} scope="col">To Date</th>
                                    <th style={{ backgroundColor: 'orange', textAlign: 'center' }} scope="col">PickUp Time</th>
                                    <th style={{ backgroundColor: 'orange', textAlign: 'center' }} scope="col">Lor's</th>
                                    <th style={{ backgroundColor: 'orange', textAlign: 'center' }} scope="col">Run Type</th>
                                    <th style={{ backgroundColor: 'orange', textAlign: 'center' }} scope="col">Download</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentRecords.map((val, i) => (
                                    <tr key={val._id}>
                                        <td style={{ backgroundColor: '#cfe2f3', textAlign: 'center' }}>
                                            {val.automation_name ? val.automation_name : '-'}
                                        </td>
                                        {/* <td style={{ backgroundColor: '#cfe2f3', textAlign: 'center' }}>{val.automationPickUpTime}</td> */}
                                        {/* <td style={{ backgroundColor: '#cfe2f3', textAlign: 'center' }}>{formatDate(new Date(val.created_date))}</td> */}
                                        <td style={{ backgroundColor: '#cfe2f3', textAlign: 'center' }}>{formatDate(new Date(val.automationStartDate))}</td>
                                        <td style={{ backgroundColor: '#cfe2f3', textAlign: 'center' }}>{formatDate(new Date(val.automationEndDate))}</td>
                                        <td style={{ backgroundColor: '#cfe2f3', textAlign: 'center' }}>{val.automationPickUpTime}</td>
                                        <td style={{ backgroundColor: '#cfe2f3', textAlign: 'center' }}>{Array.isArray(val.automationLors) ? val.automationLors.join(', ') : val.automationLors}</td>
                                        <td style={{ backgroundColor: '#cfe2f3', textAlign: 'center' }}>{val.type_of_upload}</td>
                                        <td style={{ backgroundColor: '#cfe2f3', textAlign: 'center' }}>
                                            <button className="btn success" onClick={() => downloadExcel4(val._id)}>
                                                <CloudDownloadIcon />
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        {/* Pagination Controls */}
                        <nav>
                            <ul className="pagination">
                                {[...Array(totalPages)].map((_, i) => (
                                    <li key={i} className={`page-item ${currentPage === i + 1 ? 'active' : ''}`}>
                                        <button onClick={() => paginate(i + 1)} className="page-link">
                                            {i + 1}
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        </nav>
                    </div>
                </div>
            </>
        ) : ''
        }
        </>
    );
}
