import React, { useState, useEffect, useRef } from 'react'
import { Alert } from "@mui/material";
import Select from "react-select";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import * as Yup from 'yup';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import Swal from 'sweetalert2';
import { baseURL } from '../backend_url';
import axios from 'axios';


export default function UpdateForm({ accessType }) {

    const [newSippCode, setNewSippCode] = useState('')
    const [listOfExistingCarClasses, setListOfExistingCarClasses] = useState([])
    const [validationMsg, setValidationMsg] = useState("");
    const [successMsg, setSuccessMsg] = useState("");
    const [loggedInUserDetail, setLoggedInUserDetail] = useState({});

    const formatDate = (date) => {
        return [
            padTo2Digits(date.getMonth() + 1),
            padTo2Digits(date.getDate()),
            date.getFullYear(),
        ].join('-');
    }

    const padTo2Digits = (num) => {
        return num.toString().padStart(2, '0');
    }

    //API call
    useEffect(() => {
        const loggedInUser = sessionStorage.getItem("user_token");
        const foundUser = JSON.parse(loggedInUser);
        setLoggedInUserDetail(foundUser);

        fetch(baseURL + `/get_existing_car_classes_standard`)
                .then((res) => res.json())
                .then((data) => {
                    if (data.length > 0) {
                        setListOfExistingCarClasses(data)
                    }
                    else {
                        setListOfExistingCarClasses([])
                    }
                })
    }, []);

    // submit data
    const submitHandler = (e) => {
        e.preventDefault();
        setSuccessMsg("");
        setValidationMsg("");

        if (newSippCode === '') {
            Swal.fire('Please Enter SIPP CODE to proceed!')
            return;
        }
        else if (newSippCode.length <= 3) {
            Swal.fire('Required SIPP code of 4 characters')
            return;
        }
        else {
            fetch(baseURL + '/save_fleet_standard', {
                method: 'POST', headers: { 'Content-type': 'application/json' },
                body: JSON.stringify({
                    "newSippCode": newSippCode,
                    "last_updated_by": loggedInUserDetail.userName,
                    "last_updated_date": new Date()
                })
            })
                .then(resp => resp.json())
                .then(data => {
                    console.log("data")
                    console.log(data)
                    if (data === "Duplicate") {
                        Swal.fire('Entered SIPP code is already present')
                        return;
                    }
                    else {
                        Swal.fire('SIPP Code added successfully!')
                        // setSuccessMsg("Fleet Standard saved successfully!")
                        setListOfExistingCarClasses(data);
                        setNewSippCode('')
                        // window.scrollTo(0, 0)
                    }
                })
                .catch(error => console.log(error))
        }
    }

    const deleteCarClasses = (i) => {
        Swal.fire({
            title: 'Delete this SIPP CODE?',
            showCancelButton: true,
            confirmButtonText: 'Yes',
        }).then((result) => {
            if (result.isConfirmed) {
                let ID = ''
                listOfExistingCarClasses.map((arr1d, index) => {
                    if (i === index) {
                        ID = arr1d['_id']
                    }
                })

                fetch(baseURL + `/delete_car_class_standard/${ID + "___" + loggedInUserDetail.userName}`)
                    .then((res) => res.json())
                    .then((data) => {
                        if (data.length > 0) {
                            setListOfExistingCarClasses(data)
                            Swal.fire('SIPP CODE deleted Succesfully');
                        }
                        else {
                            setListOfExistingCarClasses([])
                        }
                    })
            }
            else if (result.isDenied) {
                Swal.fire('SIPP CODE not deleted!');
            }
        })
    }


    return (
        <>
        <div className="row" style={{fontSize:'12px', color:'black', fontWeight:'600'}}>
            <form className="row g-3">
                <div className="row pt-1 mx-2">
                    <div className="col-md-10">
                        {
                            validationMsg ?
                                (<Alert severity="error">{validationMsg}!</Alert>) : ""
                        }

                        {successMsg ?
                            (
                                <div className="alert alert-success alert-dismissible fade show" role="alert">
                                    {successMsg}
                                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => setSuccessMsg('')}></button>
                                </div>
                            )
                            : ""
                        }
                        <div className="row">
                            <div className="col-md-3 mb-3">
                                <label htmlFor="new_sipp" className="form-label" style={{ marginTop: 5 }}>
                                    Add New SIPP Code :
                                </label>
                            </div>
                            <div className="col-md-2 mb-2">
                                <input type="text"className='form-control' id="new_sipp" minLength={4} maxLength={4} value={newSippCode} onChange={(e) => setNewSippCode(e.target.value)} />
                            </div>
                        </div>
                    </div>
                </div>
                {accessType === 'View' ? "" :
                    <>
                        <div className="row pt-3">
                            <div className="col-md-3"></div>
                            <div className="col-md-2">
                                <button type="submit" className="btn btn-success" onClick={submitHandler}>
                                    Submit
                                </button>
                            </div>
                        </div>
                    </>
                }
            </form>
        </div>

            {listOfExistingCarClasses.length > 0 ?
                <>
                    <div className="row pt-2 mx-2">
                        <div className="col-md-11">
                            <hr style={{ height: '3px' }} />
                        </div>
                    </div>

                    <div className="row pt-2 mx-2" style={{fontSize:'12px', color:'black', fontWeight:'600'}}>
                        <div className="col-md-8">
                            <h5 className=" mb-1" style={{ marginLeft: '-5px'}}>Existing SIPP Codes :</h5>

                            <table className="table table-hover" style={{marginTop: '16px'}}>
                                <thead>
                                    <tr>
                                        <th style={{backgroundColor:'orange'}} scope="col">SIPP Code</th>
                                        <th style={{backgroundColor:'orange'}} scope="col">Updated By</th>
                                        <th style={{backgroundColor:'orange'}} scope="col">Updated On</th>
                                        <th style={{backgroundColor:'orange'}} scope="col">Delete</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        listOfExistingCarClasses.map((val, i) => (
                                            <tr>
                                                <td>{val.SIPP_CODE}</td>
                                                <td>{val.last_updated_by}</td>
                                                <td>{formatDate(new Date(val.last_updated_date))}</td>
                                                
                                                <td><button className="btn success" disabled={accessType === "View"}  onClick={(e) => deleteCarClasses(i)}> <DeleteForeverIcon /></button></td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </> : ''
            }
        </>
    );
}
