import React, { useState, useEffect, useRef } from 'react'
import { Alert } from "@mui/material";
import Select from "react-select";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import * as Yup from 'yup';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import Swal from 'sweetalert2';
import { baseURL } from '../backend_url';
import axios from 'axios';

// const App = () => {
export default function UpdateForm({ accessType }) {

    const [availableFields, setAvailableFields] = useState([
        { name: 'Account', selected: false },
        { name: 'Location', selected: false },
        { name: 'PickUp Date', selected: false },
        { name: 'LOR 1', selected: false },
        { name: 'LOR 2', selected: false },
        { name: 'LOR 3', selected: false },
        { name: 'LOR 4', selected: false },
        { name: 'LOR 5', selected: false },
        { name: 'LOR 6', selected: false },
        { name: 'Weekly', selected: false },
        { name: 'Monthly', selected: false }
    ]);
    const [availableFields2, setAvailableFields2] = useState([
        { name: 'Account', selected: false },
        { name: 'Location', selected: false },
        { name: 'PickUp Date', selected: false },
        { name: 'LOR 1', selected: false },
        { name: 'LOR 2', selected: false },
        { name: 'LOR 3', selected: false },
        { name: 'LOR 4', selected: false },
        { name: 'LOR 5', selected: false },
        { name: 'LOR 6', selected: false },
        { name: 'Weekly', selected: false },
        { name: 'Monthly', selected: false }
    ]);
    const formatDate = (date) => {
        return [
            padTo2Digits(date.getMonth() + 1),
            padTo2Digits(date.getDate()),
            date.getFullYear(),
        ].join('-');
    }
    const padTo2Digits = (num) => {
        return num.toString().padStart(2, '0');
    }
    const [companyName, setCompanyName] = useState("");
    const [reportName, setReportName] = useState("");
    const [companyDetail, setcompanyDetail] = useState([{}]);
    const [company_id, setcompany_id] = useState("");
    const [locationId, setLocationId] = useState("");
    const [reportFormat, setReportFormat] = useState(false);
    const [disabledValue, setDisabledValue] = useState(false);
    const [editedReport, setEditedReport] = useState("NO");
    const [locationName, setLocationName] = useState("");
    const [locationDetail, setLocationDetail] = useState([{}]);
    const [loggedInUserDetail, setLoggedInUserDetail] = useState({});
    const [listOfExistingReportSetUp, setListOfExistingReportSetUp] = useState([])



    const companyNameOptions = companyDetail.map((value) => (
        { value: value.company_name, label: value.company_name }
    ));

    const locationNameOptions = locationDetail.map((value) => (
        { value: value.location_name, label: value.location_name === undefined ? value.location_name : value.location_name + " - " + value.location_id.toString().trim().split("-")[1] }
    ));
    const handleInputChangeCompanyName = characterEntered => {
        setCompanyName(characterEntered.value);
    };
    const handleInputChangeLocationName = (characterEntered) => {
        setLocationName(characterEntered.value);
    };

    const [selectedFields, setSelectedFields] = useState([]);

    // const handleMoveToSelected = () => {
    //     const selected = availableFields.filter(field => field.selected); // Get selected items
    //     const updatedAvailableFields = availableFields.filter(field => !field.selected); // Remove selected items from availableFields
    
    //     setAvailableFields(updatedAvailableFields); // Update availableFields state
    //     setSelectedFields([...selectedFields, ...selected]); // Add selected items to selectedFields
        
    //     // Reset selected state of moved items in availableFields
    //     const resetSelectedAvailable = availableFields.map(field => ({
    //         ...field,
    //         selected: false
    //     }));
    //     setAvailableFields(resetSelectedAvailable); // Update availableFields state with reset selected state
    // };

    const handleMoveToSelected = () => {
        // const selected = availableFields.filter(field => field.selected);
        const selected = availableFields
        .filter(field => field.selected)
        .map(field => ({ ...field, selected: false })); // Set selected to false for each field

        console.log('selected',selected)
        console.log('availableFields',availableFields)
        console.log('selectedFields',selectedFields)
        setAvailableFields(availableFields.filter(field => !field.selected));
        console.log('availableFields2',availableFields)
        setSelectedFields([...selectedFields, ...selected]);
        console.log('selectedFields2',selectedFields)
    };


    const createNewReport = () => {
        setAvailableFields(availableFields2)
        setSelectedFields([])
        setReportFormat(true)
        setDisabledValue(false);
        setEditedReport("NO");
        setReportName('');
        // const selected = availableFields.filter(field => field.selected);
        // setAvailableFields(availableFields.filter(field => !field.selected));
        // setSelectedFields([...selectedFields, ...selected]);
    };

    const handleMoveToAvailable = () => {
        // const toMove = selectedFields.filter(field => field.selected);
        const toMove = selectedFields
        .filter(field => field.selected)
        .map(field => ({ ...field, selected: false })); // Set selected to false for each field

        setSelectedFields(selectedFields.filter(field => !field.selected));
        setAvailableFields([...availableFields, ...toMove]);
    };

    const toggleSelected = (index, source) => {
        const fields = source === 'available' ? [...availableFields] : [...selectedFields];
        fields[index].selected = !fields[index].selected;
        source === 'available' ? setAvailableFields(fields) : setSelectedFields(fields);
    };

    const editRateCodeSetup = (i) => {
        // setAvailableFields('')
        // setDisabledValue(true)
        // setSelectedFields([]);
        Swal.fire({
            title: 'Edit this Report?',
            showCancelButton: true,
            confirmButtonText: 'Yes',
        }).then((result) => {
            if (result.isConfirmed) {
                let ID = ''
                listOfExistingReportSetUp.map((arr1d, index) => {
                    if (i === index) {
                        ID = arr1d['_id']
                    }
                })
                async function clearAndFetch() {
                    setReportFormat(true)
                    try {
                      // Clear selectedFields immutably (if applicable)
                      const clearedFields = [...selectedFields]; // Create a copy
                      clearedFields.length = 0; // Clear the copy immutably
                      setSelectedFields(clearedFields);
                  
                      // Wait for state update to complete
                      await new Promise((resolve) => setTimeout(resolve, 0)); // Optional delay
                  
                      const response = await fetch(baseURL + `/get_report_setup_by_id/${ID}`);
                      const data = await response.json();
                      console.log(data)
                  
                      if (data !== null) {
                        console.log(data)
                        setReportName(data.report_name);
                        setSelectedFields(data.selected_report_fields); // Update immutably or with callback
                        const selectedReportFields = data.selected_report_fields;
                        const newAvailableFields = availableFields.filter(field => !selectedReportFields.some(selectedField => selectedField.name === field.name));
                        setAvailableFields(newAvailableFields);
                        setDisabledValue(true);
                        setEditedReport("YES");
                      } else {
                        setSelectedFields([]);
                        setListOfExistingReportSetUp([]);
                      }
                    } catch (error) {
                      // Handle error appropriately
                      console.error("Error fetching data:", error);
                    }
                  } 
                clearAndFetch()  
                  


                // const clearedFields = [];
                // setSelectedFields(clearedFields);
                // setSelectedFields([], () => {
                // fetch(baseURL + `/get_report_setup_by_id/${ID}`)
                //     .then((res) => res.json())
                //     .then((data) => {
                //         // const selectedReportFields = []
                //         // setSelectedFields([...selectedFields, ...selectedReportFields]);
                //         if (data !==  null) {
                //             setReportName(data.report_name)
                //             let selectedReportFields = data.selected_report_fields;
                //             // Array.from(new Set([...selectedFields, ...newData]));
                //             setSelectedFields([...selectedFields, ...selectedReportFields]);
                //             const newAvailableFields = availableFields.filter(field => !selectedReportFields.some(selectedField => selectedField.name === field.name));
                //             setAvailableFields(newAvailableFields);
                            
                //             setDisabledValue(true)
                //             setEditedReport("YES")
                //         }
                //         else {
                //             setListOfExistingReportSetUp([])
                //             setSelectedFields([]);
                //         }
                //     })
                // });
            }
            // else if (result.isDenied) {
            //     Swal.fire('Rate Class not deleted!');
            // }
        })
    }


    const handleSubmit = () => {
        const selectedFields2 =selectedFields
        // const selectedFields2 =selectedFields.filter(field => field.selected)
        // console.log(selectedFields2);
        // return "kjhgf"
        if (companyName === '') {
            Swal.fire("Please select Account Name to proceed!")
            return
        }else if (locationName === '') {
            Swal.fire("Please select Location Name to proceed!")
            return
        }else if (reportName === '') {
            Swal.fire("Please Enter Report Name to proceed!")
            return
        }else if (selectedFields2.length === 0 ) {
            Swal.fire("Please select Any fields!")
            return
        }else {
            fetch(baseURL + '/save_report_setup', {
                method: 'POST', headers: { 'Content-type': 'application/json' },
                body: JSON.stringify({
                    "account_id": company_id.toString(),
                    "location_id": locationId.toString(),
                    "location_name": locationName.toString(),
                    "report_name": reportName,
                    "editedReport": editedReport,
                    "selected_report_fields": selectedFields2,
                    "last_updated_by": loggedInUserDetail.userName,
                    "last_updated_date": new Date()
                })
            })
                .then(resp => resp.json())
                .then(data => {
                    console.log("data")
                    console.log(data)
                    if (data === "Duplicate") {
                        Swal.fire("Already a Report with the given Name is available for the Current Location")
                        return
                    }
                    else {
                        if(editedReport == "YES"){
                            Swal.fire("Report Setup updated successfully")
                            
                        }else{
                            Swal.fire("Report Setup saved successfully")
                            fetch(baseURL + `/get_existing_report_setup/${locationId}`)
                            .then((res) => res.json())
                            .then((data) => {
                                if (data.length > 0) {
                                    setListOfExistingReportSetUp(data)
                                }
                                else {
                                    setListOfExistingReportSetUp([])
                                }
                            })
                        }
                        listOfExistingReportSetUp(data);
                        
                        // setSuccessMsg("Fleet SetUp saved successfully!")
                        
                        // setSelectedSippCode('')
                        // window.scrollTo(0, 0)
                    }
                })
                .catch(error => console.log(error))
        }

        // Extract data of selected fields here
        console.log('Selected Fields:', selectedFields.filter(field => field.selected));
      };

    useEffect(() => {
        const loggedInUser = sessionStorage.getItem("user_token");
        const foundUser = JSON.parse(loggedInUser);
        setLoggedInUserDetail(foundUser);
        if (foundUser.role === 'SuperAdmin') {
            axios.get(baseURL + "/get_company_details_for_users")
            .then((res) => {
                setcompanyDetail(res.data);
            });
        }else{
            console.log("im heree")
            fetch(baseURL + "/get_company_details_for_users_accessWise/" + foundUser.access['ReportSetUp_Account Access'] + "*" + foundUser.account_id + "*" + foundUser.account_type.account_type)
            .then((res) => res.json())
            .then((data) => {
                setcompanyDetail(data);
            });
        }

        // fetch(baseURL + `/get_existing_car_classes_standard`)
        //         .then((res) => res.json())
        //         .then((data) => {
        //             if (data.length > 0) {
        //                 // setListOfExistingCarClasses(data)
        //             }
        //             else {
        //                 // setListOfExistingCarClasses([])
        //             }
        //         })
    }, []);

    useEffect(() => {
        if (companyDetail && companyName) {

        let obj_company_detail = companyDetail.filter(
            (value) => value.company_name === companyName
        )[0];
        let temp_company_id = obj_company_detail._id;
        let company_type = obj_company_detail.account_type;

        setcompany_id(temp_company_id);
        setLocationName('')
        setLocationId('')
        setLocationDetail([{}])
        const requestOptions = {
            method: "GET",
            headers: {
            "Content-Type": "application/json",
            },
        };

        fetch(baseURL + "/get_company_location/" + temp_company_id, requestOptions)
            .then((res) => res.json())
            .then((data) => {
            let allTemp = data.res;
            if (loggedInUserDetail.role === 'SuperAdmin') {
                if (allTemp.length > 0) {
                setLocationDetail(allTemp);
                }
            }
            else if (company_type === 'Affiliate' && loggedInUserDetail.account_type.account_type === 'Corporate') {

                if (allTemp.length > 0) {
                setLocationDetail(allTemp);
                }
            }
            else {
                axios.post(baseURL + "/get_individual_user_details", { 'user_email': loggedInUserDetail.userEmail })
                .then((data) => {
                    console.log("data");
                    console.log(data);
                    if (allTemp.length > 0) {
                    allTemp = allTemp.filter((val) => val !== undefined && data.data[0].location_allocated[val.location_id]);
                    setLocationDetail(allTemp);
                    }
                }
                )
            }
            });
        }
    }, [companyName]);

    useEffect(() => {
        let temp_location_id = ''
        if (locationName && locationDetail) {
            temp_location_id = locationDetail.filter((loc) => (loc.location_name === locationName)).map((val) => val.location_id)
            setLocationId(temp_location_id);
        }
        setReportFormat(false)

        // setSelectedSippCode('')
        fetch(baseURL + `/get_existing_report_setup/${temp_location_id}`)
            .then((res) => res.json())
            .then((data) => {
                if (data.length > 0) {
                    setListOfExistingReportSetUp(data)
                }
                else {
                    setListOfExistingReportSetUp([])
                }
            })
    }, [locationName])

    useEffect(() =>{
        console.log(reportName)
        setReportName(reportName)
        console.log(selectedFields)
        setSelectedFields(selectedFields)
        console.log(selectedFields)

    },[reportName, selectedFields])


  return (
    <>
    <div  style={{fontSize:'12px', color:'black', fontWeight:'600'}}>
        <div className="row pt-2" style={{ marginLeft: '50px' }}>
            <div className="col-md-2 mb-3">
                <label htmlFor="company_name" className="form-label" style={{ marginTop: 5 }}>
                Account Name
                </label>
            </div>
            <div className="col-md-3 mb-3">
                <Select
                value={{ value: companyName, label: (companyName ? companyName : "Select Account Name") }}
                inputId="company_name"
                name="company_name"
                options={companyNameOptions}
                onChange={handleInputChangeCompanyName}
                />
            </div>
        </div>
        <div className="row pt-2" style={{ marginLeft: '50px' }}>
            <div className="col-md-2 mb-3">
                <label htmlFor="locationName" className="form-label" style={{ marginTop: 5 }}>
                Location Name
                </label>
            </div>
            <div className="col-md-3 mb-3">
                <Select
                value={{ value: locationName, label: (locationName ? locationName : "Select Location Name") }}
                inputId="locationName"
                name="locationName"
                options={locationNameOptions}
                onChange={handleInputChangeLocationName}
                />
            </div>
        </div>
        <div className="row pt-2" style={{ marginLeft: '50px' }}>
        { accessType === 'View' ? "" :
            <>
                <div className="col-md-3 mb-3">
                    <button style={{backgroundColor:"skyblue"}} onClick={createNewReport}>
                        Create New Report Format
                    </button>
                </div>
            </>
        }
        </div>
        { 
            reportFormat ?
            <>
            <div  className="row pt-4" style={{ marginLeft: '180px' }}>
                <div className="row">
                    <div className="col-md-2 mb-3">
                        <label htmlFor="new_sipp" className="form-label" style={{ marginTop: 5 }}>
                            <b>Report Name</b>
                        </label>
                    </div>
                    <div className="col-md-2 mb-2" style={{marginLeft: "2%"}}>
                        <input type="text"className='form-control' id="report_name2" value={reportName} disabled={disabledValue}  onChange={(e) => setReportName(e.target.value)} />
                    </div>
                </div>
                <div className="row pt-4">
                    <div className="col-md-4 mb-4">
                        <h5 className="mb-3" style={{ textDecoration: 'underline'}}>Available Fields</h5>
                        <table className="table" style={{marginTop:'30px'}}>
                            <thead>
                                <tr>
                                    <th style={{ backgroundColor: 'orange' }}>Field</th>
                                    <th style={{ backgroundColor: 'orange' }} className="text-center">Selected</th>
                                </tr>
                            </thead>
                            <tbody>
                                {availableFields.map((field, index) => (
                                <tr key={index}>
                                    <td style={{ backgroundColor: '#cfe2f3' }}>{field.name}</td>
                                    <td style={{ backgroundColor: '#cfe2f3' }} className="text-center">
                                        <input
                                            type="checkbox"
                                            checked={field.selected}
                                            onChange={() => toggleSelected(index, 'available')}
                                        />
                                    </td>
                                </tr>
                                ))}
                            </tbody>
                        </table>
                        <button className="btn btn-primary" onClick={handleMoveToSelected}>Move</button>
                    </div>
                    <div className="col-md-4 mb-4" style={{marginLeft : '40px'}}>
                        <h5 className="mb-3" style={{ textDecoration: 'underline' }}>Selected Fields</h5>
                        <table className="table" style={{marginTop:'30px'}}>
                            <thead>
                                <tr>
                                    <th style={{ backgroundColor: 'orange' }}>Field</th>
                                    <th style={{ backgroundColor: 'orange' }} className="text-center">Remove</th>
                                </tr>
                            </thead>
                            <tbody>
                                {selectedFields.map((field, index) => (
                                <tr key={index}>
                                    <td style={{ backgroundColor: '#cfe2f3' }}>{field.name}</td>
                                    <td style={{ backgroundColor: '#cfe2f3' }} className="text-center">
                                        <input
                                            type="checkbox"
                                            checked={field.selected}
                                            onChange={() => toggleSelected(index, 'selected')}
                                        />
                                    </td>
                                </tr>
                                ))}
                            </tbody>
                            </table>
                        <button className="btn btn-danger" onClick={handleMoveToAvailable}>Remove</button>
                    </div>
                </div>
                { accessType === 'View' ? "" :
                    <>
                    <div className="" style={{marginRight:'150px'}}>
                        <button className="btn btn-success" onClick={handleSubmit}>Submit</button>
                    </div>
                    </>
                }
                </div>
            </> : ''
        }
    </div>
    {listOfExistingReportSetUp.length > 0 ?
                <>
                    <div className="row pt-2 mx-2">
                        <div className="col-md-11">
                            <hr style={{ height: '3px' }} />
                        </div>
                    </div>

                    <div className="row pt-2 mx-2" style={{ marginLeft: '40px' }}>
                        <div className="col-md-8">
                            <h5 className=" mb-1" style={{ marginLeft: '10px' }}>Existing Report SetUp:</h5>

                            <table className="table table-hover" style={{ marginLeft: '40px', marginTop: '15px' }}>
                                <thead>
                                    <tr>
                                        <th style={{backgroundColor:'orange'}} scope="col">Report Name</th>
                                        <th style={{backgroundColor:'orange'}} scope="col">Updated By</th>
                                        <th style={{backgroundColor:'orange'}} scope="col">Updated On</th>
                                        <th style={{backgroundColor:'orange'}} scope="col">Edit</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        listOfExistingReportSetUp.map((val, i) => (
                                            <tr>
                                                <td style={{ backgroundColor: '#cfe2f3' }}>{val.report_name}</td>
                                                <td style={{ backgroundColor: '#cfe2f3' }}>{val.created_by}</td>
                                                <td style={{ backgroundColor: '#cfe2f3' }}>{formatDate(new Date(val.created_date))}</td>
                                                <td style={{ backgroundColor: '#cfe2f3' }}><button className="btn" disabled={accessType === "View"} onClick={(e) => editRateCodeSetup(i)}><EditIcon /></button></td>
                                                {/* <td><button className="btn success" onClick={(e) => editRateCodeSetup(i)}> <EditIcon /></button></td> */}
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </> : ''
    }
        </>
  );
};

