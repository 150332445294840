import React, { useState, useEffect, useRef } from "react";
import { Button, Alert } from "@mui/material";
import Select from "react-select";
import { Country, State, City } from "country-state-city";
import IATA from "./IATA.json";
import Swal from 'sweetalert2';
import { baseURL } from '../backend_url';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import axios from 'axios';



export default function UpdateForm({accessType}) {

  var temp_company_id = "";
  var temp_no_of_location = {};

  const [companyDetail, setcompanyDetail] = useState([{}]);
  const [companyName, setCompanyName] = useState("");
  const [company_id, setcompany_id] = useState("");
  const [locationDetail, setLocationDetail] = useState([{}]);
  const [numOfLoc, setNumOfLoc] = useState(0);
  const [successMsg, setSuccessMsg] = useState("");
  const [successMsg2, setSuccessMsg2] = useState("");
  const [loading, setLoading] = useState(false);
  const [addNewClicked, setAddNewClicked] = useState(false);
  const [locationName, setLocationName] = useState('');
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [stateData, setStateData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [city, setCity] = useState("");
  const [airportCodeData, setAirtportCodeData] = useState([]);
  const [airportCode, setAirtportCode] = useState("");
  const [activeLocation, setActiveLocation] = useState(0);
  const [errorNumOfLoc, setErrorNumOfLoc] = useState("");
  const [submitFlag, setSubmitFlag] = useState(false);
  const [updatedFlag, setUpdatedFlag] = useState(false);
  const [maxActiveLocLimit, setMaxActiveLocLimit] = useState("");
  const [locationType, setLocationType] = useState("");
  const [locationTypeData, setLocationTypeData] = useState([]);
  const [validationAlert, setValidationAlert] = useState("");
  const [loggedInUserDetail, setLoggedInUserDetail] = useState({});

  const [updateClicked, setUpdateClicked] = useState(false);
  const [locationID, setLocationID] = useState("")


  //This is for scrolling
  const titleRef = useRef()

  function handleBackClick() {
    // titleRef.current.scrollIntoView({ behavior: 'smooth' })
  }

  //API call
  useEffect(() => {

    const loggedInUser = sessionStorage.getItem("user_token");
    const foundUser = JSON.parse(loggedInUser);
    setLoggedInUserDetail(foundUser);

    if (foundUser.role === 'SuperAdmin') {

      axios.get(baseURL + "/get_company_details_for_users")
        .then((res) => {
          setcompanyDetail(res.data);
        });
    }
    else {
      fetch(baseURL + "/get_company_details_for_users_accessWise/" + foundUser.access['AccountLocations_Account Access'] + "*" + foundUser.account_id + "*" + foundUser.account_type.account_type)
        .then((res) => res.json())
        .then((data) => {

          setcompanyDetail(data);
        });
    }

  }, []);

  //Triggered on Company Name Dropdown Changes
  useEffect(() => {

    if (companyDetail && companyName) {
      temp_company_id = companyDetail.filter(
        (value) => value.company_name === companyName
      )[0]._id;
      temp_no_of_location = companyDetail.filter(
        (value) => value.company_name === companyName
      )[0].no_of_loc;

      //API call for getting data from user DB
      const requestOptions = {
        method: "GET",
        mode: 'cors',
        headers: {
          "Content-Type": "application/json",
        },
      };

      fetch(
        baseURL + '/get_user_details/' + temp_company_id.toString(),
        requestOptions
      )
        .then((res) => res.json())
        .then((data) => {
          setcompany_id(temp_company_id);
          setNumOfLoc(temp_no_of_location);

        });


      //API call for getting data from Location DB
      const requestOptions2 = {
        method: "GET",
        mode: 'cors',
        headers: {
          "Content-Type": "application/json",
        },
      };

      fetch(
        baseURL + '/get_company_location_inactive_active/' + temp_company_id.toString(),
        requestOptions2
      )
        .then((res) => res.json())
        .then((data) => {
          setLocationDetail(data.res);
          setActiveLocation(data.active_locs);
          setNumOfLoc(data.max_num_of_locs);
        });

      setLocationTypeData([
        { "name": "On Terminal" },
        { "name": "Off Terminal" },
        { "name": "City Location/Downtown" },
        { "name": "Hotel Location" },
        { "name": "Railway Station" },
        { "name": "Bus/Tram" },

      ])
    }
    setSuccessMsg("");
    setMaxActiveLocLimit("");
    setValidationAlert("");
  }, [companyName]);

  //useEffect for checking location count starts
  useEffect(() => {
    if (activeLocation >= numOfLoc) {
      setAddNewClicked(false);
      setErrorNumOfLoc("You've exceeded the maximum allowed locations you can add. Please contact support@caledontechnologies.com to increase your limit.");
    }
    else {
      setErrorNumOfLoc("");
    }
  }, [activeLocation, numOfLoc]);
  //useEffect for checking location count ends

  const handleLocationStatusChange = async (location_id, status, location_name) => {
    Swal.fire({
      text: 'You are changing location status of ' + location_name + ' to ' + status + '. Are you sure?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        //API call for updating DB data
        const requestOptions = {
          method: "PUT",
          mode: 'cors',
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            is_active: status,
            location_id: location_id,
            company_id: company_id.toString(),
            updated_by: loggedInUserDetail.userEmail,
          }),
        };

        fetch(baseURL + "/change_company_location_status", requestOptions)
          .then((res) => res.json())
          .then((data) => {
            if (data === false) {
              setMaxActiveLocLimit("Maximum active location you can have is " + numOfLoc + " and You have already touched the limit. Please contact support@caledontechnologies.com to increase your limit.")
            }
            else {

              setMaxActiveLocLimit("");
              setUpdatedFlag(!updatedFlag);
              Swal.fire('Status Changed to ' + status, '', 'success');

               const requestOptions2 = {
                method: "GET",
                mode: 'cors',
                headers: {
                  "Content-Type": "application/json",
                },
              };

              fetch(
                baseURL + '/get_company_location_inactive_active/' + company_id.toString(),
                requestOptions2
              )
                .then((res) => res.json())
                .then((data) => {
                  setLocationDetail(data.res);
                  setActiveLocation(data.active_locs);
                  setNumOfLoc(data.max_num_of_locs);
                });
            }
          });
        return;
      } else if (result.isDenied) {
        Swal.fire('Changes are not saved', '', 'info');
      }
    })
  }

  //useEffect for update existing location starts
  useEffect(() => {
    //API call for getting data from Location DB
    const requestOptions2 = {
      method: "GET",
      mode: 'cors',
      headers: {
        "Content-Type": "application/json",
      },
    };

    // fetch(
    //   baseURL + '/get_company_location_inactive_active/' + company_id.toString(),
    //   requestOptions2
    // )
    //   .then((res) => res.json())
    //   .then((data) => {
    //     setLocationDetail(data.res);
    //     setActiveLocation(data.active_locs);
    //     setNumOfLoc(data.max_num_of_locs);

    //   });

  }, [updatedFlag]);
  //useEffect for update existing location ends

  //useEffect for submited new location starts
  useEffect(() => {
    //API call for getting data from Location DB
    const requestOptions2 = {
      method: "GET",
      mode: 'cors',
      headers: {
        "Content-Type": "application/json",
      },
    };

    // fetch(
    //   baseURL + '/get_company_location_inactive_active/' + company_id.toString(),
    //   requestOptions2
    // )
    //   .then((res) => res.json())
    //   .then((data) => {
    //     setLocationDetail(data.res);
    //     setActiveLocation(data.active_locs);
    //     setNumOfLoc(data.max_num_of_locs);

    //   });
  }, [submitFlag]);
  //useEffect for submited new location ends


  //Option for company name
  console.log('companyDetail',companyDetail)
  const companyNameOptions = companyDetail.map((value) => (

    { value: value.company_name, label: value.company_name }

  ));

  const handleInputChangeCompanyName = characterEntered => {
    setCompanyName(characterEntered.value);
  };


  const handleCountry = (value) => {
    console.log("-----country-----")
    console.log(value)
    const data = State.getAllStates();
    let filteredData = [];
    for (let item of data) {
      if (item?.countryCode === value?.isoCode) filteredData.push(item);
    }
    setStateData(filteredData);
    setState("");

  };

  const handleState = (value) => {
    console.log("-----state-----")
    console.log(value)
    let filteredData = [];
    const data = City.getAllCities();
    for (let item of data) {
      if (
        value?.countryCode === item?.countryCode &&
        value?.isoCode === item?.stateCode
      ) {
        filteredData.push(item);
      }
    }
    setCityData(filteredData);
    setCity("");
  };

  const handleCity = (value) => {
    console.log("-----city-----")
    console.log(value)
    let filteredData = [];
    for (let item of IATA) {
      if (
        value.name != '' && value.name != null &&
        value.name.toString().trim().toLowerCase() === item?.city.toString().trim().toLowerCase() &&

        item.iata != ''
      ) {
        filteredData.push(item);
      }
    }

    if (filteredData.length === 0) {
      for (let item of IATA) {
        if (
          country.name != '' && country.name != null &&
          country.name.toString().trim().toLowerCase() === item?.country.toString().trim().toLowerCase() &&
          item.iata != ''
        ) {
          filteredData.push(item);
        }
      }

    }

    setAirtportCodeData(filteredData);

  }

  const submitHandler = (e) => {

    e.preventDefault();


    if (activeLocation >= numOfLoc) {
      // setAddNewClicked(false);
      setErrorNumOfLoc("You've exceeded the maximum allowed locations you can add. Please contact support@caledontechnologies.com to increase your limit.");
    }
    else {


      if (locationName.toString().trim() === "") {
        setValidationAlert("Please enter Location Name");
        return;
      }

      else if (country.name === null || country.name === undefined || country === "" || country === null) {
        setValidationAlert("Please select Country");
        return;
      }

      else if (state.name === null || state.name === undefined || state === "" || state === null) {
        setValidationAlert("Please select State");
        return;
      }

      else if (city.name === null || city.name === undefined || city === "" || city === null) {
        setValidationAlert("Please select City");
        return;
      }

      else if (airportCode === null || airportCode === '' || airportCode.iata === undefined
        || airportCode.iata === null || airportCode.iata === "") {
        setValidationAlert("GDS Code can't be empty");
        return;
      }
      else if (locationType.name === null || locationType.name === ''
        || locationType.name === undefined || locationType === "" || locationType === null) {
        setValidationAlert("Please select Location Type");
        return;
      }



      else {
        setLoading(true);
        //API call for updating DB data
        const requestOptions = {
          method: "POST",
          mode: 'cors',
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            'company_id': company_id.toString(),
            'company_name': companyName,
            'location_name': locationName.toString().trim(),
            'country': country.name,
            'state': state.name,
            'city': city.name,
            'city_code': (airportCode != null && airportCode != '' && airportCode.iata != undefined) ? airportCode.iata : "",
            'location_type': locationType.name,
            'created_by': loggedInUserDetail.userEmail,
            'updated_by': loggedInUserDetail.userEmail,
          }),
        };

        fetch(baseURL + "/save_company_location", requestOptions)
          .then((res) => res.json())
          .then((data) => {
            if (data === 'Already Present') {
              Swal.fire({
                position: 'middle',
                icon: 'error',
                title: 'Location Name Already Exists',
                showConfirmButton: false,
                timer: 3000
              })
              setLoading(false);
            }
            else {
              setSubmitFlag(!submitFlag);
              setLoading(false);
              Swal.fire({
                position: 'middle',
                icon: 'success',
                title: 'A new location has been added successfully',
                showConfirmButton: false,
                timer: 3000
              })
            }
          });
      }

    }
    setCountry(null);
    setState(null);
    setCity(null);
    setAirtportCode("");
    setLocationName("");
    setLocationType("");
    setValidationAlert("");
    // setLoading(false);

  }

 

  const handleUpdate = (val) => {

    const data1 = Country.getAllCountries();
    let filteredData1 = [];
    for (let item of data1) {
      if (val.country === item?.name) {
        filteredData1.push(item);
        break;
      }
    }

    const data = State.getAllStates();
    let filteredData = [];
    for (let item of data) {
      if (item?.countryCode === filteredData1[0]?.isoCode) filteredData.push(item);
    }
    setStateData(filteredData);


    const data2 = State.getAllStates();
    let filteredData2 = [];
    for (let item of data2) {
      if (val.state === item?.name) {
        filteredData2.push(item);
        break;
      }
    }


    const data3 = City.getAllCities();
    let filteredData3 = [];
    for (let item of data3) {

      if (filteredData1[0]?.isoCode === item?.countryCode &&
        filteredData2[0]?.isoCode === item?.stateCode) filteredData3.push(item);
    }


    setCityData(filteredData3);


    setAddNewClicked(false);
    setUpdateClicked(true);

    setAirtportCode(val.city_code)
    setCity(val.city)
    setState(val.state)
    setCountry(val.country)
    setLocationName(val.location_name)
    setLocationType(val.location_type)
    setLocationID(val.location_id)


  }


  const updateData1 = (e) => {

    e.preventDefault();


    if (locationName.toString().trim() == "") {
      Swal.fire({
        position: 'middle',
        icon: 'error',
        title: 'Please enter Location Name',
        showConfirmButton: false,
        timer: 3000
      })
      return;
    }

    if (locationType === null || locationType === "") {
      Swal.fire({
        position: 'middle',
        icon: 'error',
        title: 'Please select Location Type',
        showConfirmButton: false,
        timer: 3000
      })
      return;
    }
    else {
      //API call for updating DB data
      const requestOptions = {
        method: "POST",
        mode: 'cors',
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          'location_id': locationID,
          'company_id': company_id.toString(),
          'company_name': companyName,
          'location_name': locationName.toString().trim(),
          'state': state.name === undefined ? state : state.name,
          'city': city.name === undefined ? city : city.name,
          'location_type': locationType,
          'updated_by': loggedInUserDetail.userEmail,
        }),
      };

      fetch(baseURL + "/update_company_location", requestOptions)
        .then((res) => res.json())
        .then((data) => {
          if (data === 'Already Present') {
            Swal.fire({
              position: 'middle',
              icon: 'error',
              title: 'Location Name Already Exists',
              showConfirmButton: false,
              timer: 3000
            })

          }
          else {
            Swal.fire({
              position: 'middle',
              icon: 'success',
              title: 'Location has been updated successfully',
              showConfirmButton: false,
              timer: 3000
            })

            setUpdateClicked(false);
            setUpdatedFlag(!updatedFlag);
            setCountry(null);
            setState(null);
            setCity(null);
            setAirtportCode("");
            setLocationName("");
            setLocationType("");
            setValidationAlert("");
          }

        });
    }
  }


  const handleDelete = (id) => {

    Swal.fire({
      title: 'Do you want to delete this location ?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed) {

        axios.delete(baseURL + "/delete_location/" + id)
          .then((res1) => {

            Swal.fire({
              position: 'middle',
              icon: 'success',
              title: "Deleted Successfully",
              showConfirmButton: false,
              timer: 3000
            })

            setSubmitFlag(!submitFlag)

            const requestOptions2 = {
              method: "GET",
              mode: 'cors',
              headers: {
                "Content-Type": "application/json",
              },
            };

            fetch(
              baseURL + '/get_company_location_inactive_active/' + company_id.toString(),
              requestOptions2
            )
              .then((res) => res.json())
              .then((data) => {
                setLocationDetail(data.res);
                setActiveLocation(data.active_locs);
                setNumOfLoc(data.max_num_of_locs);
              });

          }).catch((e) => { });


      }
    })
  }



  return (
    <>
      <div className="row pt-2">
        <div className="col-8 mx-3">
          <h5>Select Account to edit details:</h5>
        </div>
      </div>
      <div className="row pt-4" style={{fontSize:'12px'}}>
        {/* <div className="col-1"></div> */}
        <div className="col-9 mx-3"  style={{color:'black', fontWeight:'600'}}>
          <form className="row g-3">

            <div className="col-md-2 mb-3">
              <label htmlFor="company_name" className="form-label" style={{ marginTop: 5, color:'black', fontWeight:'600' }}>
                Account Name
              </label>
            </div>

            <div className="col-md-5 mb-3">
              <Select
                value={{ value: companyName, label: (companyName ? companyName : "Select Account name") }}
                inputId="company_name"
                name="company_name"
                options={companyNameOptions}
                onChange={handleInputChangeCompanyName}
              />
            </div>
            <div className="col-md-1 mb-3"></div>
            <div className="col-md-2 mb-3">
              {/* <label htmlFor="company_id" className="form-label" style={{ marginTop: 5 }}>
                Account ID
              </label> */}
              <label htmlFor="company_name" className="form-label">
                Maximum number of location allowed
              </label>
            </div>
            <div className="col-md-2 mb-3">
              {/* <input
                type="text"
                className="form-control"
                value={company_id}
                id="company_id"
                disabled
              /> */}
              <input
                type="text"
                className="form-control"
                value={numOfLoc}
                id="no_of_loc"
                disabled
              />
            </div>

            <div className="col-md-9"></div>
          </form>
        </div>

      </div>


      <div className="row pt-1">

        <div className="col-md-10 mx-3">
        {accessType === 'View' ? "" :
            <>
          <Button variant="contained" style={{fontSize:'12px'}} onClick={() => { setAddNewClicked(!addNewClicked); setUpdateClicked(false); handleBackClick(); setLocationName(""); }}>Add New Location</Button>
          </>
          }
        </div>
      </div>

      {/* Add New Location starts */}
      {addNewClicked ? (

        companyName === "" ? (
          <div className="row pt-2">
            <div className="col-10">
              <Alert severity="error">
                Please select an account to proceed ahead
              </Alert> </div></div>) :
          (
            errorNumOfLoc !== "" ? (

              <div className="row pt-1">
                <div className="col-10 mx-3">
                  <Alert severity="error">
                    {errorNumOfLoc}
                  </Alert> </div></div>
            ) :
              (
                <div className="row pt-2" style={{fontSize:'12px'}} >

                  <div className="col-lg-11 mx-3">
                    {
                      validationAlert ?
                        <Alert severity="error">
                          {validationAlert}
                        </Alert> : ""
                    }
                    <form onSubmit={submitHandler} ref={titleRef}>
                      <h5 className=" mb-1 mt-2">Add New Location </h5>
                      <table className="table" >
                        <thead>
                          <tr style={{textAlign:'center'}}>
                            <th style={{backgroundColor:'orange', width:'15%'}} scope="col">Location Name*</th>
                            <th style={{backgroundColor:'orange', width:'18%'}} scope="col">Country*</th>
                            <th style={{backgroundColor:'orange', width:'18%'}} scope="col">State*</th>
                            <th style={{backgroundColor:'orange', width:'18%'}} scope="col">City/Town*</th>
                            <th style={{backgroundColor:'orange', width:'18%'}} scope="col">GDS Code</th>
                            <th style={{backgroundColor:'orange', width:'18%'}} scope="col">Location Type*</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr style={{textAlign:'center'}}>
                            <td>
                              <input
                                type="text"
                                className="form-control"
                                value={locationName}
                                onChange={(e) => setLocationName(e.target.value)}
                                id="Location_name"
                                // style={{width:'80%'}}
                              />
                            </td>
                            <td>
                              <Select
                                placeholder={"Select Country"}
                                options={Country.getAllCountries()}
                                value={country}
                                getOptionLabel={(options) => options.name}
                                getOptionValue={(options) => options.name}
                                onChange={(e) => {
                                  setCountry(e);
                                  handleCountry(e);
                                }}
                              />
                            </td>
                            <td>
                              <Select

                                placeholder={"Select state"}
                                value={state}
                                options={stateData}
                                getOptionLabel={(options) => options.name}
                                getOptionValue={(options) => options.name}
                                onChange={(e) => {
                                  setState(e);
                                  handleState(e);
                                }}
                              />
                            </td>
                            <td>
                              <Select

                                placeholder={"Select city"}
                                value={city}
                                options={cityData}
                                getOptionLabel={(options) => options.name}
                                getOptionValue={(options) => options.name}
                                onChange={(e) => {
                                  setCity(e);
                                  handleCity(e);
                                }}
                              />
                            </td>

                            <td>

                              <Select

                                placeholder={"Select city code"}
                                value={airportCode}
                                options={airportCodeData}
                                getOptionLabel={(options) => options.name + " - " + options.iata}
                                getOptionValue={(options) => options.name + " - " + options.iata}
                                onChange={(e) => {
                                  setAirtportCode(e);
                                }}
                              />
                            </td>

                            <td>
                              <Select

                                placeholder={"Select Location Type"}
                                value={locationType}
                                options={locationTypeData}
                                getOptionLabel={(options) => options.name}
                                getOptionValue={(options) => options.name}
                                onChange={(e) => {
                                  setLocationType(e);

                                }}
                              />

                            </td>

                          </tr>

                        </tbody>
                      </table>

                      <div className="row pt-2">
                        <div className="col-10 d-flex-wrap"></div>

                        <div className="col-2 d-flex justify-content-end">
                          {loading ? (
                            <button className="btn btn-success" type="button" disabled>
                              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{ marginRight: 5 }}></span>
                              Submit
                            </button>
                          ) : (
                            <button className="btn btn-success" >
                              Submit
                            </button>
                          )}
                        </div>
                      </div>

                    </form>



                  </div>
                </div>
              )
          )) :
        updateClicked ?
          <>
            <div className="row pt-2" style={{fontSize:'12px'}}>

              <div className="col-lg-11 mx-3">
                <form ref={titleRef}>
                  <h5 className=" mb-1 mt-2" style={{fontSize:'22px'}}>Edit Location </h5>
                  <table className="table" style={{marginTop:'15px'}}>
                    <thead>
                      <tr style={{textAlign:'center'}}>
                        <th style={{backgroundColor:'orange', width:'15%'}} scope="col">Location Name*</th>
                        <th style={{backgroundColor:'orange', width:'15%'}} scope="col">Country*</th>
                        <th style={{backgroundColor:'orange', width:'15%'}} scope="col">State*</th>
                        <th style={{backgroundColor:'orange', width:'15%'}} scope="col">City/Town*</th>
                        <th style={{backgroundColor:'orange', width:'15%'}} scope="col">GDS Code</th>
                        <th style={{backgroundColor:'orange', width:'15%'}} scope="col">Location Type*</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr style={{textAlign:'center'}}>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            value={locationName}
                            onChange={(e) => setLocationName(e.target.value)}
                            id="Location_name"

                          />
                        </td>
                        <td>
                          <Select

                            placeholder={"Select Country"}
                            options={Country.getAllCountries()}
                            value={country.name === undefined ? { name: country, value: country } : country}
                            getOptionLabel={(options) => options.name}
                            getOptionValue={(options) => options.name}
                            onChange={(e) => {
                              setCountry(e);
                              handleCountry(e);
                            }}
                            isDisabled={true}
                          />
                        </td>
                        <td>
                          <Select

                            placeholder={"Select state"}
                            value={state.name === undefined ? { name: state, value: state } : state}
                            options={stateData}
                            getOptionLabel={(options) => options.name}
                            getOptionValue={(options) => options.name}
                            onChange={(e) => {
                              setState(e);
                              handleState(e);
                            }}
                          />
                        </td>
                        <td>
                          <Select

                            placeholder={"Select city"}
                            value={city.name === undefined ? { name: city, value: city } : city}
                            options={cityData}
                            getOptionLabel={(options) => options.name}
                            getOptionValue={(options) => options.name}
                            onChange={(e) => {
                              setCity(e);
                              handleCity(e);
                            }}
                          />
                        </td>

                        <td>

                          {airportCode}
                        </td>

                        <td>
                          {locationType.indexOf('Terminal') > 0 ?
                            <select class="form-select" value={locationType} onChange={(e) => setLocationType(e.target.value)}>
                              <option value="On Terminal">On Terminal</option>
                              <option value="Off Terminal">Off Terminal</option>
                            </select>
                            :
                            locationType
                          }
                        </td>

                      </tr>

                    </tbody>
                  </table>

                  <div className="row pt-2">
                    <div className="col-10 d-flex-wrap"></div>

                    <div className="col-2 d-flex justify-content-end">
                      {loading ? (
                        <button className="btn btn-success" type="button" disabled>
                          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{ marginRight: 5, fontSize:'12px' }}></span>
                          Update
                        </button>
                      ) : (
                        <button className="btn btn-success" style= {{fontSize:'12px'}} onClick={(e) => { updateData1(e); }} >
                          Update
                        </button>
                      )}
                    </div>
                  </div>

                </form>
              </div>
            </div>
          </> : ""
      }
      {/* Add New Location ends */}

      {/* existinng Location starts */}
      <div className="row pt-3" style={{fontSize:'12px'}}>
        <div className="col-md-11 mx-3 ">
          <h5 className="mb-3" style={{fontSize:'22px'}}>Existing Location(s)</h5>
          {successMsg ?
            (
              <div className="alert alert-success alert-dismissible fade show" role="alert">
                {successMsg}
                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => setSuccessMsg('')}></button>
              </div>
            )
            : ("")}
          {maxActiveLocLimit ? (
            <Alert severity="error">
              {maxActiveLocLimit}
            </Alert>) : ""
          }
          <table className="table pt-2">
            <thead>
              <tr>
                <th style={{backgroundColor:'orange'}} scope="col">#</th>
                <th style={{backgroundColor:'orange'}} scope="col">Location  Name</th>
                <th style={{backgroundColor:'orange'}} scope="col">Location ID</th>
                <th style={{backgroundColor:'orange'}} scope="col">Country</th>
                <th style={{backgroundColor:'orange'}} scope="col">State</th>
                <th style={{backgroundColor:'orange'}} scope="col">City/Town*</th>
                <th style={{backgroundColor:'orange'}} scope="col">Location Type</th>
                <th style={{backgroundColor:'orange'}} scope="col">Location Status</th>
                <th style={{backgroundColor:'orange'}} scope="col">Edit</th>
                <th style={{backgroundColor:'orange'}} scope="col">Delete</th>
              </tr>
            </thead>
            <tbody>
              {locationDetail.length > 0 && locationDetail[0].location_name ? locationDetail.map((value, key) => (
                <tr >
                  <th scope="row" >{key + 1}</th>
                  <td >{value.location_name}</td>
                  <td >{value.location_id}</td>
                  <td >{value.country}</td>
                  <td>{value.state}</td>
                  <td >{value.city}</td>
                  <td >{value.location_type}</td>
                  <td>
                    <select className="form-select form-select-sm" value={value.is_active} onChange={(e) => handleLocationStatusChange(value.location_id, e.target.value, value.location_name)}>

                      <option value={value.is_active ? "active" : "inactive"} defaultValue>{value.is_active ? "Active" : "Inactive"}</option>
                      <option value={!value.is_active ? "active" : "inactive"} >{!value.is_active ? "Active" : "Inactive"}</option>

                    </select>
                  </td>
                  <td>
                  <span
                    style={{ cursor: accessType === "View" ? "not-allowed" : "pointer", opacity: accessType === "View" ? 0.5 : 1 }}
                    onClick={() => accessType !== "View" && handleUpdate(value)}
                  >
                    <EditIcon />
                  </span>
                </td>

                  {/* <td ><span style={{ cursor: "pointer" }} disabled={accessType === "View"}  onClick={() => handleUpdate(value)}><EditIcon /></span></td> */}
                  <td>
                  <span
                    style={{ cursor: accessType === "View" ? "not-allowed" : "pointer", opacity: accessType === "View" ? 0.5 : 1 }}
                    onClick={() => accessType !== "View" && handleDelete(value.location_id)}
                  >
                    <DeleteForeverIcon />
                  </span>
                </td>
                  {/* <td><span style={{ cursor: "pointer" }} disabled={accessType === "View"}  onClick={() => handleDelete(value.location_id)}><DeleteForeverIcon /></span></td> */}

                </tr>

              )) : ""}



            </tbody>
          </table>
        </div>
      </div>
      {/* existinng Location ends */}

      <br /><br />
    </>
  );
}