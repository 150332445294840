import React, { useState, useEffect } from 'react'
import { Button, Typography } from '@mui/material';
import { Modal } from 'react-bootstrap'
import UpdateForm from './UpdateForm'

export default function Competitors() {

  const [lgShow, setLgShow] = useState(false);
  const [accessType, setAccessType] = useState('');

  //API call
  useEffect(() => {

    const loggedInUser = sessionStorage.getItem("user_token");
    const foundUser = JSON.parse(loggedInUser);

    if (foundUser.role === 'SuperAdmin') {

      setAccessType('Edit');

    }
    else {

      setAccessType(foundUser.access['Competitors_Access Specifier']);
    }

  }, []);


  return (
    <>
      <div className="row my-3 ">
        <div className="col-md-6">
           {/* <Typography className="mx-3" variant='h6' sx={{ pb: 3 }}>
           Competitors - Master {accessType === 'View' ? " / View Only Mode" : ""}
            </Typography> */}
            <Typography className="mx-3" variant='h6' sx={{ pb: 3 }} style = {{marginTop:'5px', fontSize:'25px'}}>
            <b>Competitors - Master</b>
            </Typography>
            
          {/* <Typography className="mx-3" variant='h6' sx={{ pb: 3 }}>
            Competitors - Master {accessType === 'View' ? " / View Only Mode" : ""}
          </Typography> */}
        </div>
        <div className="col-md-6 d-flex justify-content-end"   >

          {/* <button type="button" className="btn btn-danger" onClick={() => setLgShow(true)} style={{ height: "40px" }}  >Help</button> */}
          <Modal
            size="lg"
            show={lgShow}
            onHide={() => setLgShow(false)}
            aria-labelledby="example-modal-sizes-title-lg"
          >
            <Modal.Header closeButton style={{ color: '#FFFFFF', backgroundColor: '#C62828' }}>
              <Modal.Title id="example-modal-sizes-title-lg" >
                {/* Instructions to Add/Edit Adjustment */}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {/* <h5>Add Adjustment:
              </h5> */}
              <ol>
                <li>Select Account Name>Select the Adjustment type.
                </li>
                <li>Click on Add RateInfo button.
                </li>
                <li>Select Adjustment Type from the dropdown.
                </li>
                <li>Select Location.
                </li>
                <li>Select Rate Code--> if select all is checked all the rate codes are selected.
                </li>
                <li>Select OTA--> if select all is checked all the OTA's are selected.
                </li>
                <li>Select Car Class from the car class feild-->if select all is checked all the Car classes are selected.
                </li>
                <li>Select Start Date and End Date from the calendar>Select Start Time and End Time from the calendar.
                </li>
                <li>Select applicable days-->Click on Select All to select all days.
                </li>
                <li>Enter minimum and maximum length of rental.
                </li>
                <li>Select Type(Increement/Decreement)from the dropdown.
                </li>
                <li>Enter the value>Select the amount/% from the dropdown.
                </li>
                <li>Click on Save.
                </li>
              </ol>

              <h5>Existing Records:
              </h5>
              <ul>
                <li>To view/edit/delete the existing Adjustments select the Account Name and Adjustment Type from the dropdown.</li>
              </ul>

            </Modal.Body>
          </Modal>

        </div>
      </div>


      {/* <div className="col-4 d-flex justify-content-end px-5">
        
          
         
        </div> */}


      {accessType === 'View' ? <UpdateForm accessType={accessType} /> : <UpdateForm />}

    </>
  );
}
