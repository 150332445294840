import React, { useState, useEffect, useRef } from 'react'
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { baseURL } from '../backend_url';
import axios from 'axios';
import moment from 'moment';
import Modal from 'react-modal';
import Select from "react-select";
import Swal from 'sweetalert2';


Modal.setAppElement('#root');

export const ExportToExcelWithRates = ({ accountName, locationName, rateCodes, fromDate, toDate, carClass, fileName, rates, rateCodeNames, account_id, location_id }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedRateType, setSelectedRateType] = useState(null);
    const [email, setEmail] = useState('');
    const [excelData, setExcelData] = useState([]);
    const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
    const [isEmailModalOpen2, setIsEmailModalOpen2] = useState(false);
    const rateEmailOptions = [
        { value: "Total", label: "Total" }
    ];
    const sharedStyles = {
        width: '250px', 
        marginRight: '10px',
    };

    const handleSelectChange = (selectedOption) => {
        setSelectedOption(selectedOption);
    };
    const [selectedOption, setSelectedOption] = useState(null);
    if (Object.keys(rates).length < 1){
        return

    }  
    const array = ['690337-YYC', '690337-YUL', '690337-YOW', '690337-YYZ', '690337-YVR'];
    const handleRateSelection = async (rateType) => {
        setSelectedRateType(rateType);
        await downloadExcelFileWithRates(rateType);
    };
    console.log("rates:", rates)
        const modifiedRates = {};
        for (const key in rates) {
            const array = rates[key];
            const modifiedArray = [];
            for (let i = 0; i < array.length; i++) {
                if (typeof array[i] === "object" && array[i] !== "lowest_among_competitor") {
                const modifiedObject = {};
                for (const rateKey in array[i]) {
                    if (array[i][rateKey] !== "" && array[i][rateKey] !== "-") {
                    // modifiedObject[rateKey] = Number((array[i][rateKey] * 100) / (100 + 26)).toFixed(2);
                    // modifiedObject[rateKey] = (Number((array[i][rateKey] * 100) / (100 + 26)).toFixed(2) / key).toFixed(2);
                    modifiedObject[rateKey] = Number(array[i][rateKey] ).toFixed(2);


                    // let modifiedObject = { [rateKey]: (Number((array[i][rateKey] * 100) / (100 + 26)).toFixed(2) / 2).toFixed(2) };

                    } else {
                    modifiedObject[rateKey] = array[i][rateKey];
                    }
                }
                modifiedArray.push(modifiedObject);
                } else {
                modifiedArray.push(array[i]);
                }
            }
            modifiedRates[key] = modifiedArray;
        }
        rates = modifiedRates

    const formatDate = (date) => {
        return [
            date.getFullYear(),
            padTo2Digits(date.getMonth() + 1),
            padTo2Digits(date.getDate())
        ].join('-');
    }

    const padTo2Digits = (num) => {
        return num.toString().padStart(2, '0');
    }

    let calender = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    let csvData 
    // let counter = 1;
    // let adjustmentsData;
    // let myOutputName = {}; // Replace 'myOutputName' with the desired variable name

    // for (let key in rates) {
        // const body_payload = {
        //     account_id: account_id.toString(),
        //     pickup_location_id: location_id.toString(),
        //     dropoff_location_id: location_id.toString(),
        //     pickup: fromDate,
        //     lor: key,
        //     ota_code: '290901',
        //     rate_code: '646351',
        //     car_class: carClass,
        // };
    const body_payload =
    {
        account_id: account_id.toString(),
        location_id: location_id.toString(),
        carClassData: rates,
        from_date: new Date(fromDate).toDateString(),
    };

    axios.post(baseURL + "/get_base_rate_date", body_payload)
        .then((res2) => {
            console.log("REverse Calculation Response:",res2)
            if (res2.data) {
                const adjustmentsData = res2.data;
                console.log('adjustmentsData', adjustmentsData)
                // const array = rates[key];
                // if (adjustmentsData) {
                //     for (let i = 0; i < array.length; i++) {
                //         if (typeof array[i] === "object" && array[i] !== "lowest_among_competitor") {
                //             for (const rateKey in array[i]) {
                //                 if (adjustmentsData.hasOwnProperty(rateKey)) {
                //                     if (array[i][rateKey] !== "-" && !isNaN(parseFloat(array[i][rateKey])) && isFinite(array[i][rateKey])) {
                //                         array[i][rateKey] /= adjustmentsData[rateKey];
                //                     } else {
                //                         array[i][rateKey] = "";
                //                     }
                //                 }
                //             }
                //         }
                //     }
                // }
            }
        })
        .catch((error) => {
            console.error("Error fetching reverse calculation data:", error);
        });

    const downloadExcelFileWithRates = async (rateType) => {
        console.log('inside excel download');
        console.log("rateType",rateType)
        const body_payload = {
            account_id: account_id.toString(),
            location_id: location_id.toString(),
            carClassData: rates,
            from_date: new Date(fromDate).toDateString(),
        };
    
        try {
            if (rateType == 'Base'){
                const response = await axios.post(baseURL + "/get_base_rate_data", body_payload);
                console.log("Reverse Calculation Response:", response);
                rates = response.data.carClassData;
                console.log("Reverse Calculation rates:", rates);
            }else if (rateType == 'Total'){
                rates = modifiedRates
            }
            
            let i = new Date(fromDate).valueOf();
            let date = formatDate(new Date(i));
            let csvData = [];
            let counter = 1;
            for (let j = 0; j < rateCodes.length; j++) {
                for (let k = 0; k < carClass.length; k++) {
                    let key = date + "_" + rateCodes[j] + "_" + carClass[k];
                    csvData.push({
                        "Sl. No.": counter++,
                        "Account Name": accountName,
                        "Location Name": locationName,
                        "Date": new Date(i).getDate() + "-" + calender[new Date(i).getMonth()] + '-' + new Date(i).getFullYear(),
                        "Rate Code": rateCodes[j],
                        "Rate Code Name": rateCodeNames[rateCodes[j]],
                        "Car Class": carClass[k],
                        "1 Day": rates[1] != undefined ? rates[1][0][carClass[k]] == '-' ? "" : rates[1][0][carClass[k]] ?? '' : "",
                        "2 Day": rates[2] != undefined ? rates[2][0][carClass[k]] == '-' ? "" : rates[2][0][carClass[k]] ?? '' : "",
                        "3 Day": rates[3] != undefined ? rates[3][0][carClass[k]] == '-' ? "" : rates[3][0][carClass[k]] ?? '' : "",
                        "4 Day": rates[4] != undefined ? rates[4][0][carClass[k]] == '-' ? "" : rates[4][0][carClass[k]] ?? '' : "",
                        "5 Day": rates[5] != undefined ? rates[5][0][carClass[k]] == '-' ? "" : rates[5][0][carClass[k]] ?? '' : "",
                        "6 Day": rates[6] != undefined ? rates[6][0][carClass[k]] == '-' ? "" : rates[6][0][carClass[k]] ?? '' : "",
                        "Daily X-Day": "" ,
                        "Weekly": rates[7] != undefined ? rates[7][0][carClass[k]] == '-' ? "" : rates[7][0][carClass[k]] ?? '' : "",
                        "Weekly X-Day":  '',
                        "Monthly": rates[28] != undefined ? rates[28][0][carClass[k]] == '-' ? "" : rates[28][0][carClass[k]] ?? '' : "",
                        "Monthly X-Day":  '',
                        "Weekend":  '',
                        "Hourly": '',
                        "Minute": '',
                        "Per Mile/Km": ''
                    });
                }
            }
            console.log('csvData444',csvData)
            setExcelData(csvData)
    
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            const fileExtension = '.xlsx';
            const ws = XLSX.utils.json_to_sheet(csvData);
            const wb = { Sheets: { 'Rate Update with Rates': ws }, SheetNames: ['Rate Update with Rates'] };
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], { type: fileType });
            FileSaver.saveAs(data, fileName + fileExtension);
        } catch (error) {
            console.error("Error during the Excel file download process:", error);
        }
    };

    const handleEmailSubmit = async () => {
        if (!validateEmail(email)) {
            Swal.fire('Please enter a valid email address.');
            return;
        }
        if (!selectedOption || selectedOption.value === '') {
            Swal.fire('Please select Rate Type.');
            return;
        }

        if (selectedOption.value == 'Base'){
            const response = await axios.post(baseURL + "/get_base_rate_data", body_payload);
            console.log("Reverse Calculation Response:", response);
            rates = response.data.carClassData;
            console.log("Reverse Calculation rates:", rates);
        }else if (selectedOption.value == 'Total'){
            rates = modifiedRates
        }
        console.log('selectedOption',selectedOption.value)
        
        let i = new Date(fromDate).valueOf();
        let date = formatDate(new Date(i));
        let csvData = [];
        let counter = 1;
        for (let j = 0; j < rateCodes.length; j++) {
            for (let k = 0; k < carClass.length; k++) {
                let key = date + "_" + rateCodes[j] + "_" + carClass[k];
                csvData.push({
                    "Sl. No.": counter++,
                    "Account Name": accountName,
                    "Location Name": locationName,
                    "Date": new Date(i).getDate() + "-" + calender[new Date(i).getMonth()] + '-' + new Date(i).getFullYear(),
                    "Rate Code": rateCodes[j],
                    "Rate Code Name": rateCodeNames[rateCodes[j]],
                    "Car Class": carClass[k],
                    "1 Day": rates[1] != undefined ? rates[1][0][carClass[k]] == '-' ? "" : rates[1][0][carClass[k]] ?? '' : "",
                    "2 Day": rates[2] != undefined ? rates[2][0][carClass[k]] == '-' ? "" : rates[2][0][carClass[k]] ?? '' : "",
                    "3 Day": rates[3] != undefined ? rates[3][0][carClass[k]] == '-' ? "" : rates[3][0][carClass[k]] ?? '' : "",
                    "4 Day": rates[4] != undefined ? rates[4][0][carClass[k]] == '-' ? "" : rates[4][0][carClass[k]] ?? '' : "",
                    "5 Day": rates[5] != undefined ? rates[5][0][carClass[k]] == '-' ? "" : rates[5][0][carClass[k]] ?? '' : "",
                    "6 Day": rates[6] != undefined ? rates[6][0][carClass[k]] == '-' ? "" : rates[6][0][carClass[k]] ?? '' : "",
                    "Daily X-Day": "" ,
                    "Weekly": rates[7] != undefined ? rates[7][0][carClass[k]] == '-' ? "" : rates[7][0][carClass[k]] ?? '' : "",
                    "Weekly X-Day":  '',
                    "Monthly": rates[28] != undefined ? rates[28][0][carClass[k]] == '-' ? "" : rates[28][0][carClass[k]] ?? '' : "",
                    "Monthly X-Day":  '',
                    "Weekend":  '',
                    "Hourly": '',
                    "Minute": '',
                    "Per Mile/Km": ''
                });
            }
        }
        try {
            const response = axios.post(baseURL + '/send_excel_email', {
                email: email,
                fileData: csvData,
            });
            console.log('Email sent:', response);
            Swal.fire('Email sent successfully!');
        } catch (error) {
            console.error('Error sending email:', error);
            Swal.fire('Failed to send email. Please try again.');
        }
        setIsEmailModalOpen(false);
    };

    const validateEmail = (email) => {
        const re = /\S+@\S+\.\S+/;
        return re.test(email);
    };

    return (
        <>
            <div style={{ marginBottom: '10px' }}>
                <button className="btn btn-dark" type="button" style={{ color: 'white', marginRight: '10px' }} onClick={() => handleRateSelection('Total')}>
                    Download Total Rate Excel
                </button>
                {/* <button className="btn btn-dark" type="button" style={{ color: 'white', marginRight :'10px' }} onClick={() => handleRateSelection('Base')}>
                    Download Base Rate Excel
                </button> */}

                <button className="btn btn-dark" type="button" style={{ color: 'white', marginRight :'10px' }} onClick={() => setIsEmailModalOpen(true)}>
                    Email Excel
                </button>
            </div>
            {isEmailModalOpen && (
                <div style={{ marginTop: '10px', marginLeft :'32%' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <input
                            type="email"
                            placeholder="Enter Email Address"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            style={{ ...sharedStyles, height: '38px' }}
                        />
                        <div style={sharedStyles}>
                            <Select
                                value={selectedOption}
                                onChange={handleSelectChange}
                                options={rateEmailOptions.map((val) => ({ value: val.value, label: val.value }))}
                                placeholder="Select Rate Type"    
                                styles={{
                                    control: (base) => ({
                                        ...base,
                                        minHeight: '38px',
                                        height: '100%', 
                                    }),
                                }}
                            />
                        </div>
                    </div>
                    <button className="btn btn-primary" style={{marginRight:'10px', marginTop:'10px'}} onClick={handleEmailSubmit}>
                        Submit
                    </button>
                    <button className="btn btn-secondary" style={{ marginTop:'10px'}} onClick={() => setIsEmailModalOpen(false)}>
                        Cancel
                    </button>
                </div>
            )}
        </>
    );
}