import React, { useState, useEffect } from 'react'
import UpdateForm from './UpdateForm';
import { Button, Typography } from '@mui/material';
import { Modal } from 'react-bootstrap'

export default function FleetSetUp() {
    const [lgShow, setLgShow] = useState(false);
    const [accessType, setAccessType] = useState('');

    useEffect(() => {
        const loggedInUser = sessionStorage.getItem("user_token");
        const foundUser = JSON.parse(loggedInUser);
        console.log('foundUser fleetsetp',foundUser)
        if (foundUser.role === 'SuperAdmin') {
            console.log('jjjj')
          setAccessType('Edit');
        }
        else { 
          setAccessType(foundUser.access['FleetSetUp_Access Specifier']);
          console.log('no one')
        }
      }, []);

    return (
        <>
            <div className="row">
                <div className="row my-3 ">
                    <div className="col-md-6">
                        {/* <Typography className="mx-3" variant='h6' sx={{ pb: 3 }}>
                            Fleet SetUp - Master
                        </Typography> */}
                        <Typography className="mx-3" variant='h6' sx={{ pb: 3 }} style = {{marginTop:'5px', fontSize:'20px'}}>
                            <b>Fleet SetUp - Master</b>
                         </Typography>
                    </div>

                    <div className="col-md-6 d-flex justify-content-end">
                        {/* <button type="button" className="btn btn-danger" onClick={() => setLgShow(true)} style={{ height: "40px" }}  >Help</button> */}
                        <Modal
                            size="lg"
                            show={lgShow}
                            onHide={() => setLgShow(false)}
                            aria-labelledby="example-modal-sizes-title-lg">
                            <Modal.Header closeButton style={{ color: '#FFFFFF', backgroundColor: '#C62828' }}>
                                <Modal.Title id="example-modal-sizes-title-lg" >
                                    Instructions to Add/Delete Fleet SetUp
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <h5>Create New Fleet SetUp</h5>
                                <ol>
                                    <li>Select Account for Fleet Standard</li>
                                    <li>Select Account Name from the dropdown</li>
                                    <li>Add new - Select Car Category, Type, Transmission and Air Conditioner</li>
                                    <li>Click on Submit button</li>
                                </ol>

                                <h5>Existing Car Classes</h5>
                                <ol>
                                    <li>To View/Delete Existing Car Classes select Account name from the dropdown</li>
                                </ol>
                            </Modal.Body>
                        </Modal>
                    </div>
                </div>
            </div>

            {/* <UpdateForm /> */}
            {accessType === 'View' ? <UpdateForm accessType={accessType} /> : <UpdateForm />}

        </>
    );
}
