import React, { useState, useEffect, useRef } from 'react'
import { Alert } from "@mui/material";
import Select from "react-select";
import Swal from 'sweetalert2';
import Moment from 'moment';
import { Calendar, Empty } from 'antd';
// import './RateUpdate.css'
import Modal from 'react-bootstrap/Modal'
import { baseURL } from '../backend_url';
import axios from 'axios';
import moment from 'moment';
// import { ExportToExcel } from './ExportToExcel'
// import { ExportToExcelWithRates } from './ExportToExcelWithRates';
// import ExcelUpload from './ExcelUpload';
import MultiRangeSlider from "multi-range-slider-react";
import RangeSlider from "react-bootstrap-range-slider";
import { isEmpty } from 'lodash';
import ProgressBar from './ProgressBar';
import './loader.css';
import { DatePicker } from "antd";
import expedia from './expedia_logo.jpg'
import ace from './ace.png'
import budget from './budget.jpg'
import europcar from './europcar.jpg'
import dollar from './dollar.jpg'
import national from './national.png'
import alamo from './alamo.png'
import advantage from './advantage.jpg'
import routes from './routes.jpg'
import fox from './fox.jpg'
import thrifty from './thrifty.jpg'
import greenMotion from './greenMotion.jpg'
import { ExportToExcelWithRates } from './ExportToExcelWithRates';
import { parse, format } from 'date-fns';
import { PushToRateUpdate } from './PushToRateUpdate';




export default function UpdateForm({ accessType, hotDateData }) {

    //Heena
    const [hotDateFlag, setHotDateFlag] = useState(false)
    const [hotDateCarClass, setHotDateCarClass] = useState('')


    const daysNameTable = [['Monday', 'Friday'], ['Tuesday', 'Saturday'], ['Wednesday', 'Sunday'], ['Thursday', null]]
    const [rowData, setRowData] = useState({});
    const [trueKeys, setTrueKeys] = useState({});
    const [defaultCheck, setDefaultCheck] = useState({})
    const [defaultDate, setDefaultDate] = useState(new Date())
    const [locationCurrency, setLocationCurrency] = useState('');

    const changeDaysCheckBox = (key, day, checked) => {
        let countFalses = countFalseValues()
        if (checked == true && countFalses == 1) {
            setDefaultCheck({ ...defaultCheck, [key]: true });
        }
        if (checked == false) {
            setDefaultCheck({ ...defaultCheck, [key]: checked });

        }
        updateRowData(key, { ...rowData[key], [day]: checked });
    };

    const handleTether = (e) => {
        e.preventDefault();
        setTetheringShow(!tetheringShow);
    }

    const handleDatePickerChange = (key, date) => {
        console.log("date value", date._d);
        setDefaultDate(date._d);
        updateRowData(key, { ...rowData[key], defaultDate: date._d });
    };

    const updateRowData = (key, newData) => {
        setRowData(prevData => ({
            ...prevData,
            [key]: newData
        }));
    };

    const countFalseValues = () => {
        let count = 0;
        for (let key in rowData) {
            for (let day in rowData[key]) {
                if (!rowData[key][day]) {
                    count++;
                }
            }
        }
        return count;
    }
    const changeAllDaysCheckBox = (key, checked) => {
        if (checked == true) {
            setDefaultCheck({ ...defaultCheck, [key]: checked });
            let temp = { ...daysNameHash };
            let updatedData = { ...rowData[key] }; // Initialize updatedData with existing rowData[key]
            for (const day of Object.keys(temp)) {
                updatedData[day] = true; // Update the value for all days to true
            }
            setRowData({ ...rowData, [key]: updatedData });
        } else if (checked == false) {
            setDefaultCheck({ ...defaultCheck, [key]: checked });
            let temp = { ...daysNameHash };
            let updatedData = { ...rowData[key] }; // Initialize updatedData with existing rowData[key]
            for (const day of Object.keys(temp)) {
                updatedData[day] = false; // Update the value for all days to true
            }
            setRowData({ ...rowData, [key]: updatedData });
        }
    };

    const handleTetheringSubmit = (e) => {
        e.preventDefault();
        setIsChecked(true);

        setTimeout(() => {
            Swal.fire("Tethering Criteria Submitted Successfully")
            setTetheringShow(false);

        }, 3000);
    }

    const [checkAll, setCheckAll] = useState(false)
    const [selectedRow, setSelectedRow] = useState(-1);
    const [editedPrices, setEditedPrices] = useState({});
    const [tetheringShow, setTetheringShow] = useState(false);
    const [currencyShow, setCurrencyShow] = useState(false);
    const [currencyConversionValue, setCurrencyConversionValue] = useState(1);

    // const [showPopup, setShowPopup] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [currentTab, setCurrentTab] = useState(null);

    const handleCheckboxChange = (index) => {
        setSelectedRow(index === selectedRow ? null : index);
    };

    const currencyOption = [
        { id: 1, value: "USD", label: "USD" },
        { id: 2, value: "CAD", label: "CAD" },
        { id: 3, value: "EUR", label: "EUR" },
        { id: 4, value: "AUD", label: "AUD" },
    ];

    const rateCodeOptions = [
        { id: 1, value: "Both", label: "Both" },
        { id: 2, value: "Pay On Arrival", label: "Pay On Arrival" },
        { id: 2, value: "Prepaid", label: "Prepaid" },
    ];
    const pickUpTime = null;
    // const handleToggleCarClass = (carClass) => {
    //     if (selectedCarClasses.includes(carClass)) {
    //         setSelectedCarClasses(selectedCarClasses.filter(cc => cc !== carClass)); // Deselect car class
    //     } else {
    //         setSelectedCarClasses([...selectedCarClasses, carClass]); // Select car class
    //     }
    // }

    const handleToggleCarClass = (carClass) => {
        setSelectedCarClasses({
            ...selectedCarClasses,
            [carClass]: !selectedCarClasses[carClass] // Toggle the value of the clicked car class
        });
    }

    const handleToggleCompetitors = (competitor) => {
        setSelectedCompetitors({
            ...selectedCompetitors,
            [competitor]: !selectedCompetitors[competitor] // Toggle the value of the clicked car class
        });
    }

    // const handleSelectAll2 = () => {
    //     if (carClassAll) {
    //         setSelectedCarClasses([]); // Deselect all car classes
    //     } else {
    //         setSelectedCarClasses(carClasses); // Select all car classes
    //     }
    //     setCarClassAll(!carClassAll); // Toggle "Select All" state
    // }
    
    const handleSelectAllCompetitors2 = () => {
        const allCompetitors = areAllCompetitorsSelected();
        const updatedSelectedCompetitors = {};
        for (const competitor in selectedCompetitors) {
            updatedSelectedCompetitors[competitor] = !allCompetitors;
        }
        setSelectedCompetitors(updatedSelectedCompetitors);
    }

    const handleSelectAll2 = () => {
        const allSelected = areAllCarClassesSelected();
        const updatedSelectedCarClasses = {};
        for (const carClass in selectedCarClasses) {
            updatedSelectedCarClasses[carClass] = !allSelected;
        }
        setSelectedCarClasses(updatedSelectedCarClasses);
    }

    const areAllCarClassesSelected = () => {
        for (const carClass in selectedCarClasses) {
            if (!selectedCarClasses[carClass]) {
                return false;
            }
        }
        return true;
    }

    const areAllCompetitorsSelected = () => {
        for (const competitor in selectedCompetitors) {
            if (!selectedCompetitors[competitor]) {
                return false;
            }
        }
        return true;
    }


    useEffect(() => {
        // Create a style element
        const styleElement = document.createElement('style');
        styleElement.type = 'text/css';
        // Define your CSS rules
        const cssRules = `
          :root {
            --background-color: white;
          }
        `;
        // Set the CSS rules as the content of the style element
        if ('textContent' in styleElement) {
            styleElement.textContent = cssRules;
        } else {
            styleElement.styleSheet.cssText = cssRules;
        }

        // Append the style element to the document head
        document.head.appendChild(styleElement);

        // Clean up function
        return () => {
            // Remove the style element from the document head
            document.head.removeChild(styleElement);
        };
    }, []);


    // const handlePriceChange = (e, carClass) => {
    //     const { value } = e.target;

    //     // Assuming selectedLowestValues is a state variable
    //     setSelectedLowestValue(prevState => {
    //       // Find the index of the row that corresponds to the carClass
    //       const rowIndex = prevState.findIndex(row => row.hasOwnProperty(carClass));
    //       if (rowIndex !== -1) {
    //         // If the carClass exists in the selectedLowestValues array, update its price
    //         const updatedRow = {
    //           ...prevState[rowIndex],
    //           [carClass]: parseFloat(value) // Assuming prices are numeric, parse the value as float
    //         };
    //         // Update the selectedLowestValues array with the updated row
    //         return [
    //           ...prevState.slice(0, rowIndex),
    //           updatedRow,
    //           ...prevState.slice(rowIndex + 1)
    //         ];
    //       }
    //       return prevState; // If carClass not found, return the previous state
    //     });
    //   };




    const handleAccept = () => {
        // window.location.reload();
        console.log(selectedLowestValue)
        console.log(selectedIndex)

        if (Array.isArray(selectedLowestValue) && selectedLowestValue.length === 0) {
            Swal.fire('Please select at any Strategy data');
            return;
        }

        let keysWithEmptyValues = [];
        for (let i = 0; i < selectedLowestValue.length; i++) {
            const obj = selectedLowestValue[i];
            for (let key in obj) {
                if (obj.hasOwnProperty(key)) {
                    if (isNaN(obj[key]) || obj[key] === 0) {
                        keysWithEmptyValues.push(key);
                    }
                }
            }
        }

        if (keysWithEmptyValues.length > 0) {
            const keysMessage = keysWithEmptyValues.join(', ');
            Swal.fire(`The following Car Classes have empty prices: ${keysMessage}. Please enter the Price.`);
            return;
        }

        const body_payload =
        {
            account_id: company_id.toString(),
            location_id: locationId.toString(),
            pickup_time: selectedPickUpTime,
            sipp_code: selectedCarClass,
            strategyOptions: selectedOptions,
            carClassData: selectedLowestValue,
            updatingLOr: selectedIndex,
            from_date: new Date(fromDate).toDateString(),
            created_by: loggedInUserDetail.userEmail,
            updated_by: loggedInUserDetail.userEmail,
            created_date: new Date(),
            updated_date: new Date()
        };
        // console.log(body_payload)
        // return

        axios
            .post(baseURL + "/save_instant_pro_in_rate_update", body_payload)
            .then((res2) => {
                // scrollToBottom();
                // setTimeDuration(1)
                // setLoading2(false);
                // setLoading(false);
                console.log("res2:", res2)
                // console.log(fromDate)

            });
    }

    // const handlePriceChange = (e, carClass, priceIndex) => {
    //   const newEditedPrices = { ...editedPrices };
    //   if (!newEditedPrices[carClass]) {
    //     newEditedPrices[carClass] = [...Array(Object.values(responseData[activeTab]?.competitors_result || {})[0].length).fill('')];
    //   }
    //   newEditedPrices[carClass][priceIndex] = e.target.value;
    //   setEditedPrices(newEditedPrices);
    // };

    const fetchRates = () => {
        // Fetch rates for the selected row
        if (selectedRow !== null) {
            // Perform your fetch operation here using selectedRow
            console.log("Fetching rates for row:", selectedRow);
            console.log("Edited prices:", editedPrices);
        } else {
            console.log("No row selected");
        }
    };


    const formatDate = (date) => {
        return [
            padTo2Digits(date.getMonth() + 1),
            padTo2Digits(date.getDate()),
            date.getFullYear(),
        ].join('-');
    }

    const CustomLoader = () => {
        return (
            // <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh', backgroundColor: 'rgba(0, 0, 0, 0.5)', position: 'fixed', top: 0, left: 0, right: 0, bottom: 0 }}>
            // <h2 style={{ color: '#fff', marginBottom: '20px' }}>Please wait, while we are fetching the live rates for you...</h2>
            // <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            //     <img src={budget} alt="Loader" style={{width: '120px', height: '80px', marginRight: '10px', animation: 'rotate 2s infinite linear' }} />
            //     <img src={europcar} alt="Loader" style={{width: '120px', height: '80px', marginRight: '10px', animation: 'rotate 2s infinite linear' }} />
            //     <img src={ace} alt="Loader" style={{width: '120px', height: '80px', marginRight: '10px', animation: 'rotate 2s infinite linear' }} />
            //     <img src={national} alt="Loader" style={{width: '120px', height: '80px', marginRight: '10px', animation: 'rotate 2s infinite linear' }} />
            //     <img src={alamo} alt="Loader" style={{width: '120px', height: '80px', marginRight: '10px', animation: 'rotate 2s infinite linear' }} />
            //     {/* <img src={advantage} alt="Loader" style={{width: '150px', height: '100px', marginRight: '10px', animation: 'rotate 2s infinite linear' }} /> */}
            //     <img src={dollar} alt="Loader" style={{width: '120px', height: '80px', animation: 'rotate 2s infinite linear' }} />
            // </div>
            // <style>
            //     {`
            //     @keyframes rotate {
            //         from {
            //         transform: rotate(0deg);
            //         }
            //         to {
            //         transform: rotate(360deg);
            //         }
            //     }
            //     `}
            // </style>
            // </div>

            //     <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh', backgroundColor: 'rgba(0, 0, 0, 0.5)', position: 'fixed', top: 0, left: 0, right: 0, bottom: 0 }}>
            //     <h2 style={{ color: '#fff', marginBottom: '20px' }}>Please wait, while we are fetching the live rates for you...</h2>
            //     <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            //         <img src={budget} alt="Loader" style={{ width: '120px', height: '80px', marginRight: '10px', animation: 'moveLeftToRight 2s infinite linear' }} />
            //         <img src={europcar} alt="Loader" style={{ width: '120px', height: '80px', marginRight: '10px', animation: 'moveLeftToRight 2s infinite linear' }} />
            //         <img src={ace} alt="Loader" style={{ width: '120px', height: '80px', marginRight: '10px', animation: 'moveLeftToRight 2s infinite linear' }} />
            //         <img src={national} alt="Loader" style={{ width: '120px', height: '80px', marginRight: '10px', animation: 'moveLeftToRight 2s infinite linear' }} />
            //         <img src={alamo} alt="Loader" style={{ width: '120px', height: '80px', marginRight: '10px', animation: 'moveLeftToRight 2s infinite linear' }} />
            //         <img src={dollar} alt="Loader" style={{ width: '120px', height: '80px', animation: 'moveLeftToRight 2s infinite linear' }} />
            //     </div>
            //     <style>
            //     {`
            //         @keyframes moveLeftToRight {
            //         0% {
            //             transform: translateX(-100%);
            //         }
            //         100% {
            //             transform: translateX(100%);
            //         }
            //         }
            //     `}
            //     </style>
            // </div>

            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh', backgroundColor: 'rgba(0, 0, 0, 0.5)', position: 'fixed', top: 0, left: 0, right: 0, bottom: 0 }}>
                <h2 style={{ color: '#fff', marginBottom: '20px' }}>Please wait, while we are fetching the live rates for you...</h2>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {/* First set of images (left to right) */}
                    <img src={routes} alt="Loader" style={{ width: '80px', height: '50px', marginRight: '10px', animation: 'moveLeftToRight 2s infinite linear' }} />
                    {/* <img src={europcar} alt="Loader" style={{ width: '120px', height: '80px', marginRight: '10px', animation: 'moveLeftToRight 2s infinite linear' }} /> */}
                    <img src={ace} alt="Loader" style={{ width: '80px', height: '50px', marginRight: '10px', animation: 'moveLeftToRight 2s infinite linear' }} />
                    <img src={national} alt="Loader" style={{ width: '80px', height: '50px', marginRight: '10px', animation: 'moveLeftToRight 2s infinite linear' }} />
                    <img src={alamo} alt="Loader" style={{ width: '80px', height: '50px', marginRight: '10px', animation: 'moveLeftToRight 2s infinite linear' }} />
                    <img src={dollar} alt="Loader" style={{ width: '80px', height: '50px', animation: 'moveLeftToRight 2s infinite linear' }} />
                </div>

                {/* Second set of images (right to left) */}
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}>
                    <img src={budget} alt="Loader" style={{ width: '80px', height: '50px', marginRight: '10px', animation: 'moveRightToLeft 2s infinite linear' }} />
                    <img src={europcar} alt="Loader" style={{ width: '80px', height: '50px', marginRight: '10px', animation: 'moveRightToLeft 2s infinite linear' }} />
                    <img src={fox} alt="Loader" style={{ width: '80px', height: '50px', marginRight: '10px', animation: 'moveRightToLeft 2s infinite linear' }} />
                    <img src={greenMotion} alt="Loader" style={{ width: '80px', height: '50px', marginRight: '10px', animation: 'moveRightToLeft 2s infinite linear' }} />
                    {/* <img src={alamo} alt="Loader" style={{ width: '120px', height: '80px', marginRight: '10px', animation: 'moveRightToLeft 2s infinite linear' }} /> */}
                    <img src={thrifty} alt="Loader" style={{ width: '80px', height: '50px', animation: 'moveRightToLeft 2s infinite linear' }} />
                </div>

                {/* Animation styles */}
                <style>
                    {`
        @keyframes moveLeftToRight {
            0% {
                transform: translateX(-100%);
            }
            100% {
                transform: translateX(100%);
            }
        }

        @keyframes moveRightToLeft {
            0% {
                transform: translateX(100%);
            }
            100% {
                transform: translateX(-100%);
            }
        }
    `}
                </style>
            </div>



        );
    };

    const formatDate_Modal = (date) => {
        return [
            date.getFullYear(),
            padTo2Digits(date.getMonth() + 1),
            padTo2Digits(date.getDate())
        ].join('-');
    }

    const padTo2Digits = (num) => {
        return num.toString().padStart(2, '0');
    }

    const handleRefresh = () => {
        window.location.reload();
    };

    const [loading2, setLoading2] = useState(false);
    const [competitorsList, setCompetitorsList] = useState([{}]);
    const [selectedPickUpTime, setSelectedPickUpTime] = useState('');
    const [selectedRateParity, setSelectedRateParity] = useState('Static');
    const [numbers, setNumbers] = useState({ "1": false, "2": false, '3': false, '4': false, '5': false, '6': false, 'Weekly': false, 'Monthly': false })
    // const [strategyOptions, setStrategyOptions] = useState({'Lowest' : false, '2nd Lowest' : false, '3rd Lowest' : false, 'Lowest Off-Airport' : false, '2nd Lowest Off-Airport' : false, '3rd Lowest Off-Airport' : false, 'Lowest In-Terminal' : false, '2nd Lowest In-Terminal' : false, '3rd Lowest In-Terminal' : false, 'Lowest among all the Car Classes' : false, 'Leave as it is' : false});
    const [strategyOptions, setStrategyOptions] = useState({
        'Lowest': false,
        '2nd Lowest': false,
        '3rd Lowest': false,
        'Lowest Off-Airport': false,
        '2nd Lowest Off-Airport': false,
        '3rd Lowest Off-Airport': false,
        'Lowest In-Terminal': false,
        '2nd Lowest In-Terminal': false,
        '3rd Lowest In-Terminal': false,
        'Lowest among all the Car Classes': false,
        // 'Leave as it is': false,
    });
    const [loading, setLoading] = useState(false);

    // const handleOptionChange = (option) => {
    //     setStrategyOptions({
    //       ...strategyOptions,
    //       [option]: !strategyOptions[option],
    //     });
    // };

    const handleSelectAllStrategies = () => {
        const allSelected = Object.values(strategyOptions).every((value) => value === true);
        const updatedOptions = {};

        for (const option in strategyOptions) {
            updatedOptions[option] = !allSelected;
        }

        setStrategyOptions(updatedOptions);
    };

    const chunkArray = (array, size) => {
        const chunkedArr = [];
        for (let i = 0; i < array.length; i += size) {
            chunkedArr.push(array.slice(i, i + size));
        }
        return chunkedArr;
    };

    const optionsArray = Object.entries(strategyOptions);
    const rows = chunkArray(optionsArray, 3);
    // const [responseData, setResponseData] = useState(null);
    const [responseData, setResponseData] = useState({});
    const [activeTab, setActiveTab] = useState(null);

    // const competitors = Object.keys(responseData.competitors);
    // const carClasses = Object.keys(responseData.lowest_total);

    const mainDropdownOptions = ['Lowest', '2nd Lowest', '3rd Lowest', 'Lowest Off-Airport', '2nd Lowest Off-Airport', '3rd Lowest Off-Airport', 'Lowest In-Terminal', '2nd Lowest In-Terminal', '3rd Lowest In-Terminal', 'Lowest among all the Car Classes', 'Custom', 'Leave as it is'];
    const changeOptions = ['Dollar ($)', 'Percentage (%)']; // Replace this with your additional dropdown options
    const [sippValues, setSelectedSippValues] = useState([]);
    const [sippValues2, setSelectedSippValues2] = useState([]);
    const [sippValues3, setSelectedSippValues3] = useState([]);
    const [selectedStrategy, setSelectedStrategy] = useState([]);
    const [selectedAmount, setSelectedAmount] = useState([]);
    const [selectedCompetitor, setSelectedCompetitor] = useState([]);
    const [selectedChange, setSelectedChange] = useState([]);
    const [timeDuration, setTimeDuration] = useState(0);
    const [selectedRateSourceOption, setSelectedRateSourceOption] = useState('Expedia');
    const [selectedCurrencyOption, setSelectedCurrencyOption] = useState('');
    const [selectedRateCodeOption, setSelectedRateCodeOption] = useState('');
    const [selectedCompetitionOption, setSelectedCompetitionOption] = useState('SIPP CODE');
    const [expediaOptions, setExpediaOptions] = useState(['Economy', 'Compact', 'MidSize', 'Standard', 'Full-Size', 'Premium', 'Luxury', 'Convertible', 'Minivan', 'SUV', 'Compact SUV', 'Midsize SUV', 'Standard SUV', 'Standard Elite SUV', 'Fullsize SUV', 'Premium SUV', 'Luxury SUV', 'Special SUV', 'Van', 'Pickup', 'Sports Car', 'Other']);
    const [pricelineOptions, setPricelineOPtions]  = useState(['Car','Economy', 'Compact','MidSize', 'Standard', 'Full-Size','Premium', 'Luxury Car', 'Sports Car', 'SUV', 'Compact SUV', 'Midsize-SUV', 'Standard SUV', 'Standard Elite SUV', 'Full-Size SUV','Full-Size Elite SUV', 'Premium SUV', 'Premium Crossover', 'Luxury SUV', 'Van', 'Minivan']);
    const [companyDetail, setcompanyDetail] = useState([{}]);
    const [companyName, setCompanyName] = useState("");
    const [company_id, setcompany_id] = useState("");
    const [locationDetail, setLocationDetail] = useState([{}]);
    const [locationName, setLocationName] = useState("");
    const [locationId, setLocationId] = useState("");
    const [headerData, setHeaderData] = useState("");
    const [listOfRateCodes, setListOfRateCodes] = useState({})
    const [table, setTable] = useState([])
    const [daysNameHash, setDaysNameHash] = useState({ "Monday": false, "Tuesday": false, 'Wednesday': false, 'Thursday': false, 'Friday': false, 'Saturday': false, 'Sunday': false })
    const [carDetails, setCarDetails] = useState([])
    const [carDetailsInOption1, setCarDetailsInOption1] = useState([])
    const [carDetailsInOption2, setCarDetailsInOption2] = useState([])
    const [carDetailsInOptionConstant, setCarDetailsInOptionConstant] = useState([])
    const [fleetStandardCarDetails, setFleetStandardCarDetails] = useState([])
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [selectAllDaysFlag, setSelectAllDaysFlag] = useState(true)
    const [instantAutomationShow, setInstantAutomationShow] = useState("NO")
    const [fromDate, setFromDate] = useState(new Date())
    const [toDate, setToDate] = useState(new Date())
    const [instantFromDate, setInstantFromDate] = useState(new Date());
    const [instantToDate, setInstantToDate] = useState(new Date());
    const [dateArr, setDateArr] = useState([])
    const [selectedDatePicker, setSelectedDatePicker] = useState('')
    const [loggedInUserDetail, setLoggedInUserDetail] = useState({});
    const [rateCodeNames, setRateCodeNames] = useState({})
    const [allCarClassData, setAllCarClassData] = useState([]);
    const [selectedCarClass, setSelectedCarClass] = useState([]);
    // const [carClassAll, setCarClassAll] = useState(false);
    const [allCompetitors, setAllCompetitors] = useState([]);
    const [selectedCompetitors, setSelectedCompetitors] = useState([]);
    const [competitorsAll, setCompetitorsAll] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState('');

    const [selectedLowestValue, setSelectedLowestValue] = useState({});
    const [selectedIndex, setSelectedIndex] = useState('');

    const [carClasses, setCarClasses] = useState([]); // Define an array to store car classes
    const [selectedCarClasses, setSelectedCarClasses] = useState([]); // Define an array to store selected car classes
    const [carClassAll, setCarClassAll] = useState(false);


    // const handleOptionChange = (event) => {
    //     setSelectedOption(event.target.value);
    // };
    // const handleOptionChange = (e) => {
    //     const { value } = e.target;
    //     if (selectedOptions.includes(value)) {
    //         setSelectedOptions(selectedOptions.filter(option => option !== value));
    //     } else {
    //         setSelectedOptions([...selectedOptions, value]);
    //     }
    // };

    	
    //setting default values from HotDates --- Heena
    useEffect(() => {
        if (hotDateData.length !== 0) {
            setHotDateFlag(true)
            setCompanyName(hotDateData['companyName']);
            setcompany_id(hotDateData['companyID'])
            setSelectedCompetitionOption('SIPP CODE')
            if(hotDateData['rateSource'] == 'EXI')
            {
                setSelectedRateSourceOption('Expedia')
            }
            else
            {
                setSelectedRateSourceOption('Priceline')
            }
            setSelectedPickUpTime(hotDateData['pickupTime'])
            const initialDateString = hotDateData['pickupDate'];
            const formattedDate = parse(initialDateString, "yyyy-MM-dd", new Date());
            console.log(formattedDate)
            setInstantFromDate(formattedDate)
            setInstantToDate(formattedDate)
            const loc_array = [{
                location_id : hotDateData['locationID'],
                location_name : hotDateData['locationName']
            }]
            setLocationDetail(loc_array)
            setLocationName(hotDateData['locationName'])
            setLocationId(hotDateData['locationID'])
            updateState(hotDateData['lor'].toString(), setNumbers);
            setHotDateCarClass(hotDateData['carClass'])
          
        }
    }, [hotDateData]); // Dependency array ensures this runs when hotDateData changes
    
    const updateState = (key, setState) => {
        setState(prevState => ({
          ...Object.keys(prevState).reduce((acc, k) => ({ ...acc, [k]: false }), {}),
          [key]: true
        }));
      };


    const handleOptionChange = (value) => {
        if (selectedOptions.includes(value)) {
            setSelectedOptions(selectedOptions.filter(option => option !== value));
        } else {
            setSelectedOptions([...selectedOptions, value]);
        }
    };

    const rateSourceOptions = [
        { value: "Expedia", label: "Expedia" },
        { value: "Priceline", label: "Priceline" }
    ];

    const competitionOptions = [
        { value: "SIPP CODE", label: "SIPP CODE" }
        // { value: "Car Category", label: "Car Category" }
    ];

    const parityOptions = [
        { value: "Static", label: "Static" },
        { value: "Multiple of LOR", label: "Multiple of LOR" }
    ];

    const handleCarClass = (val) => {
        let tempObj = { ...selectedCarClass };
        tempObj[val] = !selectedCarClass[val];

        let keyArr = Object.keys(tempObj);

        let count = 0;
        for (let i = 0; i < keyArr.length; i++) {
            if (tempObj[keyArr[i]] === true)
                count++;
        }

        if (count < keyArr.length) {
            setCarClassAll(false)
        }
        else {
            setCarClassAll(true)
        }
        setSelectedCarClass(tempObj);
        setSelectedCarClasses(tempObj)
    }

    //   const handleLowestValues = (val, val2) => {
    //     console.log("lowest Values")
    //     console.log(val)
    //     console.log(val2)
    //     setSelectedLowestValue(val)
    //     console.log(selectedLowestValue)

    //   }
    // const handleLowestValues = (value, rowIndex) => {
    //     setSelectedLowestValue(value)
    //     setSelectedIndex(rowIndex)
    //     console.log(value)
    //     console.log(rowIndex)
    // };

    const handleLowestValues = (rowData, tab) => {
        const rowData_temp = JSON.parse(JSON.stringify(rowData));
        setSelectedLowestValue(prevState => ({
            ...prevState,
            [tab]: rowData_temp,
        }));
        setCurrentTab(tab)
        console.log('tab:', tab);
        console.log('rowData:', rowData_temp)
        console.log('hhhhhhhhhhhhhh:', selectedLowestValue)
    };

    // const handlePriceChange = (e, carClass) => {
    //     const { value } = e.target;

    //     // Find the index of the selected row
    //     const rowIndex = selectedLowestValue.findIndex(row => Object.keys(row).includes(carClass));

    //     // If the selected row exists
    //     if (rowIndex !== -1) {
    //         // Update the price for the specified car class
    //         const updatedRow = {
    //             ...selectedLowestValue[rowIndex],
    //             [carClass]: parseFloat(value) // Assuming prices are numeric, parse the value as float
    //         };

    //         // Update the selectedLowestValue array with the updated row
    //         const updatedLowestValues = [
    //             ...selectedLowestValue.slice(0, rowIndex),
    //             updatedRow,
    //             ...selectedLowestValue.slice(rowIndex + 1)
    //         ];

    //         // Update the state with the modified array
    //         setSelectedLowestValue(updatedLowestValues);
    //         console.log('selectedLowestValue:', updatedLowestValues);
    //     }

    // };  
    const handlePriceChange = (e, carClass) => {

        const { value } = e.target;
        const updatedLowestValues = JSON.parse(JSON.stringify(selectedLowestValue));
        updatedLowestValues[activeTab][0][carClass] = value

        setSelectedLowestValue(updatedLowestValues);

    };

    const handleTabClick = (e, tab) => {
        setActiveTab(tab);
    };

    // const handleTabClick = (e, tabKey) => {
    //     e.preventDefault(); // Prevent the default form submission behavior
    //     setActiveTab(tabKey);
    //     setSelectedLowestValue([])
    //     // setSelectedIndex(selectedIndex)
    // };


    const handleCompetitors = (val) => {
        // console.log(val)
        let tempObj = { ...selectedCompetitors };
        tempObj[val] = !selectedCompetitors[val];

        let keyArr = Object.keys(tempObj);

        let count = 0;
        for (let i = 0; i < keyArr.length; i++) {
            if (tempObj[keyArr[i]] === true)
                count++;
        }

        if (count < keyArr.length) {
            setCompetitorsAll(false)
        }
        else {
            setCompetitorsAll(true)
        }
        setSelectedCompetitors(tempObj);
    }

    const handleSelectedCompetitors = (val) => {

        setCompetitorsAll(val)
        let tempObj = { ...selectedCompetitors };
        let keyArr = Object.keys(selectedCompetitors);

        for (let i = 0; i < keyArr.length; i++) {

            tempObj[keyArr[i]] = val;
        }
        setSelectedCompetitors(tempObj);
    }

    const handleCarClassAll = (val) => {

        setCarClassAll(val)
        let tempObj = { ...selectedCarClass };
        let keyArr = Object.keys(selectedCarClass);

        for (let i = 0; i < keyArr.length; i++) {

            tempObj[keyArr[i]] = val;
        }

        setSelectedCarClass(tempObj);
        setSelectedCarClasses(tempObj)
    }

    const hours = [
        { "name": "00:00" }, { "name": "00:30" }, { "name": "01:00" }, { "name": "01:30" }, { "name": "02:00" }, { "name": "02:30" }, { "name": "03:00" },
        { "name": "03:30" }, { "name": "04:00" }, { "name": "04:30" }, { "name": "05:00" }, { "name": "05:30" }, { "name": "06:00" }, { "name": "06:30" },
        { "name": "07:00" }, { "name": "07:30" }, { "name": "08:00" }, { "name": "08:30" }, { "name": "09:00" }, { "name": "09:30" }, { "name": "10:00" },
        { "name": "10:30" }, { "name": "11:00" }, { "name": "11:30" }, { "name": "12:00" }, { "name": "12:30" }, { "name": "13:00" }, { "name": "13:30" },
        { "name": "14:00" }, { "name": "14:30" }, { "name": "15:00" }, { "name": "15:30" }, { "name": "16:00" }, { "name": "16:30" }, { "name": "17:00" },
        { "name": "17:30" }, { "name": "18:00" }, { "name": "18:30" }, { "name": "19:00" }, { "name": "19:30" }, { "name": "20:00" }, { "name": "20:30" },
        { "name": "21:00" }, { "name": "21:30" }, { "name": "22:00" }, { "name": "22:30" }, { "name": "23:00" }, { "name": "23:30" }, { "name": "23:59" },
    ];

    const handleSelectAll = () => {
        const areAllSelected = Object.values(numbers).every((value) => value);
        const updatedNumbers = {};

        for (const key in numbers) {
            updatedNumbers[key] = !areAllSelected;
        }
        setNumbers(updatedNumbers);
    };

    const handleCurrencyOption = (value) => {
        if (value.value != "USD") {
            setCurrencyShow(!currencyShow);
        }
        setSelectedCurrencyOption(value);
    }
    // const handleCurrencyOption = (value) => {
    //     console.log("currencyValue",value)
    //     // console.log(value.value)
    //     setSelectedCurrencyOption(value.value);
    //     if (value.value != "USD") {
    //         setCurrencyShow(true);
    //     }
    // }

    const handleRateCodeOption = (value) => {
        setSelectedRateCodeOption(value);
    }

    const handleTetherCheckboxChange = () => {
        console.log(isChecked)
        if (isChecked == false) {
            setTetheringShow(!tetheringShow);
        }
        setIsChecked(!isChecked);

    };

    const handleRateSourceOption = (value) => {
        console.log("value",value)
        setSelectedRateSourceOption(value);
    };

    const handleCompetitionOption = (value) => {
        setSelectedCompetitionOption(value);
        // if (value == "SIPP CODE") {
        //     setCarDetailsInOption1(carDetailsInOptionConstant)
        //     setCarDetailsInOption2(fleetStandardCarDetails)
        // } else if (value == "Car Category") {
        //     if (selectedRateSourceOption == "Expedia") {
        //         setCarDetailsInOption1(expediaOptions)
        //         setCarDetailsInOption2(expediaOptions)
        //     } else if (selectedRateSourceOption == "Priceline") {
        //         setCarDetailsInOption1(carDetailsInOptionConstant)
        //         setCarDetailsInOption2(fleetStandardCarDetails)
        //         // setSelectedCompetitionOption('SIPP CODE');
        //     }
        // }
    };

    const handleComptitorSippCodes = (index, value) => {
        setSelectedSippValues((prevValues) => {
            const updatedValues2 = [...prevValues];
            updatedValues2[index] = { compSippValues: value };
            return updatedValues2;
        });
    };

    const handleComptitorSippCodes2 = (index, value) => {
        setSelectedSippValues2((prevValues2) => {
            const updatedValues2 = [...prevValues2];
            updatedValues2[index] = { compSippValues2: value };
            return updatedValues2;
        });
    };

    const handleComptitorSippCodes3 = (index, value) => {
        setSelectedSippValues3((prevValues3) => {
            const updatedValues2 = [...prevValues3];
            updatedValues2[index] = { compSippValues3: value };
            return updatedValues2;
        });
    };

    const handleStrategy = (index, value) => {
        setSelectedStrategy((prevValues3) => {
            const updatedValues3 = [...prevValues3];
            updatedValues3[index] = { strategyValues: value };
            return updatedValues3;
        });

        if (value != 'custom') {
            setSelectedCompetitor((prevValues4) => {
                const updatedValues4 = [...prevValues4];
                updatedValues4[index] = { competitorValues: '' };
                return updatedValues4;
            });

            setSelectedAmount((prevValues6) => {
                const updatedValues6 = [...prevValues6];
                updatedValues6[index] = { amountValues: '' };
                return updatedValues6;
            });

            setSelectedChange((prevValues5) => {
                const updatedValues5 = [...prevValues5];
                updatedValues5[index] = { changeValues: '' };
                return updatedValues5;
            });
        }
    };

    const handleAmount = (index, value) => {
        setSelectedAmount((prevValues6) => {
            const updatedValues6 = [...prevValues6];
            updatedValues6[index] = { amountValues: value };
            return updatedValues6;
        });
    };

    const handleChange = (index, value) => {
        setSelectedChange((prevValues5) => {
            const updatedValues5 = [...prevValues5];
            updatedValues5[index] = { changeValues: value };
            return updatedValues5;
        });
    };

    // function scrollToBottom() {
    //     const scrollPosition = document.body.scrollHeight - window.innerHeight;
    //     // Scroll to the calculated position
    //     window.scrollTo({ top: scrollPosition, behavior: 'smooth' });
    // }

    function scrollToBottom() {
        const scrollPosition = document.body.scrollHeight - window.innerHeight;
        // Scroll to the calculated position
        window.scrollTo({ top: scrollPosition, behavior: 'smooth' });
    }

    const handleCompetitor = (index, value) => {
        setSelectedCompetitor((prevValues4) => {
            const updatedValues4 = [...prevValues4];
            updatedValues4[index] = { competitorValues: value };
            return updatedValues4;
        });
    };

    const handleInputChangeCompanyName = characterEntered => {
        setCompanyName(characterEntered.value);
    };

    const handleInputChangeLocationName = characterEntered => {
        setLocationName(characterEntered.value);
    };

    const handleNumberSelection = (number) => {
        setNumbers({
            ...numbers,
            [number]: !numbers[number],
        });
    };

    const handlePickUpTime = (value) => {
        setSelectedPickUpTime(value);
    };
    const handleParity = (value) => {
        setSelectedRateParity(value);
    };

    useEffect(() => {
        axios
        .get(baseURL + "/getLocationCurrency/" + locationName)
        .then((res) => {

            console.log("location Currency Response", res)
            setLocationCurrency(res.data)
        });
    }, [locationName]);

    // const handleAutomationSubmit = (e) => {
    const handleAutomationSubmit = async (e) => {       
        e.preventDefault();
        if (companyName === "") {
            Swal.fire('Please select Account Name');
            return;
        }
        if (locationName === "") {
            Swal.fire('Please select a Location to proceed');
            return;
        }
        if (selectedRateSourceOption === "") {
            Swal.fire('Please select Rate Source');
            return;
        }
        if (selectedCompetitionOption === "") {
            Swal.fire('Please select Competition By');
            return;
        }
        if (selectedRateCodeOption === "") {
            Swal.fire('Please select Rate Code');
            return;
        }
        const hasTrueValueForLor = Object.values(numbers).some((value) => value === true);
        const trueKeys2 = Object.keys(numbers).filter(key => numbers[key] === true);
        setTrueKeys(trueKeys2)
        if (selectedPickUpTime === "") {
            Swal.fire('Please select a PickUp Time');
            return;
        }
        if (!hasTrueValueForLor) {
            Swal.fire("Please select atleast one Lor");
            return;
        }
        const hasTrueValueForCarClasses = Object.values(selectedCarClasses).some((value) => value === true);
        if (!hasTrueValueForCarClasses) {
            Swal.fire("Please select atleast one Car Class");
            return;
        }

        const hasTrueValueForCompetitors = Object.values(selectedCompetitors).some((value) => value === true);
        if (!hasTrueValueForCompetitors) {
            Swal.fire("Please select atleast one Competitor");
            return;
        }

        console.log('hasTrueValueForLor',hasTrueValueForLor)
        console.log('selectedRateCodeOption',selectedRateCodeOption)
        console.log('selectedCompetitionOption',selectedCompetitionOption)

        // console.log(selectedCarClass)
        // console.log(selectedCompetitors)
        // console.log(numbers)
        // console.log(selectedPickUpTime)
        // console.log(selectedRateSourceOption)
        // console.log(fromDate)
        // console.log(strategyOptions)
        console.log('selectedCarClasses',selectedCarClasses)


        // setLoading2(true)
        // setButtonDisabled(true);
        // setTimeDuration(timeDurationValue)
        // console.log(selectedOptions)
        console.log('selectedOptions',selectedOptions)
        if (Array.isArray(selectedOptions) && selectedOptions.length === 0) {
            Swal.fire("Please Select Strategy")
            return;
        }
        if (selectedOptions === "") {
            Swal.fire("Please Select Strategy")
            return;
        }
        const momentObject = Moment(instantFromDate);
        const formattedDate = momentObject.format('MMMM Do, YYYY');
        setHeaderData(locationName + ' - ' + formattedDate)
        scrollToBottom();
        if (selectedCurrencyOption == 'USD') {
            setCurrencyConversionValue(1)
        }
        // return "success"
        const body_payload =
        {
            location_name: locationName,
            account_id: company_id.toString(),
            location_id: locationId.toString(),
            pickup_time: selectedPickUpTime,
            rateSourceSelected: selectedRateSourceOption,
            lor: numbers,
            selectedCompetitionOption:selectedCompetitionOption,
            competitors: selectedCompetitors,
            sipp_code: selectedCarClasses,
            strategyOptions: selectedOptions,
            currencyConversionValue: currencyConversionValue,
            selectedCurrencyOption: selectedCurrencyOption,
            selectedRateCodeOption: selectedRateCodeOption,
            from_date: new Date(instantFromDate).toDateString(),
            created_by: loggedInUserDetail.userEmail,
            updated_by: loggedInUserDetail.userEmail,
            created_date: new Date(),
            updated_date: new Date()
        };
        // console.log("body_payload")
        setLoading(true);
        const res2 = await axios.post(baseURL + "/save_automation_instant_info_pro", body_payload);
        // axios
        //     .post(baseURL + "/save_automation_instant_info_pro", body_payload)
        //     .then((res2) => {
                console.log("RES2:", res2)
                if (res2 !== null && 'data' in res2 && typeof res2.data === 'object') {
                    scrollToBottom();
                    setLoading2(false);
                    setLoading(false);
                    setResponseData(res2.data);
                    setActiveTab(Object.keys(res2.data)[0]);
                    const temp_lowest_values = {}
                    let lor_arr = Object.keys(res2.data);
                    for (let i = 0; i < lor_arr.length; i++) {
                        let arr_inside_lor_arr = Object.keys(res2.data[lor_arr[i]])
                        console.log("res2.data[lor_arr[i]]", res2.data[lor_arr[i]]);
                        console.log("arr_inside_lor_arr", arr_inside_lor_arr);
                        temp_lowest_values[lor_arr[i]] = [res2.data[lor_arr[i]][arr_inside_lor_arr[1]], arr_inside_lor_arr[1]]

                    }
                    setSelectedLowestValue(temp_lowest_values);
                } else {
                    setLoading2(false);
                    setLoading(false);
                    Swal.fire('No Response')
                }
            // });

    };

    let competitors2 = []
    let carClasses2 = []
    if (responseData !== null) {
        const competitors2 = responseData.competitors ? Object.keys(responseData.competitors) : [];
        const carClasses2 = responseData.lowest_total ? Object.keys(responseData.lowest_total) : [];
    }

    useEffect(() => {
        const loggedInUser = sessionStorage.getItem("user_token");
        const foundUser = JSON.parse(loggedInUser);
        setLoggedInUserDetail(foundUser);
        if (foundUser.role === 'SuperAdmin') {
            axios.get(baseURL + "/get_company_details_for_users")
                .then((res) => {
                    setcompanyDetail(res.data);
                });
        }
        else {
            fetch(baseURL + "/get_company_details_for_users_accessWise/" + foundUser.access['InstantAutomationPro_Account Access'] + "*" + foundUser.account_id + "*" + foundUser.account_type.account_type)
                .then((res) => res.json())
                .then((data) => {
                    setcompanyDetail(data);
                });
        }

        fetch(baseURL + "/get_all_rate_codes_names")
            .then((res) => res.json())
            .then((data) => {
                if (Object.keys(data).length > 0) {
                    setRateCodeNames(data);
                }
                else {
                    setRateCodeNames({});
                }
            })
    }, []);

    useEffect(() => {
        if (!hotDateFlag) {
            if (companyDetail && companyName) {
                let obj_company_detail = companyDetail.filter(
                    (value) => value.company_name === companyName
                )[0];
                let temp_company_id = obj_company_detail._id;
                let company_type = obj_company_detail.account_type;

                setcompany_id(temp_company_id);
                setLocationName('')
                setLocationId('')
                setInstantAutomationShow('NO')
                // setLocationEnabledForAutomation('No')
                setListOfRateCodes({})
                setTable([])
                setSelectAllDaysFlag(false)

                const requestOptions = {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                    },
                };

                fetch(baseURL + "/get_company_location/" + temp_company_id, requestOptions)
                    .then((res) => res.json())
                    .then((data) => {
                        let allTemp = data.res;
                        if (loggedInUserDetail.role === 'SuperAdmin') {
                            if (allTemp.length > 0) {
                                setLocationDetail(allTemp);
                            }
                        }
                        else if (company_type === 'Affiliate' && loggedInUserDetail.account_type.account_type === 'Corporate') {

                            if (allTemp.length > 0) {
                                setLocationDetail(allTemp);
                            }
                        }
                        else {
                            axios.post(baseURL + "/get_individual_user_details", { 'user_email': loggedInUserDetail.userEmail })
                                .then((data) => {
                                    if (allTemp.length > 0) {
                                        allTemp = allTemp.filter((val) => val !== undefined && data.data[0].location_allocated[val.location_id]);
                                        setLocationDetail(allTemp);
                                    }
                                }
                                )
                        }
                    });
            }
        }
    }, [companyName,hotDateFlag]);

    useEffect(() => {
        let temp_location_id = ''
        if (locationName && locationDetail) {
            temp_location_id = locationDetail.filter((loc) => (loc.location_name === locationName)).map((val) => val.location_id)
            setLocationId(temp_location_id);
            setSelectedStrategy([]);
            setSelectedSippValues([]);
            setSelectedSippValues2([]);
            setSelectedSippValues3([]);
            setSelectedCompetitor([]);
            setSelectedChange([]);
            setSelectedAmount([]);
            if (!hotDateFlag) 
            {
                setNumbers({ "1": false, "2": false, '3': false, '4': false, '5': false, '6': false, 'Weekly': false, 'Monthly': false});
                setSelectedPickUpTime('');
                setInstantFromDate(new Date());
                setInstantToDate(new Date());
            }
            axios
            .get(baseURL + "/get_automation_enabled_info/" + locationName)
            .then((res) => {
                let automationEnabled = false
                if(res.data.length > 0){
                     automationEnabled = res.data[0]['enabled'] ;
                }
                if (automationEnabled === true){
                    // setLocationEnabledForAutomation("Yes")
                }else{
                    // setLocationEnabledForAutomation("No")
                }
            });

            fetch(baseURL + "/get_existing_fleet_setup/" + temp_location_id)
                .then((res) => res.json())
                .then((data) => {
                    if (data.length > 0) {
                        // console.log(data)
                        let tempArr = [];
                        let tempObj = {}
                        let temp_car_name = [];
                        let temp_arr = []

                        for (let i = 0; i < data.length; i++) {
                            if (data[i] !== undefined && temp_car_name.indexOf(data[i].SIPP_CODE) < 0) {
                                temp_car_name.push(data[i].SIPP_CODE);
                                temp_arr.push(data[i]);
                            }
                        }
                        data = temp_arr;
                        for (let i = 0; i < data.length; i = i + 4) {
                            let tempArr2 = []
                            for (let j = i; j < i + 4; j++) {
                              if (data[j] !== undefined) {
                                tempArr2.push(data[j].SIPP_CODE);
                                tempObj[data[j].SIPP_CODE] = false;
                              }
                            }
                            tempArr.push(tempArr2);
                        }

                        // console.log(tempObj)
                        // console.log(tempArr)
                   
                        const selectedSippCodeNameOptions = data.map((value) => ({
                            value: value.SIPP_CODE,
                            label: value.SIPP_CODE,
                        }));
                        const selectedSippCodeNamesArray = selectedSippCodeNameOptions.map(option => option.value);
                        setAllCarClassData(tempArr);
                        setSelectedCarClass(tempObj);

                        setSelectedCarClasses(tempObj)
                        if(hotDateFlag)
                        {
                            updateState(hotDateCarClass, setSelectedCarClasses);
                        }

                        console.log('competitionSelected',selectedCompetitionOption)
                        console.log('ratesource',selectedRateSourceOption)
                        console.log('selectedCompetitionOption',selectedCompetitionOption.value)
                        // console.log('selectedRateSourceOption',selectedRateSourceOption.value)
                        // if (selectedCompetitionOption.value == 'Car Category' &&  selectedRateSourceOption == 'Expedia'){
                        //     console.log('im at epedia')
                        //     setCarDetails(expediaOptions);
                        //     setCarDetailsInOption1(expediaOptions)
                        //     setCarDetailsInOptionConstant(expediaOptions)
                        //     setInstantAutomationShow('YES')
                        // }else if(selectedCompetitionOption.value == 'Car Category' &&  selectedRateSourceOption == 'Priceline'){
                        //     setCarDetails(pricelineOptions);
                        //     setCarDetailsInOption1(pricelineOptions)
                        //     setCarDetailsInOptionConstant(pricelineOptions)
                        //     setInstantAutomationShow('YES')
                        // }else{
                        //     setCarDetails(selectedSippCodeNamesArray);
                        //     setCarDetailsInOption1(selectedSippCodeNamesArray)
                        //     setCarDetailsInOptionConstant(selectedSippCodeNamesArray)
                        //     setInstantAutomationShow('YES')
                        // }
                        setCarDetails(selectedSippCodeNamesArray);
                        setCarDetailsInOption1(selectedSippCodeNamesArray)
                        setCarDetailsInOptionConstant(selectedSippCodeNamesArray)
                        setInstantAutomationShow('YES')

                        
                    }
                    else {
                        setCarDetails([])
                        setCarDetailsInOption1([])
                        setCarDetailsInOptionConstant([])
                        setInstantAutomationShow('NO')
                    }
                });
            
            fetch(baseURL + "/get_existing_car_classes_standard" )
            .then((res) => res.json())
            .then((data) => {
                if (data.length > 0) {
                    const standardSippCodeOptions = data.map((value) => ({
                        value: value.SIPP_CODE
                    }));
                    const standardSippCodeArray = standardSippCodeOptions.map(option => option.value);

                    setFleetStandardCarDetails(standardSippCodeArray);
                    setCarDetailsInOption2(standardSippCodeArray)
                }
            })
            axios
                .get(baseURL + "/get_all_competitor_setup_info/" + temp_location_id)
                .then(response => { 
                    if(response.data !== null){
                        const newArray = response.data.selected_items.map(subArray => subArray[0]);
                        // console.log("newArray")
                        // console.log(newArray)
                        let data = newArray

                        let tempArr = [];
                        let tempObj = {}
                        let temp_car_name = [];
                        let temp_arr = []
                        for (let i = 0; i < data.length; i++) {
                            if (data[i] !== undefined && temp_car_name.indexOf(data[i].value) < 0) {
                                temp_car_name.push(data[i].value);
                                temp_arr.push(data[i]);
                            }
                        }

                        data = temp_arr;
                        for (let i = 0; i < data.length; i = i + 4) {
                            let tempArr2 = []
                            for (let j = i; j < i + 4; j++) {
                              if (data[j] !== undefined) {
                                tempArr2.push(data[j].value);
                                tempObj[data[j].value] = false;
                              }
                            }
                            tempArr.push(tempArr2);
                        }
                        setAllCompetitors(tempArr);
                        setSelectedCompetitors(tempObj);
                        // console.log("competitors")
                        // console.log(tempArr)
                        // console.log(tempObj)
                        setCompetitorsList(newArray)
                    }
                })
                .catch(error => {
                console.error("Second Axios Request Error:", error);
                });
            axios
            .get(baseURL + "/get_automation_instant_info/" + locationName)
            .then((res) => {
                const formattedStrategy2 = {};
                const formattedCompSipp2 = {};
                const formattedCompSipp3 = {};
                const formattedCompSipp4 = {};
                const formattedCompetitor2 = {};
                const formattedChange2 = {};
                const formattedAmount2 = {};
                // if(res.data !== null){
                //     if(res.data.competitionSelected === undefined || res.data.competitionSelected === null){
                //         setSelectedCompetitionOption("SIPP CODE")
                //     }else{
                //         setSelectedCompetitionOption(res.data.competitionSelected)
                //     }

                //     if(res.data.paritySelected === undefined){
                //         setSelectedRateParity("Static")
                //     }else{
                //         setSelectedRateParity(res.data.paritySelected)
                //     }
    
                //     if(res.data.rateSourceSelected === undefined){
                //         setSelectedRateSourceOption("Expedia")
                //     }else{
                //         setSelectedRateSourceOption(res.data.rateSourceSelected)
                //     }
                    
                //     if(res.data.competitionSelected === "Car Category"){
                //         setCarDetailsInOption1(expediaOptions)
                //         setCarDetailsInOption2(expediaOptions)
                //     }

                //     res.data.strategy.forEach((item, index) => {
                //         formattedStrategy2[index] = { strategyValues: item };
                //     });
                //     res.data.comp_sipp_code.forEach((item, index) => {
                //         formattedCompSipp2[index] = { compSippValues: item };
                //     });
                //     if (res.data.comp_sipp_code2 && res.data.comp_sipp_code2.length > 0) {
                //         res.data.comp_sipp_code2.forEach((item, index) => {
                //           formattedCompSipp3[index] = { compSippValues2: item };
                //         });
                //         const formattedCompSipp2f = Object.keys(formattedCompSipp3).map(key => formattedCompSipp3[key]);
                //         setSelectedSippValues2(formattedCompSipp2f);
                //     }
                //     if (res.data.comp_sipp_code3 && res.data.comp_sipp_code3.length > 0) {
                //         res.data.comp_sipp_code3.forEach((item, index) => {
                //             formattedCompSipp4[index] = { compSippValues3: item };
                //         });
                //         const formattedCompSipp3f = Object.keys(formattedCompSipp4).map(key => formattedCompSipp4[key]);
                //         setSelectedSippValues3(formattedCompSipp3f);
                //     }     
                    
                //     res.data.selected_competitor.forEach((item, index) => {
                //         formattedCompetitor2[index] = { competitorValues: item };
                //     });
                //     res.data.selected_change.forEach((item, index) => {
                //         formattedChange2[index] = { changeValues: item };
                //     });
                //     res.data.amount.forEach((item, index) => {
                //         formattedAmount2[index] = { amountValues: item };
                //     });
                //     const formattedCompSipp = Object.keys(formattedCompSipp2).map(key => formattedCompSipp2[key]);
                //     const formattedStrategy = Object.keys(formattedStrategy2).map(key => formattedStrategy2[key]);
                //     const formattedCompetitor = Object.keys(formattedCompetitor2).map(key => formattedCompetitor2[key]);
                //     const formattedChange = Object.keys(formattedChange2).map(key => formattedChange2[key]);
                //     const formattedAmount = Object.keys(formattedAmount2).map(key => formattedAmount2[key]);
                   
                //     setSelectedStrategy(formattedStrategy);
                //     setSelectedSippValues(formattedCompSipp);
                //     setSelectedCompetitor(formattedCompetitor);
                //     setSelectedChange(formattedChange);
                //     setSelectedAmount(formattedAmount);
                //     if(!hotDateFlag)
                //     {
                //         setInstantFromDate(res.data.from_date);
                //         setSelectedPickUpTime(res.data.pickup_time);
                //         setInstantToDate(res.data.to_date);
                //     }
                //     setNumbers(res.data.lor);
                // }
            });
        }
    }, [locationName])

    // useEffect(() => {
    //     if (selectedCompetitionOption == "Car Category") {
    //         setCarDetailsInOption1(expediaOptions)
    //         setCarDetailsInOption2(expediaOptions)
    //     } else {
    //         setCarDetailsInOption1(carDetailsInOptionConstant)
    //         setCarDetailsInOption2(fleetStandardCarDetails)
    //     }
    // }, [carDetailsInOption1, selectedCompetitionOption]);


    //Option for company name
    const companyNameOptions = companyDetail.map((value) => (
        { value: value.company_name, label: value.company_name }
    ));

    //Option for Location name
    const locationNameOptions = locationDetail.map((value) => (
        { value: value.location_name, label: value.location_name === undefined ? value.location_name : value.location_name + " - " + value.location_id.toString().trim().split("-")[1] }
    ));

    return (

        <div style={{ fontSize: '12px', color: 'black', fontWeight: '600' }}>
            <Modal
                show={tetheringShow}
                onHide={() => setTetheringShow(false)}
                aria-labelledby="example-modal-sizes-title-lg"
                centered
                style={{
                    zIndex: 9999,
                    position: 'fixed',
                    top: '50%',
                    left: '50%',
                    width: '70% !important',
                    transform: 'translate(-50%, -50%)',
                    // overflow: 'hidden',
                }}
            >
                <div style={{ width: '150%', maxWidth: 'unset' }}>
                    <Modal.Header style={{ backgroundColor: 'orange', textAlign: 'center' }} closeButton >
                        <Modal.Title id="example-modal-sizes-title-lg" style={{ textAlign: 'center' }} >
                            Tethering Criteria
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ backgroundColor: 'white' }}>
                        <form style={{ fontSize: '12px' }}>
                            <table className="table" >
                                <thead>
                                    <tr>
                                        <th style={{ backgroundColor: 'orange', textAlign: 'center' }} scope="col">LOR</th>
                                        <th style={{ backgroundColor: 'orange', textAlign: 'center' }} scope="col">Start</th>
                                        <th style={{ backgroundColor: 'orange', textAlign: 'center' }} scope="col">End</th>
                                        <th style={{ backgroundColor: 'orange', textAlign: 'center' }} scope="col">Days</th>
                                    </tr>
                                </thead>
                                <tbody style={{ textAlign: 'center' }}>
                                    {Object.keys(trueKeys).length > 0 && trueKeys.map((key, index) => (
                                        <tr key={index}>
                                            <td>{key}</td>
                                            <td>
                                                <DatePicker
                                                    size="medium"
                                                    format='MM-DD-YYYY'
                                                    placeholder='mm-dd-yyyy'
                                                    value={moment(instantToDate.valueOf())}
                                                    onChange={(value) => { setInstantToDate(value._d) }}
                                                    style={{ width: '75%' }}
                                                    disabled
                                                />
                                            </td>
                                            <td style={{}}>
                                                <DatePicker
                                                    size="medium"
                                                    format='MM-DD-YYYY'
                                                    placeholder='mm-dd-yyyy'
                                                    style={{ width: '75%' }}
                                                    value={rowData[key]?.defaultDate ? moment(rowData[key].defaultDate) : null}
                                                    onChange={(date) => handleDatePickerChange(key, date)}
                                                    disabledDate={(current) => {
                                                        if (instantFromDate) {
                                                            const startDate = moment(instantFromDate);
                                                            const endDate = moment(instantFromDate).add(400, 'days'); // Allow selection within 7 days of start date
                                                            return current && (current.valueOf() < startDate.valueOf() || current.valueOf() > endDate.valueOf());
                                                        } else {
                                                            return current && current.valueOf() < (new Date().valueOf() - (1 * 24 * 60 * 60));
                                                        }
                                                    }}
                                                />
                                            </td>
                                            <td style={{ marginLeft: '30px' }}>
                                                <tr>
                                                    <th><input type='checkbox' className='form-check-input' checked={defaultCheck[key]} onChange={(e) => changeAllDaysCheckBox(key, e.target.checked)} /> </th>
                                                    <th>Select All</th>
                                                </tr>
                                                {daysNameTable.map((val) => (
                                                    <tr>
                                                        {val.map((v) => (
                                                            v == null ? '' :
                                                                <>
                                                                    <td><input type='checkbox' className='form-check-input' checked={rowData[key]?.[v] || false} onChange={(e) => changeDaysCheckBox(key, v, e.target.checked)} /></td>
                                                                    <td>{v}</td>
                                                                    <td>&nbsp;&nbsp;</td>
                                                                </>
                                                        ))}
                                                    </tr>
                                                ))}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            { accessType === 'View' ? "" :
                            <>
                                <div className="row" style={{ marginBottom: '10px' }}>
                                    <div className="col-md-4">
                                    </div>
                                    <div className="col-md-2">
                                        <button style={{ width: '143px', fontSize: '12px' }} type="submit" className="btn btn-success" onClick={handleTetheringSubmit} >SUBMIT </button>
                                    </div>
                                </div>
                            </>
                            }

                        </form>
                    </Modal.Body>
                </div>
            </Modal>
            <Modal
                size="medium"
                show={currencyShow}
                onHide={() => setCurrencyShow(false)}
                aria-labelledby="example-modal-sizes-title-lg"
                centered
                style={{ display: 'flex', justifyContent: 'center' }}
            >
                <Modal.Header style={{ backgroundColor: 'orange', textAlign: 'center' }} closeButton >
                    <Modal.Title id="example-modal-sizes-title-lg" style={{ textAlign: 'center' }} >
                        Currency Conversion Value : USD to {selectedCurrencyOption['value']}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ backgroundColor: 'white' }}>
                    <form style={{ fontSize: '12px' }}>
                        <div className="row pt-2 mx-2">
                            <div className="col-md-3 mb-6">
                            </div>
                            <div className="col-md-9 mb-6">
                                <input
                                    type="number"
                                    className='form-control'
                                    value={currencyConversionValue}
                                    style={{ width: '50%', textAlign: 'center', fontSize: '20px' }}
                                    onChange={(e) => setCurrencyConversionValue(e.target.value)}
                                />
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
            <form style={{ fontSize: '12px', color: 'black', fontWeight: '600', backgroundColor:'white' }}>
                {/* <div className="row pt-2 mx-2" >
                    <div className="col-md-2 mb-3">
                        <label htmlFor="company_name" className="form-label" style={{ marginTop: 5 }}>
                            Account Name
                        </label>
                    </div>
                    <div className="col-md-3 mb-3">
                        <Select
                            value={{ value: companyName, label: (companyName ? companyName : "Select Account Name") }}
                            inputId="company_name"
                            name="company_name"
                            options={companyNameOptions}
                            onChange={handleInputChangeCompanyName}
                        />
                    </div>
                </div> */}
                {/* <div className="card p-3"> */}
                <div style={{ padding: '10px', borderRadius: '8px', fontWeight: '600', marginBottom: '10px', marginTop: '10px',width: 'auto' }}>
                <style>
                    {`
                        .custom-margin-override {
                            margin-bottom: 0 !important;
                        }
                    `}
                </style>
                    <div className="row mx-2" style={{backgroundColor:'white',}}>
                        <div className="col-md-6 mb-3 custom-margin-override">
                            <div className="card p-3 d-flex align-items-center" style={{ height: '100%', backgroundColor:'#54ADD1',  border: '1px solid #000', borderRadius: '8px'}}>
                            <div className="w-100 d-flex align-items-center" style={{ height: '100%' }}>
                                <div style={{ flex: '1', textAlign: 'center' }}>
                                <label htmlFor="company_name" className="form-label mb-0" style={{fontSize:'15px', marginTop: 5,padding: '5px', borderRadius: '4px', fontWeight:'bold' }}>
                                    Account Name
                                </label>
                                </div>
                                <div style={{
                                width: '1px',
                                backgroundColor: '#000', // Change color as needed
                                height: '100%',
                                alignSelf: 'stretch', // Ensures the vertical line spans the height of its parent
                                margin: '0 15px'
                                }}></div>
                                <div style={{ flex: '2' }}>
                                <Select
                                    value={{ value: companyName, label: companyName ? companyName : "Select Account Name" }}
                                    inputId="company_name"
                                    name="company_name"
                                    options={companyNameOptions}
                                    onChange={handleInputChangeCompanyName}
                                    styles={{
                                    container: (base) => ({ ...base, width: '100%' }),
                                    control: (base) => ({ ...base, height: '100%' }),
                                    }}
                                />
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="col-md-6 mb-3 custom-margin-override">
                            <div className="card p-3 d-flex align-items-center" style={{ height: '100%', backgroundColor:'#54ADD1',  border: '1px solid #000', borderRadius: '8px'}}>
                            <div className="w-100 d-flex align-items-center" style={{ height: '100%' }}>
                                <div style={{ flex: '1', textAlign: 'center' }}>
                                <label htmlFor="company_name" className="form-label mb-0" style={{fontSize:'15px', marginTop: 5,padding: '5px', borderRadius: '4px', fontWeight:'bold' }}>
                                    Location Name
                                </label>
                                {/* <label htmlFor="company_name" className="form-label mb-0" style={{ marginTop: 5,padding: '5px', borderRadius: '4px', fontWeight:'bold' }}>
                                    Location Name
                                </label> */}
                                </div>
                                <div style={{
                                width: '1px',
                                backgroundColor: '#000', // Change color as needed
                                height: '100%',
                                alignSelf: 'stretch', // Ensures the vertical line spans the height of its parent
                                margin: '0 15px'
                                }}></div>
                                <div style={{ flex: '2' }}>
                                <Select
                                    value={{ value: locationName, label: locationName ? locationName : "Select Location Name" }}
                                    inputId="locationName"
                                    name="locationName"
                                    options={locationNameOptions}
                                    onChange={handleInputChangeLocationName}
                                    styles={{
                                        container: (base) => ({ ...base, width: '100%' }),
                                        control: (base) => ({ ...base, height: '100%' }),
                                    }}
                                />
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container custom-margin-override" style={{ display: 'flex', justifyContent: 'space-between', padding: '0px' }}>
                    <div style={{ width: '45%', marginLeft:'4%' }}>
                        <div className="row mx-6" style={{ padding: '10px', display: 'table', borderCollapse: 'collapse', borderRadius: '8px', fontWeight: 'bold', backgroundColor: '#54ADD1', width:'102%' }}>
                            <div style={{ display: 'table-row', fontWeight: 'bold', textAlign: 'center' }}>
                                <div style={{ display: 'table-cell', border: '1px solid #000', padding: '10px', width: '40%', fontSize:'15px', fontWeight:'bold' }}>Run Date</div>
                                <div style={{ display: 'table-cell', border: '1px solid #000', padding: '10px', width: '40%', fontSize:'15px', fontWeight:'bold' }}>Time</div>
                            </div>

                            <div style={{ display: 'table-row', textAlign: 'center'}}>
                                <div style={{ display: 'table-cell', border: '1px solid #000', padding: '10px' }}>
                                    <DatePicker
                                            size="large"
                                            format='MM-DD-YYYY'
                                            placeholder='mm-dd-yyyy'
                                            onChange={(value) => {
                                                setInstantFromDate(value._d);
                                                setInstantToDate(value._d);
                                            }}
                                            disabledDate={(current) => {
                                                if (instantToDate && instantFromDate?.valueOf() !== instantToDate?.valueOf()) {
                                                    return current && (current.valueOf() > (instantToDate?.valueOf() + (1 * 24 * 60 * 60))) || (current.valueOf() < (new Date().valueOf() - (1000 * 24 * 60 * 60)))
                                                }
                                                else {
                                                    return current && (current.valueOf() < (new Date().valueOf() - (1 * 24 * 60 * 60)))
                                                }
                                            }}
                                            value={moment(instantFromDate.valueOf())}
                                            style={{ width: '100%' }}
                                        />
                                </div>
                                <div style={{ display: 'table-cell', border: '1px solid #000', padding: '10px' }}>
                                    <select style={{ textAlign: "center", width: '100%' }}
                                        value={selectedPickUpTime}
                                        onChange={(e) => handlePickUpTime(e.target.value)}
                                    >
                                        <option value="">Select Time</option>
                                        {hours.map((val) => (
                                            <option value={val.name} key={val.name}>
                                                {val.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 custom-margin-override" style={{marginRight : '4%', width: '45%'}}>
                        <div className="card p-3 d-flex align-items-center" style={{ height: '80%', backgroundColor:'#54ADD1',border: '1px solid #000', borderRadius: '8px' }}>
                            <div className="w-100 d-flex align-items-center" style={{ height: '80%' }}>
                                <div style={{ flex: '1', textAlign: 'center' }}>
                                    <label htmlFor="company_name" className="form-label mb-0" style={{fontSize:'15px', marginTop: 5,padding: '5px', borderRadius: '4px', fontWeight:'bold' }}>
                                        Rate Source
                                    </label>
                                    {/* <label htmlFor="applyRatesToDays" className="form-label mb-0">
                                        RateSource
                                    </label> */}
                                    </div>
                                    <div style={{
                                    width: '1px',
                                    backgroundColor: '#000', 
                                    height: '100%',
                                    alignSelf: 'stretch', // Ensures the vertical line spans the height of its parent
                                    margin: '0 15px'
                                    }}></div>
                                    <div style={{ flex: '2' }}>
                                    <Select
                                        value={rateSourceOptions.find(option => option.value === selectedRateSourceOption)}
                                        onChange={(option) => handleRateSourceOption(option.value)}
                                        options={rateSourceOptions.map((val) => ({ value: val.value, label: val.value }))}                                   
                                        placeholder="Select Rate Source"    
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>    
                <div style={{ padding: '10px', borderRadius: '8px', fontWeight: 'bold', marginBottom: '0px', marginTop: '5px',width: 'auto' }}>
                <style>
                    {`
                        .custom-margin-override {
                            margin-bottom: 0 !important;
                        }
                    `}
                </style>
                    <div className="row mx-2">
                        <div className="col-md-6  custom-margin-override">
                            <div className="card p-3 d-flex align-items-center" style={{ height: '80%', backgroundColor:'#54ADD1',border: '1px solid #000', borderRadius: '8px' }}>
                                <div className="w-100 d-flex align-items-center" style={{ height: '80%' }}>
                                    <div style={{ flex: '1', textAlign: 'center' }}>
                                        <label htmlFor="company_name" className="form-label mb-0" style={{fontSize:'15px', marginTop: 5,padding: '5px', borderRadius: '4px', fontWeight:'bold' }}>
                                            Competition By
                                        </label>
                                        {/* <label htmlFor="applyRatesToDays" className="form-label mb-0">
                                            Competition By
                                        </label> */}
                                    </div>
                                    <div style={{
                                        width: '1px',
                                        backgroundColor: '#000',
                                        height: '100%',
                                        alignSelf: 'stretch',
                                        margin: '0 15px'
                                    }}></div>
                                    <div style={{ flex: '2' }}>
                                        <Select
                                            value={competitionOptions.find(option => option.value === selectedCompetitionOption)}
                                            onChange={(option) => handleCompetitionOption(option.value)}
                                            // value={selectedCompetitionOption}
                                            // onChange={handleCompetitionOption}
                                            options={competitionOptions.map((val) => ({ value: val.value, label: val.value }))}        
                                            // options={selectedRateSourceOption === 'Expedia' ? competitionOptions : competitionOptions.filter(option => option.label === 'SIPP CODE')}
                                            placeholder="Select Competition"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mb-1 custom-margin-override">
                            <div className="card p-3 d-flex align-items-center" style={{ height: '80%', backgroundColor:'#54ADD1',border: '1px solid #000', borderRadius: '8px' }}>
                                <div className="w-100 d-flex align-items-center" style={{ height: '80%' }}>
                                    <div style={{ flex: '1', textAlign: 'center' }}>
                                        <label htmlFor="company_name" className="form-label mb-0" style={{fontSize:'15px', marginTop: 5,padding: '5px', borderRadius: '4px', fontWeight:'bold' }}>
                                            Rate Code 
                                        </label>
                                    </div>
                                    <div style={{
                                        width: '1px',
                                        backgroundColor: '#000',
                                        height: '100%',
                                        alignSelf: 'stretch',
                                        margin: '0 15px'
                                    }}></div>
                                    <div style={{ flex: '2' }}>
                                        <Select
                                            value={selectedRateCodeOption}
                                            onChange={handleRateCodeOption}
                                            options={rateCodeOptions}
                                            placeholder="Select Rate Code"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
                {/* <div className="container" style={{ display: 'flex', justifyContent: 'space-between', padding: '10px' }}>
                    <div style={{ width: '52%' }}>
                        <div className="row mx-4" style={{ padding: '10px', border: '1px solid #000',fontSize:'18px', borderRadius: '8px', fontWeight: 'bold', marginBottom: '10px', marginTop: '0px', backgroundColor: 'black',color: 'white' , width:'40%' }}>
                            LOCATION / TIMING
                        </div>
                        <div className="row mx-6" style={{ padding: '10px', marginLeft:'-4%', display: 'table', borderCollapse: 'collapse', borderRadius: '8px', fontWeight: 'bold', backgroundColor: '#54ADD1', width:'102%' }}>
                            <div style={{ display: 'table-row', fontWeight: 'bold', textAlign: 'center' }}>
                                <div style={{ display: 'table-cell', border: '1px solid #000', padding: '10px', width: '20%' }}></div>
                                <div style={{ display: 'table-cell', border: '1px solid #000', padding: '10px', width: '40%' }}>LOCATION</div>
                                <div style={{ display: 'table-cell', border: '1px solid #000', padding: '10px', width: '40%' }}>TIME</div>
                            </div>

                            <div style={{ display: 'table-row', textAlign: 'center'}}>
                                <div style={{ display: 'table-cell', border: '1px solid #000', padding: '10px' }}>PICK UP</div>
                                <div style={{ display: 'table-cell', border: '1px solid #000', padding: '10px' }}>
                                    <Select
                                        value={{ value: locationName, label: locationName ? locationName : "Select Location Name" }}
                                        inputId="locationName"
                                        name="locationName"
                                        options={locationNameOptions}
                                        onChange={handleInputChangeLocationName}
                                        styles={{
                                            container: (base) => ({ ...base, width: '100%' }),
                                            control: (base) => ({ ...base, height: '100%' }),
                                        }}
                                    />
                                </div>
                                <div style={{ display: 'table-cell', border: '1px solid #000', padding: '10px' }}>
                                    <select style={{ textAlign: "center", width: '100%' }}
                                        value={selectedPickUpTime}
                                        onChange={(e) => handlePickUpTime(e.target.value)}
                                    >
                                        <option value="">Select Time</option>
                                        {hours.map((val) => (
                                            <option value={val.name} key={val.name}>
                                                {val.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div style={{ width: '52%' }}>
                        <div className="row mx-4" style={{ padding: '10px', border: '1px solid #000', borderRadius: '8px', fontWeight: 'bold', fontSize:'18px', marginBottom: '10px', marginTop: '0px', backgroundColor: 'black',color:'white', width:'25%' }}>
                            RUN DATE
                        </div>
                        <div className="row mx-6" style={{ padding: '10px', marginLeft:'3%', display: 'table', borderCollapse: 'collapse', borderRadius: '8px', fontWeight: 'bold', backgroundColor: '#54ADD1', height:'70%', width:'100%'}}>
                            <div style={{ padding: '10px', display: 'table-row', textAlign: 'center', borderRadius: '8px' }}>
                                <div style={{ display: 'table-cell', border: '1px solid #000', padding: '10px' }}>FROM DATE</div>
                                <div style={{ display: 'table-cell', border: '1px solid #000', padding: '10px' }}>
                                    <DatePicker
                                        size="large"
                                        format='MM-DD-YYYY'
                                        placeholder='mm-dd-yyyy'
                                        onChange={(value) => {
                                            setInstantFromDate(value._d);
                                            setInstantToDate(value._d);
                                        }}
                                        disabledDate={(current) => {
                                            if (instantToDate && instantFromDate?.valueOf() !== instantToDate?.valueOf()) {
                                                return current && (current.valueOf() > (instantToDate?.valueOf() + (1 * 24 * 60 * 60))) || (current.valueOf() < (new Date().valueOf() - (1000 * 24 * 60 * 60)))
                                            }
                                            else {
                                                return current && (current.valueOf() < (new Date().valueOf() - (1 * 24 * 60 * 60)))
                                            }
                                        }}
                                        value={moment(instantFromDate.valueOf())}
                                        style={{ width: '100%' }}
                                    />
                                </div>
                            </div>

                            
                        </div>
                    </div>
                </div> */}

                
                
                <div style={{ padding: '10px', borderRadius: '8px', fontWeight: 'bold', marginBottom: '5px', marginTop: '5px',width: 'auto' }}>
                    <style>
                        {`
                            .custom-margin-override {
                                margin-bottom: 0 !important;
                            }
                        `}
                    </style>
                    <div className="row mx-2">
                        
                        <div className="col-md-6 mb-1 custom-margin-override">
                            <div className="card p-3 d-flex align-items-center" style={{ height: '80%', backgroundColor:'#54ADD1',border: '1px solid #000', borderRadius: '8px' }}>
                                <div className="w-100 d-flex align-items-center" style={{ height: '80%' }}>
                                    <div style={{ flex: '1', textAlign: 'center' }}>
                                        <label htmlFor="company_name" className="form-label mb-0" style={{fontSize:'15px', marginTop: 5,padding: '5px', borderRadius: '4px', fontWeight:'bold' }}>
                                            Currency  
                                        </label>
                                    </div>
                                    <div style={{
                                        width: '1px',
                                        backgroundColor: '#000',
                                        height: '100%',
                                        alignSelf: 'stretch',
                                        margin: '0 15px'
                                    }}></div>
                                    <div style={{ flex: '2' }}>
                                    <Select
                                        value={selectedCurrencyOption}
                                        onChange={handleCurrencyOption}
                                        options={currencyOption}
                                        placeholder="Select Currency"
                                    />
                                    {/* <Select
                                        value={selectedCurrencyOption}
                                        onChange={handleCurrencyOption}
                                        options={currencyOptions.map(option => ({ value: option.value, label: option.label }))}
                                        placeholder="Select Currency"
                                    /> */}
                                        {/* <Select
                                            value={{ value: selectedCurrencyOption.id, label: selectedCurrencyOption.label }}
                                            // value={selectedCurrencyOption}
                                            onChange={handleCurrencyOption}
                                            options={currencyOption.map(option => ({ value: option.id, label: option.label }))}
                                            // options={currencyOption}
                                            placeholder="Select Currency"
                                        /> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="col-md-12 mb-3" style={{ marginLeft: '0%', backgroundColor:'white !important' }}> */}
                {/* <div className="custom-background col-md-12 mb-3" style={{ marginLeft: '0%', backgroundColor: 'white', background: 'white !important' }}> */}
                {/* <div className="col-md-12 mb-3" style={{ marginLeft: '0%', backgroundColor: 'white !important' }}> */}
                {/* <div style={{ padding: '10px',paddingLeft: '5px',paddingRight: '5px',  border: '1px solid #000', borderRadius: '8px', fontWeight: 'bold', marginBottom: '10px', marginTop: '10px',width: '90%', boxSizing: 'border-box', backgroundColor:"#54ADD1"}}> */}
                <div style={{
                    padding: '10px',
                    paddingLeft: '5%',             // Add 5% padding to the left
                    paddingRight: '5%',            // Add 5% padding to the right
                    border: '1px solid #000',
                    borderRadius: '8px',
                    fontWeight: 'bold',
                    marginBottom: '10px',
                    marginTop: '10px',
                    width: '95%',                  // Set width to 90% of the parent container
                    marginLeft: 'auto',            // Center the div horizontally
                    marginRight: 'auto',           // Center the div horizontally
                    backgroundColor: "#54ADD1",
                    boxSizing: 'border-box'        // Ensure padding and border are included in the width
                    }}>
                    <table className="ggg">
                        <tbody >
                            <tr>
                                <td>
                                    <span style={{ fontSize: '15px' }}>Car Classes</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                        <button type='button' className={`btn ${areAllCarClassesSelected() ? 'btn-primary custom-btn-primary' : 'btn-secondary custom-btn-secondary'}`}
                                            onClick={handleSelectAll2}
                                            style={{ 
                                                marginRight: '30px',
                                                transition: 'all 0.2s ease',
                                                transform: areAllCarClassesSelected() ? 'scale(0.85)' : 'scale(1)',
                                                boxShadow: areAllCarClassesSelected() ? '0 5px 15px rgba(0, 0, 0, 0.3)' : '0 10px 20px rgba(255,0,0,0.3)',
                                                height: '40px',
                                            }}
                                        >
                                            {areAllCarClassesSelected() ? 'Deselect All' : 'Select All'}
                                        </button>
                                        {Object.keys(selectedCarClasses).map((carClass, index) => (
                                            <button 
                                                type='button' 
                                                key={index} 
                                                className={`btn ${selectedCarClasses[carClass] ? 'btn-primary custom-btn-primary' : 'btn-secondary custom-btn-secondary'}`}
                                                onClick={() => handleToggleCarClass(carClass)}
                                                style={{ 
                                                    marginRight: '5px', 
                                                    marginBottom: '5px', 
                                                    transition: 'all 0.2s ease',
                                                    transform: selectedCarClasses[carClass] ? 'scale(0.85)' : 'scale(1)',
                                                    boxShadow: selectedCarClasses[carClass] ? '0 5px 15px rgba(0, 0, 0, 0.3)' : '0 10px 20px rgba(0, 0, 0, 0.2)',
                                                    height: '40px',
                                                }}
                                            >
                                                {carClass}
                                            </button>
                                        ))}
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                {/* <div style={{ padding: '10px', border: '1px solid #000', borderRadius: '8px', fontWeight: 'bold', marginBottom: '10px', marginTop: '10px',width: 'auto',  backgroundColor:"#54ADD1" }}> */}
                <div style={{
                    padding: '10px',
                    paddingLeft: '5%',             // Add 5% padding to the left
                    paddingRight: '5%',            // Add 5% padding to the right
                    border: '1px solid #000',
                    borderRadius: '8px',
                    fontWeight: 'bold',
                    marginBottom: '10px',
                    marginTop: '10px',
                    width: '95%',                  // Set width to 90% of the parent container
                    marginLeft: 'auto',            // Center the div horizontally
                    marginRight: 'auto',           // Center the div horizontally
                    backgroundColor: "#54ADD1",
                    boxSizing: 'border-box'        // Ensure padding and border are included in the width
                    }}>
                    <table className="ggg">
                        <tbody>
                            <tr>
                                <td>
                                <span style={{ fontSize: '15px' }}>Competitors</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                    <button type='button' className={`btn ${areAllCompetitorsSelected() ? 'btn-primary custom-btn-primary' : 'btn-secondary custom-btn-secondary'}`}
                                        onClick={handleSelectAllCompetitors2}
                                        style={{ 
                                            marginRight: '30px',
                                            transition: 'all 0.2s ease',
                                            transform: areAllCompetitorsSelected() ? 'scale(0.85)' : 'scale(1)',
                                            boxShadow: areAllCompetitorsSelected() ? '0 5px 15px rgba(0, 0, 0, 0.3)' : '0 10px 20px rgba(0, 0, 0, 0.2)',
                                            height: '40px',
                                        }}
                                    >
                                        {areAllCompetitorsSelected() ? 'Deselect All' : 'Select All'}
                                    </button>
                                        {Object.keys(selectedCompetitors).map((competitor, index) => (
                                            <button 
                                                type='button' 
                                                key={index} 
                                                className={`btn ${selectedCompetitors[competitor] ? 'btn-primary custom-btn-primary' : 'btn-secondary custom-btn-secondary'}`}
                                                onClick={() => handleToggleCompetitors(competitor)}
                                                style={{ 
                                                    marginRight: '5px', 
                                                    marginBottom: '5px', 
                                                    transition: 'all 0.2s ease',
                                                    transform: selectedCompetitors[competitor] ? 'scale(0.85)' : 'scale(1)',
                                                    boxShadow: selectedCompetitors[competitor] ? '0 5px 15px rgba(0, 0, 0, 0.3)' : '0 10px 20px rgba(0, 0, 0, 0.2)',
                                                    height: '40px',
                                                }}
                                            >
                                                {competitor}
                                            </button>
                                        ))}
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                {/* <div style={{ padding: '10px', border: '1px solid #000', borderRadius: '8px', fontWeight: 'bold', marginBottom: '10px', marginTop: '10px',width: 'auto',  backgroundColor:"#54ADD1" }}> */}
                <div style={{
                    padding: '10px',
                    paddingLeft: '5%',             // Add 5% padding to the left
                    paddingRight: '5%',            // Add 5% padding to the right
                    border: '1px solid #000',
                    borderRadius: '8px',
                    fontWeight: 'bold',
                    marginBottom: '10px',
                    marginTop: '10px',
                    width: '95%',                  // Set width to 90% of the parent container
                    marginLeft: 'auto',            // Center the div horizontally
                    marginRight: 'auto',           // Center the div horizontally
                    backgroundColor: "#54ADD1",
                    boxSizing: 'border-box'        // Ensure padding and border are included in the width
                    }}>
                    <table className="gg">
                        <tbody>
                            <tr>
                                <td>
                                    <span style={{ fontSize: '15px' }}>Lor's</span>
                                </td>
                            </tr>
                            {/* <tr>
                                <td>
                                    
                                </td>
                            </tr> */}
                            <tr>
                                {/* <td>
                                
                                </td> */}
                                <td>
                                
                                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                    <button 
                                        type='button' 
                                        className={`btn ${Object.values(numbers).every((value) => value) ?'btn-primary custom-btn-primary' : 'btn-secondary custom-btn-secondary'}`}
                                        onClick={handleSelectAll}
                                        style={{ 
                                            marginRight: '30px',
                                            transition: 'all 0.2s ease',
                                            transform: Object.values(numbers).every((value) => value) ? 'scale(0.85)' : 'scale(1)',
                                            boxShadow: Object.values(numbers).every((value) => value) ? '0 5px 15px rgba(0, 0, 0, 0.3)' : '0 10px 20px rgba(0, 0, 0, 0.2)',
                                            height: '40px',
                                        }}
                                    >
                                        {Object.values(numbers).every((value) => value) ? 'Deselect All' : 'Select All'}
                                    </button>
                                        {Object.entries(numbers).map(([number, selected]) => (
                                            <button 
                                                type='button' 
                                                key={number} 
                                                className={`btn ${selected ? 'btn-primary custom-btn-primary' : 'btn-secondary custom-btn-secondary'}`}
                                                onClick={() => handleNumberSelection(number)}
                                                style={{ 
                                                    marginRight: '5px', 
                                                    marginBottom: '5px', 
                                                    transition: 'all 0.2s ease',
                                                    transform: selected ? 'scale(0.85)' : 'scale(1)',
                                                    boxShadow: selected ? '0 5px 15px rgba(0, 0, 0, 0.3)' : '0 10px 20px rgba(0, 0, 0, 0.2)',
                                                    height: '40px',
                                                }}
                                            >
                                                {number}
                                            </button>
                                        ))}
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                {/* <div style={{ padding: '10px', border: '1px solid #000', borderRadius: '8px', fontWeight: 'bold', marginBottom: '10px', marginTop: '10px',width: 'auto',  backgroundColor:"#54ADD1" }}> */}
                <div style={{
                    padding: '10px',
                    paddingLeft: '5%',             // Add 5% padding to the left
                    paddingRight: '5%',            // Add 5% padding to the right
                    border: '1px solid #000',
                    borderRadius: '8px',
                    fontWeight: 'bold',
                    marginBottom: '10px',
                    marginTop: '10px',
                    width: '95%',                  // Set width to 90% of the parent container
                    marginLeft: 'auto',            // Center the div horizontally
                    marginRight: 'auto',           // Center the div horizontally
                    backgroundColor: "#54ADD1",
                    boxSizing: 'border-box'        // Ensure padding and border are included in the width
                    }}>
                    <table className="gg">
                        <tbody>
                            <tr>
                                <td>
                                    <span style={{ fontSize: '15px' }}>Strategy</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                        {[
                                            { label: 'Lowest', value: 'Lowest' },
                                            { label: '2nd Lowest', value: '2nd Lowest' },
                                            { label: '3rd Lowest', value: '3rd Lowest' },
                                            { label: 'Lowest Off-Airport', value: 'Lowest Off-Airport' },
                                            { label: '2nd Lowest Off-Airport', value: '2nd Lowest Off-Airport' },
                                            { label: '3rd Lowest Off-Airport', value: '3rd Lowest Off-Airport' },
                                            { label: 'Lowest In-Terminal', value: 'Lowest In-Terminal' },
                                            { label: '2nd Lowest In-Terminal', value: '2nd Lowest In-Terminal' },
                                            { label: '3rd Lowest In-Terminal', value: '3rd Lowest In-Terminal' },
                                        ].map((option, index) => (
                                            <button
                                                type='button'
                                                key={index}
                                                className={`btn ${selectedOptions.includes(option.value) ? 'btn-primary custom-btn-primary' : 'btn-secondary custom-btn-secondary'}`}
                                                onClick={() => handleOptionChange(option.value)}
                                                style={{
                                                    marginRight: '5px',
                                                    marginBottom: '5px',
                                                    transition: 'all 0.2s ease',
                                                    transform: selectedOptions.includes(option.value) ? 'scale(0.85)' : 'scale(1)',
                                                    boxShadow: selectedOptions.includes(option.value) ? '0 5px 15px rgba(0, 0, 0, 0.3)' : '0 10px 20px rgba(0, 0, 0, 0.2)'
                                                }}
                                            >
                                                {option.label}
                                            </button>
                                        ))}
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                {loading && <CustomLoader />}
                { accessType === 'View' ? "" :
                     <>
                    <div className="row" style={{ marginBottom: '10px' }}>
                        <div className="col-md-5">
                        </div>
                        <div className="col-md-2">
                            <button style={{ width: '143px', fontSize: '12px' }} type="submit" disabled={buttonDisabled} className="btn btn-success" onClick={handleAutomationSubmit} >Generate Grid </button>
                        </div>
                    </div>
                    </>
                }
            </form>
            {Object.keys(responseData).length !== 0 ? (
                <div style={{ display: 'flex', flexDirection: 'column', paddingTop: '20px', paddingBottom: '20px', float: "left" }}>
                    <div style={{ border: '2px solid grey', borderRadius: '0 0 7px 7px', width: 'auto', padding: '15px' }}>
                        <h2><b>{headerData} - LOR {activeTab}</b></h2>
                        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                            <thead style={{ position: "sticky", top: '40px', zIndex: 2 }}>
                                <tr>
                                    <th style={{ position: "sticky", left: '181px', zIndex: 1, padding: '8px', border: '2px solid grey', backgroundColor: 'black', color: 'white', textAlign: 'center', verticalAlign: 'middle', marginLeft: '30px' }}>
                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                            <img src={expedia} style={{ marginLeft: '10px', width: '100px', height: '40px' }} alt="" className='logo' />
                                            <span style={{ color: 'white', marginTop: '5px' }}>SIPP CODES ➔</span>
                                        </div>
                                        <div style={{ textAlign: 'center', marginTop: '5px' }}>
                                            <span style={{ color: 'white', marginRight: '5px' }}>Competitors</span>
                                            <span>&#9660;</span>
                                        </div>
                                    </th>
                                    {Object.entries(responseData[activeTab]?.competitors_result || {}).slice(0, 1).map(([_, carClassData]) => (
                                        Object.keys(carClassData).map((imageName) => (
                                            <th key={imageName} style={{ padding: '8px', border: '2px solid grey', backgroundColor: 'black', color: 'white', textAlign: 'center', verticalAlign: 'middle' }}>
                                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                    <img src={baseURL + "/static/uploads/" + imageName.split('@')[1].trim()} alt={`Image for ${imageName}`} style={{ width: '80px', height: 'auto' }} />
                                                    <span style={{ marginTop: '5px' }}>{imageName.split('@')[0].trim()}</span>
                                                </div>
                                            </th>
                                        ))
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {Object.entries(responseData[activeTab]?.competitors_result || {}).map(([competitor, prices], indx) => (
                                    // indx == 0 ?
                                    //     <tr key={competitor}>
                                    //         <td style={{ position: "sticky", top: '154px', left: '181px', zIndex: 2, border: '2px solid grey', backgroundColor: 'black', color: 'white', textAlign: 'center', verticalAlign: 'middle' }}>
                                    //             <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    //                 <img src={baseURL + "/static/uploads/" + competitor.split('@')[1].trim()} alt={`Image for ${competitor}`} style={{ width: '80px', height: '50px', }} />
                                    //             </div>
                                    //         </td>
                                    //         {Object.values(prices).map((price, i) => (
                                    //             <td key={i} style={{
                                    //                 position: "sticky", top: '154px', zIndex: 1,
                                    //                 padding: '8px',
                                    //                 border: '2px solid grey',
                                    //                 backgroundColor: price.toString().includes('!prasanth') ? 'skyblue' : 'black', // Convert price to string and then check for the condition
                                    //                 color: price.toString().includes('!prasanth') ? 'black' : 'white', // Convert price to string and then check for the condition
                                    //                 textAlign: 'center',
                                    //                 verticalAlign: 'middle',
                                    //                 fontSize: '14px'
                                    //             }}>
                                    //                 {price.toString().includes('!prasanth') ? price.toString().replace('!prasanth', '') : price} {/* Display price without the !prasanth part if present */}
                                    //             </td>
                                    //         ))}
                                    //     </tr>
                                    //     :
                                    <tr key={competitor}>
                                        <td style={{ position: "sticky", left: '181px', zIndex: 1, border: '2px solid grey', backgroundColor: 'black', color: 'white', textAlign: 'center', verticalAlign: 'middle' }}>
                                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                <img src={baseURL + "/static/uploads/" + competitor.split('@')[1].trim()} alt={`Image for ${competitor}`} style={{ width: '80px', height: '50px', }} />
                                            </div>
                                        </td>
                                        {Object.values(prices).map((price, i) => (
                                            <td key={i} style={{
                                                zIndex: 1,
                                                padding: '8px',
                                                border: '2px solid grey',
                                                color: price.toString().includes('lowest1') || price.toString().includes('lowest2') ? 'black' : 'white',
                                                backgroundColor: price.toString().includes('lowest1') ? '#50C878' : price.toString().includes('lowest2') ? '#4169E1' : price.toString().includes('lowest3') ? 'black' : 'black',
                                                textAlign: 'center',
                                                verticalAlign: 'middle',
                                                fontSize: '14px'
                                            }}>
                                                {price.toString().includes('!lowest1') || price.toString().includes('!lowest2') || price.toString().includes('!lowest3') ? price.toString().replace(/!lowest[123]/g, '') : price}
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                                {responseData[activeTab]?.lowest_among_competitor && (
                                    <tr style={{ width: '90px', height: '40px' }}>
                                        <td style={{ position: "sticky", left: '181px', zIndex: 1, padding: '8px', border: '2px solid grey', backgroundColor: 'orange', color: 'black', textAlign: 'center', verticalAlign: 'middle', fontSize: '14px' }}><b>Lowest</b></td>
                                        {Object.entries(responseData[activeTab]?.lowest_among_competitor || {}).map(([carClass, price], i) => (
                                            <td key={i} style={{ padding: '8px', border: '2px solid grey', backgroundColor: 'orange', color: 'black', textAlign: 'center', verticalAlign: 'middle', fontSize: '14px' }}>
                                                {price}
                                            </td>
                                        ))}
                                        <td style={{ padding: '8px', backgroundColor: '#cfe2f3', color: 'black', textAlign: 'center', verticalAlign: 'middle' }}>
                                            <input
                                                type="radio"
                                                name={"selectedRow" + activeTab}
                                                key={activeTab}
                                                checked={selectedLowestValue[activeTab]?.length > 1 && selectedLowestValue[activeTab][1] == 'lowest_among_competitor'}
                                                onChange={() => handleLowestValues([responseData[activeTab]?.lowest_among_competitor, 'lowest_among_competitor'], activeTab)}
                                                style={{ width: '17px', height: '17px' }}
                                            />
                                        </td>
                                    </tr>
                                )}
                                {responseData[activeTab]?.second_lowest_among_competitor && (
                                    <tr style={{ width: '90px', height: '40px' }}>
                                        <td style={{ position: "sticky", left: '181px', zIndex: 1, padding: '8px', border: '2px solid grey', backgroundColor: 'orange', color: 'black', textAlign: 'center', verticalAlign: 'middle', fontSize: '14px' }}><b>2nd Lowest</b></td>
                                        {Object.entries(responseData[activeTab]?.second_lowest_among_competitor || {}).map(([carClass, price], i) => (
                                            <td key={i} style={{ padding: '8px', border: '2px solid grey', backgroundColor: 'orange', color: 'black', textAlign: 'center', verticalAlign: 'middle', fontSize: '14px' }}>
                                                {price}
                                            </td>
                                        ))}
                                        <td style={{ padding: '8px', backgroundColor: '#cfe2f3', color: 'black', textAlign: 'center', verticalAlign: 'middle' }}>
                                            <input
                                                type="radio"
                                                name={"selectedRow" + activeTab}
                                                key={activeTab}
                                                checked={selectedLowestValue[activeTab]?.length > 1 && selectedLowestValue[activeTab][1] == 'second_lowest_among_competitor'} //second_lowest_among_competitor
                                                onChange={() => handleLowestValues([responseData[activeTab]?.second_lowest_among_competitor, 'second_lowest_among_competitor'], activeTab)}
                                                style={{ width: '17px', height: '17px' }}
                                            />
                                        </td>
                                    </tr>
                                )}
                                {responseData[activeTab]?.third_lowest_among_competitor && (
                                    <tr style={{ width: '90px', height: '40px' }}>
                                        <td style={{ position: "sticky", left: '181px', zIndex: 1, padding: '8px', border: '2px solid grey', backgroundColor: 'orange', color: 'black', textAlign: 'center', verticalAlign: 'middle', fontSize: '14px' }}><b>3rd Lowest</b></td>
                                        {Object.entries(responseData[activeTab]?.third_lowest_among_competitor || {}).map(([carClass, price], i) => (
                                            <td key={i} style={{ padding: '8px', border: '2px solid grey', backgroundColor: 'orange', color: 'black', textAlign: 'center', verticalAlign: 'middle', fontSize: '14px' }}>
                                                {price}
                                            </td>
                                        ))}
                                        <td style={{ padding: '8px', backgroundColor: '#cfe2f3', color: 'black', textAlign: 'center', verticalAlign: 'middle' }}>
                                            <input
                                                type="radio"
                                                name={"selectedRow" + activeTab}
                                                key={activeTab}
                                                checked={selectedLowestValue[activeTab]?.length > 1 && selectedLowestValue[activeTab][1] == 'third_lowest_among_competitor'}
                                                onChange={() => handleLowestValues([responseData[activeTab]?.third_lowest_among_competitor, 'third_lowest_among_competitor'], activeTab)}
                                                style={{ width: '17px', height: '17px' }}
                                            />
                                        </td>
                                    </tr>
                                )}
                                {responseData[activeTab]?.lowest_off_competitor && (
                                    <tr style={{ width: '90px', height: '40px' }}>
                                        <td style={{
                                            position: "sticky", left: '181px', zIndex: 1,
                                            padding: '8px', border: '2px solid grey',

                                            backgroundColor: 'orange', color: 'black', textAlign: 'center', verticalAlign: 'middle', fontSize: '14px'
                                        }}><b> Lowest Off-Airport</b></td>
                                        {Object.entries(responseData[activeTab]?.lowest_off_competitor || {}).map(([carClass, price], i) => (
                                            <td key={i} style={{ padding: '8px', border: '2px solid grey', backgroundColor: 'orange', color: 'black', textAlign: 'center', verticalAlign: 'middle', fontSize: '14px' }}>
                                                {price}
                                            </td>
                                        ))}
                                        <td style={{ padding: '8px', backgroundColor: '#cfe2f3', color: 'black', textAlign: 'center', verticalAlign: 'middle' }}>
                                            <input
                                                type="radio"
                                                name={"selectedRow" + activeTab}
                                                key={activeTab}
                                                checked={selectedLowestValue[activeTab]?.length > 1 && selectedLowestValue[activeTab][1] == 'lowest_off_competitor'}
                                                onChange={() => handleLowestValues([responseData[activeTab]?.lowest_off_competitor, 'lowest_off_competitor'], activeTab)}
                                                style={{ width: '17px', height: '17px' }}
                                            />
                                        </td>
                                    </tr>
                                )}
                                {responseData[activeTab]?.second_lowest_off_competitor && (
                                    <tr style={{ width: '90px', height: '40px' }}>
                                        <td style={{ position: "sticky", left: '181px', zIndex: 1, padding: '8px', border: '2px solid grey', backgroundColor: 'orange', color: 'black', textAlign: 'center', verticalAlign: 'middle', fontSize: '14px' }}><b> 2nd Lowest Off-Airport</b></td>
                                        {Object.entries(responseData[activeTab]?.second_lowest_off_competitor || {}).map(([carClass, price], i) => (
                                            <td key={i} style={{ padding: '8px', border: '2px solid grey', backgroundColor: 'orange', color: 'black', textAlign: 'center', verticalAlign: 'middle', fontSize: '14px' }}>
                                                {price}
                                            </td>
                                        ))}
                                        <td style={{ padding: '8px', backgroundColor: '#cfe2f3', color: 'black', textAlign: 'center', verticalAlign: 'middle' }}>
                                            <input
                                                type="radio"
                                                name={"selectedRow" + activeTab}
                                                key={activeTab}
                                                checked={selectedLowestValue[activeTab]?.length > 1 && selectedLowestValue[activeTab][1] == 'second_lowest_off_competitor'}
                                                onChange={() => handleLowestValues([responseData[activeTab]?.second_lowest_off_competitor, 'second_lowest_off_competitor'], activeTab)}
                                                style={{ width: '17px', height: '17px' }}
                                            />
                                        </td>
                                    </tr>
                                )}
                                {responseData[activeTab]?.third_lowest_off_competitor && (
                                    <tr style={{ width: '90px', height: '40px' }}>
                                        <td style={{ position: "sticky", left: '181px', zIndex: 1, padding: '8px', border: '2px solid grey', backgroundColor: 'orange', color: 'black', textAlign: 'center', verticalAlign: 'middle', fontSize: '14px' }}><b> 3rd Lowest Off-Airport</b></td>
                                        {Object.entries(responseData[activeTab]?.third_lowest_off_competitor || {}).map(([carClass, price], i) => (
                                            <td key={i} style={{ padding: '8px', border: '2px solid grey', backgroundColor: 'orange', color: 'black', textAlign: 'center', verticalAlign: 'middle', fontSize: '14px' }}>
                                                {price}
                                            </td>
                                        ))}
                                        <td style={{ padding: '8px', backgroundColor: '#cfe2f3', color: 'black', textAlign: 'center', verticalAlign: 'middle' }}>
                                            <input
                                                type="radio"
                                                name={"selectedRow" + activeTab}
                                                key={activeTab}
                                                checked={selectedLowestValue[activeTab]?.length > 1 && selectedLowestValue[activeTab][1] == 'third_lowest_off_competitor'}
                                                onChange={() => handleLowestValues([responseData[activeTab]?.third_lowest_off_competitor, 'third_lowest_off_competitor'], activeTab)}
                                                style={{ width: '17px', height: '17px' }}
                                            />
                                        </td>
                                    </tr>
                                )}
                                {responseData[activeTab]?.lowest_on_competitor && (
                                    <tr style={{ width: '90px', height: '40px' }}>
                                        <td style={{ position: "sticky", left: '181px', zIndex: 1, padding: '8px', border: '2px solid grey', backgroundColor: 'orange', color: 'black', textAlign: 'center', verticalAlign: 'middle', fontSize: '14px' }}><b> Lowest In-Terminal</b></td>
                                        {Object.entries(responseData[activeTab]?.lowest_on_competitor || {}).map(([carClass, price], i) => (
                                            <td key={i} style={{ padding: '8px', border: '2px solid grey', backgroundColor: 'orange', color: 'black', textAlign: 'center', verticalAlign: 'middle', fontSize: '14px' }}>
                                                {price}
                                            </td>
                                        ))}
                                        <td style={{ padding: '8px', backgroundColor: '#cfe2f3', color: 'black', textAlign: 'center', verticalAlign: 'middle' }}>
                                            <input
                                                type="radio"
                                                name={"selectedRow" + activeTab}
                                                key={activeTab}
                                                checked={selectedLowestValue[activeTab]?.length > 1 && selectedLowestValue[activeTab][1] == 'lowest_on_competitor'}
                                                onChange={() => handleLowestValues([responseData[activeTab]?.lowest_on_competitor, 'lowest_on_competitor'], activeTab)}
                                                style={{ width: '17px', height: '17px' }}
                                            />
                                        </td>
                                    </tr>
                                )}
                                {responseData[activeTab]?.second_lowest_on_competitor && (
                                    <tr style={{ width: '90px', height: '40px' }}>
                                        <td style={{ position: "sticky", left: '181px', zIndex: 1, padding: '8px', border: '2px solid grey', backgroundColor: 'orange', color: 'black', textAlign: 'center', verticalAlign: 'middle', fontSize: '14px' }}><b>2nd Lowest In-Terminal</b></td>
                                        {Object.entries(responseData[activeTab]?.second_lowest_on_competitor || {}).map(([carClass, price], i) => (
                                            <td key={i} style={{ padding: '8px', border: '2px solid grey', backgroundColor: 'orange', color: 'black', textAlign: 'center', verticalAlign: 'middle', fontSize: '14px' }}>
                                                {price}
                                            </td>
                                        ))}
                                        <td style={{ padding: '8px', backgroundColor: '#cfe2f3', color: 'black', textAlign: 'center', verticalAlign: 'middle' }}>
                                            <input
                                                type="radio"
                                                name={"selectedRow" + activeTab}
                                                key={activeTab}
                                                checked={selectedLowestValue[activeTab]?.length > 1 && selectedLowestValue[activeTab][1] == 'second_lowest_on_competitor'}
                                                onChange={() => handleLowestValues([responseData[activeTab]?.second_lowest_on_competitor, 'second_lowest_on_competitor'], activeTab)}
                                                style={{ width: '17px', height: '17px' }}
                                            />
                                        </td>
                                    </tr>
                                )}
                                {responseData[activeTab]?.third_lowest_on_competitor && (
                                    <tr style={{ width: '90px', height: '40px' }}>
                                        <td style={{ position: "sticky", left: '181px', zIndex: 1, padding: '8px', border: '2px solid grey', backgroundColor: 'orange', color: 'black', textAlign: 'center', verticalAlign: 'middle', fontSize: '14px' }}>
                                            <b>3rd Lowest In-Terminal</b>
                                        </td>
                                        {Object.entries(responseData[activeTab]?.third_lowest_on_competitor || {}).map(([carClass, price], i) => (
                                            <td key={i} style={{ padding: '8px', border: '2px solid grey', backgroundColor: 'orange', color: 'black', textAlign: 'center', verticalAlign: 'middle', fontSize: '14px' }}>
                                                {price}
                                            </td>
                                        ))}
                                        <td style={{ padding: '8px', backgroundColor: '#cfe2f3', textAlign: 'center', verticalAlign: 'middle' }}>
                                            <input
                                                type="radio"
                                                name={"selectedRow" + activeTab}
                                                key={activeTab}
                                                checked={selectedLowestValue[activeTab]?.length > 1 && selectedLowestValue[activeTab][1] == 'third_lowest_on_competitor'}
                                                onChange={() => handleLowestValues([responseData[activeTab]?.third_lowest_on_competitor, 'third_lowest_on_competitor'], activeTab)}
                                                style={{ width: '17px', height: '17px' }}
                                            />
                                        </td>
                                    </tr>
                                )}

                                {responseData[activeTab]?.minimum_rates && (
                                    <tr style={{ width: '90px', height: '40px' }}>
                                        <td style={{ position: "sticky", left: '181px', zIndex: 1, padding: '8px', border: '2px solid grey', backgroundColor: '#C60C30', color: 'black', textAlign: 'center', verticalAlign: 'middle', fontSize: '14px' }}>
                                            <b>Minimum Rates</b>
                                        </td>
                                        {Object.entries(responseData[activeTab]?.minimum_rates || {}).map(([carClass, price], i) => (
                                            <td key={i} style={{ padding: '8px', border: '2px solid grey', backgroundColor: '#C60C30', color: 'black', textAlign: 'center', verticalAlign: 'middle', fontSize: '14px' }}>
                                                {price}
                                            </td>
                                        ))}
                                    </tr>
                                )}
                                <tr style={{ width: '90px', height: '40px' }}>
                                    <td style={{ position: "sticky", left: '181px', zIndex: 1, padding: '8px', border: '2px solid grey', backgroundColor: '#198754', color: 'black', textAlign: 'center', verticalAlign: 'middle', fontSize: '14px' }}>
                                        <b>Final Rates</b>
                                    </td>
                                    { 
                                        selectedLowestValue[activeTab]?.length > 0 && Object.entries(selectedLowestValue[activeTab][0]).map(([carClass, price], cellIndex) => (
                                            <td key={`${1}-${cellIndex}`} style={{ padding: '8px', border: '2px solid grey', backgroundColor: '#198754', color: 'black', textAlign: 'center', verticalAlign: 'middle' }}>
                                                <input
                                                    type="number"
                                                    value={price}
                                                    key={activeTab}
                                                    onChange={(e) => handlePriceChange(e, carClass)}
                                                    style={{ width: '100px', textAlign: 'center', fontSize: '14px' }}
                                                />
                                            </td>
                                        ))
                                    }
                                </tr>
                            </tbody>
                        </table>
                        {/* <div style={{ display: 'flex', alignItems: 'center', float: 'right' }}>
                            <label style={{ marginLeft: '-25%', marginRight: '10%', fontSize: '18px' }}>
                                <input
                                    type="checkbox"
                                    checked={isChecked}
                                    onChange={handleTetherCheckboxChange}
                                    style={{
                                        width: '17px',
                                        height: '17px',
                                        marginRight: '10px',
                                        backgroundColor: isChecked && 'blue',
                                        borderColor: isChecked && 'blue',
                                        borderStyle: 'solid',
                                        borderWidth: '1px',
                                    }}
                                />
                                <button
                                    style={{ width: '150px', marginTop: '7px', textAlign: 'center' }}
                                    type="submit"
                                    className="btn btn-success"
                                    onClick={handleTether}
                                >
                                    Tether Rates
                                </button>

                            </label>
                            <div className="full_display">
                                <PushToRateUpdate accountName={companyName.toString()}
                                    locationName={locationName.toString()}
                                    rateCodes={['778288']}
                                    fromDate={instantFromDate} toDate={fromDate} carClass={carDetails} rates={selectedLowestValue}
                                    fileName={"Rates"} rateCodeNames={'Net Rate POA Non-Inclusive'}
                                    account_id={company_id} location_id={locationId}
                                    rateCodeSelected={selectedRateCodeOption} rateSource={selectedRateSourceOption} rowData={rowData} tetheringShow={isChecked} selectedCurrencyOptionP= {selectedCurrencyOption} locationCurrencyP = {locationCurrency} createdBy ={loggedInUserDetail.userEmail} />
                            </div>

                        </div> */}
                    </div>
                    <div style={{ bottom: '10px', padding: '5px' }}>
                        {Object.keys(responseData).map((key) => (
                            <button
                                key={key}
                                onClick={(e) => handleTabClick(e, key)}
                                style={{
                                    padding: '10px',
                                    marginLeft: '-50px',
                                    margin: '0 5px',
                                    backgroundColor: activeTab === key ? '#198754' : 'white',
                                    color: activeTab === key ? 'white' : 'black',
                                    border: '1px solid #ccc',
                                    borderRadius: '5px 5px 0 0',
                                    cursor: 'pointer',
                                    width: '100px',
                                }}
                            >
                                LOR {key}
                            </button>
                        ))}
                    </div>
                    <div className="full_display">
                        <ExportToExcelWithRates accountName={companyName.toString()}
                            locationName={locationName.toString()}
                            rateCodes={['778288']}
                            fromDate={instantFromDate} toDate={fromDate} carClass={carDetails} rates={selectedLowestValue}
                            fileName={"Final Rates"} rateCodeNames={'Net Rate POA Non-Inclusive'} account_id={company_id} location_id={locationId} rateCodeSelected={selectedRateCodeOption} rateSource={selectedRateSourceOption} />
                    </div>
                </div>
                     )
                : ("")
            }
            {/* {Object.keys(responseData).length !== 0 ? (
                <div style={{ display: 'flex', flexDirection: 'column', paddingTop: '20px', paddingBottom: '20px', float: "left" }}>
                    <div style={{ border: '2px solid grey', borderRadius: '0 0 7px 7px', width: 'auto', padding: '15px' }}>
                        <h2><b>{headerData} - LOR {activeTab}</b></h2>
                        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                            <thead style={{ position: "sticky", top: '40px', zIndex: 2 }}>
                                <tr>
                                    <th style={{ position: "sticky", left: '181px', zIndex: 1, padding: '8px', border: '2px solid grey', backgroundColor: 'black', color: 'white', textAlign: 'center', verticalAlign: 'middle', marginLeft: '30px' }}>
                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                            <img src={expedia} style={{ marginLeft: '10px', width: '100px', height: '40px' }} alt="" className='logo' />
                                            <span style={{ color: 'white', marginTop: '5px' }}>SIPP CODES ➔</span>
                                        </div>
                                        <div style={{ textAlign: 'center', marginTop: '5px' }}>
                                            <span style={{ color: 'white', marginRight: '5px' }}>Competitors</span>
                                            <span>&#9660;</span> 
                                        </div>
                                    </th>
                                    {Object.entries(responseData[activeTab]?.competitors_result || {}).slice(0, 1).map(([_, carClassData]) => (
                                        Object.keys(carClassData).map((imageName) => (
                                            <th key={imageName} style={{ padding: '8px', border: '2px solid grey', backgroundColor: 'black', color: 'white', textAlign: 'center', verticalAlign: 'middle' }}>
                                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                    <img src={baseURL + "/static/img/" + imageName.split('@')[1].trim()} alt={`Image for ${imageName}`} style={{ width: '80px', height: 'auto' }} />
                                                    <span style={{ marginTop: '5px' }}>{imageName.split('@')[0].trim()}</span>
                                                </div>
                                            </th>
                                        ))
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {Object.entries(responseData[activeTab]?.competitors_result || {}).map(([competitor, prices], indx) => (
                                    indx == 0 ?
                                        <tr key={competitor}>
                                            <td style={{ position: "sticky", top: '154px', left: '181px', zIndex: 2, border: '2px solid grey', backgroundColor: 'black', color: 'white', textAlign: 'center', verticalAlign: 'middle' }}>
                                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                    <img src={baseURL + "/static/uploads/" + competitor.split('@')[1].trim()} alt={`Image for ${competitor}`} style={{ width: '80px', height: '50px', }} />
                                                </div>
                                            </td>
                                            {Object.values(prices).map((price, i) => (
                                                <td key={i} style={{
                                                    position: "sticky", top: '154px', zIndex: 1,
                                                    padding: '8px',
                                                    border: '2px solid grey',
                                                    backgroundColor: price.toString().includes('!prasanth') ? 'skyblue' : 'black', 
                                                    color: price.toString().includes('!prasanth') ? 'black' : 'white',
                                                    textAlign: 'center',
                                                    verticalAlign: 'middle',
                                                    fontSize: '14px'
                                                }}>
                                                    {price.toString().includes('!prasanth') ? price.toString().replace('!prasanth', '') : price} 
                                                </td>
                                            ))}
                                        </tr>
                                        :
                                        <tr key={competitor}>
                                            <td style={{ position: "sticky", left: '181px', zIndex: 1, border: '2px solid grey', backgroundColor: 'black', color: 'white', textAlign: 'center', verticalAlign: 'middle' }}>
                                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                    <img src={baseURL + "/static/uploads/" + competitor.split('@')[1].trim()} alt={`Image for ${competitor}`} style={{ width: '80px', height: '50px', }} />
                                                </div>
                                            </td>
                                            {Object.values(prices).map((price, i) => (
                                                <td key={i} style={{
                                                    zIndex: 1,
                                                    padding: '8px',
                                                    border: '2px solid grey',
                                                    color: price.toString().includes('lowest1') || price.toString().includes('lowest2') ? 'black' : 'white',
                                                    backgroundColor: price.toString().includes('lowest1') ? '#50C878' : price.toString().includes('lowest2') ? '#4169E1' : price.toString().includes('lowest3') ? 'black' : 'black',
                                                    textAlign: 'center',
                                                    verticalAlign: 'middle',
                                                    fontSize: '14px'
                                                }}>
                                                    {price.toString().includes('!lowest1') || price.toString().includes('!lowest2') || price.toString().includes('!lowest3') ? price.toString().replace(/!lowest[123]/g, '') : price}
                                                </td>
                                            ))}
                                        </tr>
                                ))}
                                {responseData[activeTab]?.lowest_among_competitor && (
                                    <tr style={{ width: '90px', height: '40px' }}>
                                        <td style={{ position: "sticky", left: '181px', zIndex: 1, padding: '8px', border: '2px solid grey', backgroundColor: 'orange', color: 'black', textAlign: 'center', verticalAlign: 'middle', fontSize: '14px' }}><b>Lowest</b></td>
                                        {Object.entries(responseData[activeTab]?.lowest_among_competitor || {}).map(([carClass, price], i) => (
                                            <td key={i} style={{ padding: '8px', border: '2px solid grey', backgroundColor: 'orange', color: 'black', textAlign: 'center', verticalAlign: 'middle', fontSize: '14px' }}>
                                                {price}
                                            </td>
                                        ))}
                                        <td style={{ padding: '8px', backgroundColor: 'white', color: 'black', textAlign: 'center', verticalAlign: 'middle' }}>
                                            <input
                                                type="radio"
                                                name={"selectedRow" + activeTab}
                                                key={activeTab}
                                                checked={selectedLowestValue[activeTab]?.length > 1 && selectedLowestValue[activeTab][1] == 'lowest_among_competitor'}
                                                onChange={() => handleLowestValues([responseData[activeTab]?.lowest_among_competitor, 'lowest_among_competitor'], activeTab)}
                                                style={{ width: '17px', height: '17px' }}
                                            />
                                        </td>
                                    </tr>
                                )}
                                {responseData[activeTab]?.second_lowest_among_competitor && (
                                    <tr style={{ width: '90px', height: '40px' }}>
                                        <td style={{ position: "sticky", left: '181px', zIndex: 1, padding: '8px', border: '2px solid grey', backgroundColor: 'orange', color: 'black', textAlign: 'center', verticalAlign: 'middle', fontSize: '14px' }}><b>2nd Lowest</b></td>
                                        {Object.entries(responseData[activeTab]?.second_lowest_among_competitor || {}).map(([carClass, price], i) => (
                                            <td key={i} style={{ padding: '8px', border: '2px solid grey', backgroundColor: 'orange', color: 'black', textAlign: 'center', verticalAlign: 'middle', fontSize: '14px' }}>
                                                {price}
                                            </td>
                                        ))}
                                        <td style={{ padding: '8px', backgroundColor: 'white', color: 'black', textAlign: 'center', verticalAlign: 'middle' }}>
                                            <input
                                                type="radio"
                                                name={"selectedRow" + activeTab}
                                                key={activeTab}
                                                checked={selectedLowestValue[activeTab]?.length > 1 && selectedLowestValue[activeTab][1] == 'second_lowest_among_competitor'} 
                                                onChange={() => handleLowestValues([responseData[activeTab]?.second_lowest_among_competitor, 'second_lowest_among_competitor'], activeTab)}
                                                style={{ width: '17px', height: '17px' }}
                                            />
                                        </td>
                                    </tr>
                                )}
                                {responseData[activeTab]?.third_lowest_among_competitor && (
                                    <tr style={{ width: '90px', height: '40px' }}>
                                        <td style={{ position: "sticky", left: '181px', zIndex: 1, padding: '8px', border: '2px solid grey', backgroundColor: 'orange', color: 'black', textAlign: 'center', verticalAlign: 'middle', fontSize: '14px' }}><b>3rd Lowest</b></td>
                                        {Object.entries(responseData[activeTab]?.third_lowest_among_competitor || {}).map(([carClass, price], i) => (
                                            <td key={i} style={{ padding: '8px', border: '2px solid grey', backgroundColor: 'orange', color: 'black', textAlign: 'center', verticalAlign: 'middle', fontSize: '14px' }}>
                                                {price}
                                            </td>
                                        ))}
                                        <td style={{ padding: '8px', backgroundColor: 'white', color: 'black', textAlign: 'center', verticalAlign: 'middle' }}>
                                            <input
                                                type="radio"
                                                name={"selectedRow" + activeTab}
                                                key={activeTab}
                                                checked={selectedLowestValue[activeTab]?.length > 1 && selectedLowestValue[activeTab][1] == 'third_lowest_among_competitor'}
                                                onChange={() => handleLowestValues([responseData[activeTab]?.third_lowest_among_competitor, 'third_lowest_among_competitor'], activeTab)}
                                                style={{ width: '17px', height: '17px' }}
                                            />
                                        </td>
                                    </tr>
                                )}
                                {responseData[activeTab]?.lowest_off_competitor && (
                                    <tr style={{ width: '90px', height: '40px' }}>
                                        <td style={{
                                            position: "sticky", left: '181px', zIndex: 1,
                                            padding: '8px', border: '2px solid grey',

                                            backgroundColor: 'orange', color: 'black', textAlign: 'center', verticalAlign: 'middle', fontSize: '14px'
                                        }}><b> Lowest Off-Airport</b></td>
                                        {Object.entries(responseData[activeTab]?.lowest_off_competitor || {}).map(([carClass, price], i) => (
                                            <td key={i} style={{ padding: '8px', border: '2px solid grey', backgroundColor: 'orange', color: 'black', textAlign: 'center', verticalAlign: 'middle', fontSize: '14px' }}>
                                                {price}
                                            </td>
                                        ))}
                                        <td style={{ padding: '8px', backgroundColor: 'white', color: 'black', textAlign: 'center', verticalAlign: 'middle' }}>
                                            <input
                                                type="radio"
                                                name={"selectedRow" + activeTab}
                                                key={activeTab}
                                                checked={selectedLowestValue[activeTab]?.length > 1 && selectedLowestValue[activeTab][1] == 'lowest_off_competitor'}
                                                onChange={() => handleLowestValues([responseData[activeTab]?.lowest_off_competitor, 'lowest_off_competitor'], activeTab)}
                                                style={{ width: '17px', height: '17px' }}
                                            />
                                        </td>
                                    </tr>
                                )}
                                {responseData[activeTab]?.second_lowest_off_competitor && (
                                    <tr style={{ width: '90px', height: '40px' }}>
                                        <td style={{ position: "sticky", left: '181px', zIndex: 1, padding: '8px', border: '2px solid grey', backgroundColor: 'orange', color: 'black', textAlign: 'center', verticalAlign: 'middle', fontSize: '14px' }}><b> 2nd Lowest Off-Airport</b></td>
                                        {Object.entries(responseData[activeTab]?.second_lowest_off_competitor || {}).map(([carClass, price], i) => (
                                            <td key={i} style={{ padding: '8px', border: '2px solid grey', backgroundColor: 'orange', color: 'black', textAlign: 'center', verticalAlign: 'middle', fontSize: '14px' }}>
                                                {price}
                                            </td>
                                        ))}
                                        <td style={{ padding: '8px', backgroundColor: 'white', color: 'black', textAlign: 'center', verticalAlign: 'middle' }}>
                                            <input
                                                type="radio"
                                                name={"selectedRow" + activeTab}
                                                key={activeTab}
                                                checked={selectedLowestValue[activeTab]?.length > 1 && selectedLowestValue[activeTab][1] == 'second_lowest_off_competitor'}
                                                onChange={() => handleLowestValues([responseData[activeTab]?.second_lowest_off_competitor, 'second_lowest_off_competitor'], activeTab)}
                                                style={{ width: '17px', height: '17px' }}
                                            />
                                        </td>
                                    </tr>
                                )}
                                {responseData[activeTab]?.third_lowest_off_competitor && (
                                    <tr style={{ width: '90px', height: '40px' }}>
                                        <td style={{ position: "sticky", left: '181px', zIndex: 1, padding: '8px', border: '2px solid grey', backgroundColor: 'orange', color: 'black', textAlign: 'center', verticalAlign: 'middle', fontSize: '14px' }}><b> 3rd Lowest Off-Airport</b></td>
                                        {Object.entries(responseData[activeTab]?.third_lowest_off_competitor || {}).map(([carClass, price], i) => (
                                            <td key={i} style={{ padding: '8px', border: '2px solid grey', backgroundColor: 'orange', color: 'black', textAlign: 'center', verticalAlign: 'middle', fontSize: '14px' }}>
                                                {price}
                                            </td>
                                        ))}
                                        <td style={{ padding: '8px', backgroundColor: 'white', color: 'black', textAlign: 'center', verticalAlign: 'middle' }}>
                                            <input
                                                type="radio"
                                                name={"selectedRow" + activeTab}
                                                key={activeTab}
                                                checked={selectedLowestValue[activeTab]?.length > 1 && selectedLowestValue[activeTab][1] == 'third_lowest_off_competitor'}
                                                onChange={() => handleLowestValues([responseData[activeTab]?.third_lowest_off_competitor, 'third_lowest_off_competitor'], activeTab)}
                                                style={{ width: '17px', height: '17px' }}
                                            />
                                        </td>
                                    </tr>
                                )}
                                {responseData[activeTab]?.lowest_on_competitor && (
                                    <tr style={{ width: '90px', height: '40px' }}>
                                        <td style={{ position: "sticky", left: '181px', zIndex: 1, padding: '8px', border: '2px solid grey', backgroundColor: 'orange', color: 'black', textAlign: 'center', verticalAlign: 'middle', fontSize: '14px' }}><b> Lowest In-Terminal</b></td>
                                        {Object.entries(responseData[activeTab]?.lowest_on_competitor || {}).map(([carClass, price], i) => (
                                            <td key={i} style={{ padding: '8px', border: '2px solid grey', backgroundColor: 'orange', color: 'black', textAlign: 'center', verticalAlign: 'middle', fontSize: '14px' }}>
                                                {price}
                                            </td>
                                        ))}
                                        <td style={{ padding: '8px', backgroundColor: 'white', color: 'black', textAlign: 'center', verticalAlign: 'middle' }}>
                                            <input
                                                type="radio"
                                                name={"selectedRow" + activeTab}
                                                key={activeTab}
                                                checked={selectedLowestValue[activeTab]?.length > 1 && selectedLowestValue[activeTab][1] == 'lowest_on_competitor'}
                                                onChange={() => handleLowestValues([responseData[activeTab]?.lowest_on_competitor, 'lowest_on_competitor'], activeTab)}
                                                style={{ width: '17px', height: '17px' }}
                                            />
                                        </td>
                                    </tr>
                                )}
                                {responseData[activeTab]?.second_lowest_on_competitor && (
                                    <tr style={{ width: '90px', height: '40px' }}>
                                        <td style={{ position: "sticky", left: '181px', zIndex: 1, padding: '8px', border: '2px solid grey', backgroundColor: 'orange', color: 'black', textAlign: 'center', verticalAlign: 'middle', fontSize: '14px' }}><b>2nd Lowest In-Terminal</b></td>
                                        {Object.entries(responseData[activeTab]?.second_lowest_on_competitor || {}).map(([carClass, price], i) => (
                                            <td key={i} style={{ padding: '8px', border: '2px solid grey', backgroundColor: 'orange', color: 'black', textAlign: 'center', verticalAlign: 'middle', fontSize: '14px' }}>
                                                {price}
                                            </td>
                                        ))}
                                        <td style={{ padding: '8px', backgroundColor: 'white', color: 'black', textAlign: 'center', verticalAlign: 'middle' }}>
                                            <input
                                                type="radio"
                                                name={"selectedRow" + activeTab}
                                                key={activeTab}
                                                checked={selectedLowestValue[activeTab]?.length > 1 && selectedLowestValue[activeTab][1] == 'second_lowest_on_competitor'}
                                                onChange={() => handleLowestValues([responseData[activeTab]?.second_lowest_on_competitor, 'second_lowest_on_competitor'], activeTab)}
                                                style={{ width: '17px', height: '17px' }}
                                            />
                                        </td>
                                    </tr>
                                )}
                                {responseData[activeTab]?.third_lowest_on_competitor && (
                                    <tr style={{ width: '90px', height: '40px' }}>
                                        <td style={{ position: "sticky", left: '181px', zIndex: 1, padding: '8px', border: '2px solid grey', backgroundColor: 'orange', color: 'black', textAlign: 'center', verticalAlign: 'middle', fontSize: '14px' }}>
                                            <b>3rd Lowest In-Terminal</b>
                                        </td>
                                        {Object.entries(responseData[activeTab]?.third_lowest_on_competitor || {}).map(([carClass, price], i) => (
                                            <td key={i} style={{ padding: '8px', border: '2px solid grey', backgroundColor: 'orange', color: 'black', textAlign: 'center', verticalAlign: 'middle', fontSize: '14px' }}>
                                                {price}
                                            </td>
                                        ))}
                                        <td style={{ padding: '8px', backgroundColor: 'white', textAlign: 'center', verticalAlign: 'middle' }}>
                                            <input
                                                type="radio"
                                                name={"selectedRow" + activeTab}
                                                key={activeTab}
                                                checked={selectedLowestValue[activeTab]?.length > 1 && selectedLowestValue[activeTab][1] == 'third_lowest_on_competitor'}
                                                onChange={() => handleLowestValues([responseData[activeTab]?.third_lowest_on_competitor, 'third_lowest_on_competitor'], activeTab)}
                                                style={{ width: '17px', height: '17px' }}
                                            />
                                        </td>
                                    </tr>
                                )}
                                {responseData[activeTab]?.minimum_rates && (
                                    <tr style={{ width: '90px', height: '40px' }}>
                                        <td style={{ position: "sticky", left: '181px', zIndex: 1, padding: '8px', border: '2px solid grey', backgroundColor: '#C60C30', color: 'black', textAlign: 'center', verticalAlign: 'middle', fontSize: '14px' }}>
                                            <b>Minimum Rates</b>
                                        </td>
                                        {Object.entries(responseData[activeTab]?.minimum_rates || {}).map(([carClass, price], i) => (
                                            <td key={i} style={{ padding: '8px', border: '2px solid grey', backgroundColor: '#C60C30', color: 'black', textAlign: 'center', verticalAlign: 'middle', fontSize: '14px' }}>
                                                {price}
                                            </td>
                                        ))}
                                    </tr>
                                )}
                                <tr style={{ width: '90px', height: '40px' }}>
                                    <td style={{ position: "sticky", left: '181px', zIndex: 1, padding: '8px', border: '2px solid grey', backgroundColor: '#198754', color: 'black', textAlign: 'center', verticalAlign: 'middle', fontSize: '14px' }}>
                                        <b>Final Rates</b>
                                    </td>
                                    { 
                                        selectedLowestValue[activeTab]?.length > 0 && Object.entries(selectedLowestValue[activeTab][0]).map(([carClass, price], cellIndex) => (
                                            <td key={`${1}-${cellIndex}`} style={{ padding: '8px', border: '2px solid grey', backgroundColor: '#198754', color: 'black', textAlign: 'center', verticalAlign: 'middle' }}>
                                                <input
                                                    type="number"
                                                    value={price}
                                                    key={activeTab}
                                                    onChange={(e) => handlePriceChange(e, carClass)}
                                                    style={{ width: '100px', textAlign: 'center', fontSize: '14px' }}
                                                />
                                            </td>
                                        ))
                                    }
                                </tr>
                            </tbody>
                        </table>
                        <div style={{ display: 'flex', alignItems: 'center', float: 'right' }}>
                            <label style={{ marginLeft: '-25%', marginRight: '10%', fontSize: '18px' }}>
                                <input
                                    type="checkbox"
                                    checked={isChecked}
                                    onChange={handleTetherCheckboxChange}
                                    style={{
                                        width: '17px',
                                        height: '17px',
                                        marginRight: '10px',
                                        backgroundColor: isChecked && 'blue',
                                        borderColor: isChecked && 'blue',
                                        borderStyle: 'solid',
                                        borderWidth: '1px',
                                    }}
                                />
                                <button
                                    style={{ width: '150px', marginTop: '7px', textAlign: 'center' }}
                                    type="submit"
                                    className="btn btn-success"
                                    onClick={handleTether}
                                >
                                    Tether Rates
                                </button>
                            </label>
                        </div>
                    </div>
                    <div style={{ bottom: '10px', padding: '5px' }}>
                        {Object.keys(responseData).map((key) => (
                            <button
                                key={key}
                                onClick={(e) => handleTabClick(e, key)}
                                style={{
                                    padding: '10px',
                                    marginLeft: '-50px',
                                    margin: '0 5px',
                                    backgroundColor: activeTab === key ? '#198754' : 'white',
                                    color: activeTab === key ? 'white' : 'black',
                                    border: '1px solid #ccc',
                                    borderRadius: '5px 5px 0 0',
                                    cursor: 'pointer',
                                    width: '100px',
                                }}
                            >
                                LOR {key}
                            </button>
                        ))}
                    </div>
                    <div className="full_display">
                        <ExportToExcelWithRates accountName={companyName.toString()}
                            locationName={locationName.toString()}
                            rateCodes={['778288']}
                            fromDate={instantFromDate} toDate={fromDate} carClass={carDetails} rates={selectedLowestValue}
                            fileName={"Final Rates"} rateCodeNames={'Net Rate POA Non-Inclusive'} account_id={company_id} location_id={locationId} rateCodeSelected={selectedRateCodeOption} rateSource={selectedRateSourceOption} />
                    </div>
                </div>
                     )
                : ("")
            } */}
        </div>
    )
}    