import React, { useState } from 'react'
import UpdateForm from './UpdateForm';
import { Button, Typography } from '@mui/material';
import { Modal } from 'react-bootstrap'

export default function RateDownload() {
    const [lgShow, setLgShow] = useState(false);

    return (
        <>
            <div className="row">
                <div className="row my-3 ">
                    <div className="col-md-6">
                        {/* <Typography className="mx-3" variant='h6' sx={{ pb: 3 }}>
                            Rate Download
                        </Typography> */}
                        <Typography className="mx-3" variant='h6' sx={{ pb: 3 }} style = {{marginTop:'5px', fontSize:'20px'}}>
                            <b>Rate Download</b>
                        </Typography>
                    </div>

                    <div className="col-md-6 d-flex justify-content-end">
                        {/* <button type="button" className="btn btn-danger" onClick={() => setLgShow(true)} style={{ height: "40px" }}  >Help</button> */}
                        <Modal
                            size="lg"
                            show={lgShow}
                            onHide={() => setLgShow(false)}
                            aria-labelledby="example-modal-sizes-title-lg">
                            <Modal.Header closeButton style={{ color: '#FFFFFF', backgroundColor: '#C62828' }}>
                                <Modal.Title id="example-modal-sizes-title-lg" >
                                    Instructions to Add/Delete Rate Download
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <h5>Rate Download</h5>
                                <ol>
                                    <li>Select Account for Fleet Standard</li>
                                    <li>Select Account Name from the dropdown</li>
                                    <li>Add new - Select Car Category, Type, Transmission and Air Conditioner</li>
                                    <li>Click on Submit button</li>
                                </ol>

                                <h5>Existing Car Classes</h5>
                                <ol>
                                    <li>To View/Delete Existing Car Classes select Account name from the dropdown</li>
                                </ol>
                            </Modal.Body>
                        </Modal>
                    </div>
                </div>
            </div>

            <UpdateForm />
        </>
    );
}
