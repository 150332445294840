import React, { useState, useEffect } from 'react'
import { Alert } from "@mui/material";
import Select from "react-select";
import { baseURL } from '../backend_url';
import { Button } from '@mui/material'
import * as Yup from 'yup';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import EditIcon from '@mui/icons-material/Edit';
import Swal from 'sweetalert2';

export default function UpdateForm() {
    const removeSpaces = (val) => {
        let temp = '';
        for (let i = 0; i < val.length; i++) {
            if (val[i] !== ' ') {
                temp += val[i];
            }
        }
        return temp;
    }

    const [companyDetail, setcompanyDetail] = useState([{}]);
    const [companyName, setCompanyName] = useState("");
    const [company_id, setcompany_id] = useState("");

    const [createNewFlag, setCreateNewFlag] = useState(false);

    const [roleName, setRoleName] = useState('');
    const [showFor, setShowFor] = useState('');

    const [modulesList, setModuleList] = useState([]);
    // const columnList = ["Access Specifier", "Account Access", "Rate Code Selection", "Copy Rates", "Days Applicable Selection", "Excel Upload", "OTA Selection", "Car Class Selection", "Type of Adjustment/ Promotion / Blackout"];
    const columnList = ["Access Specifier", "Account Access"];

    const [grid, setGrid] = useState({});

    const [listOfRoles, setListOfRoles] = useState([]);

    const [editFlag, setEditFlag] = useState(false);

    const [validationMsg, setValidationMsg] = useState("");
    const [successMsg, setSuccessMsg] = useState("");
    const [loading, setLoading] = useState(false);

    // form validation rules 
    const validationSchema = Yup.object().shape({
        roleName: Yup.string()
            .required('Required')
    });
    const formOptions = { resolver: yupResolver(validationSchema) };

    // get functions to build form with useForm() hook
    const { register, handleSubmit, setValue, formState: { errors } } = useForm(formOptions);

    //API call
    useEffect(() => {
        fetch(baseURL + "/get_corporate_companies")
            .then((res) => res.json())
            .then((data) => {
                setcompanyDetail(data);
            });

        fetch(baseURL + "/get_modules")
            .then((res) => res.json())
            .then((data) => {
                setModuleList(data);
                console.log('setModuleList',data)

                // initial values of the grid
                let hmap = {}
                for (let i = 0; i < data.length; i++) {
                    for (let j = 0; j < columnList.length; j++) {
                        let module_name = removeSpaces(data[i]['name']);
                        let col_name = columnList[j];
                        console.log('col_name',col_name)


                        let key = module_name + "_" + col_name;
                        hmap[key] = '';

                        if (col_name === 'Access Specifier') {
                            hmap[key] = accessSpecifierOptions[2]['value'];
                        }
                        else if (col_name === 'Account Access') {
                            if (['Account', 'AccountLocations', 'OptionalServicesImages', 'OptionalServicesStandard', 'FleetStandard', 'Cars', 'RateID', 'AccessSetup'].indexOf(module_name) <= -1)
                                hmap[key] = accountAccessOptions[2]['value'];
                        }
                        // else if (col_name === 'Rate Code Selection') {
                        //     if (['MinimumRate', 'RateUpdate', 'RateSnapshot', 'Adjustment', 'Promotion', 'Blackout'].indexOf(module_name) > -1)
                        //         hmap[key] = yesNoOptions[1]['value'];
                        // }
                        // else if (col_name === 'Copy Rates') {
                        //     if (module_name === 'RateUpdate')
                        //         hmap[key] = yesNoOptions[1]['value'];
                        // }
                        // else if (col_name === 'Days Applicable Selection') {
                        //     if (['RateUpdate', 'Adjustment', 'Promotion', 'Blackout'].indexOf(module_name) > -1)
                        //         hmap[key] = yesNoOptions[1]['value'];
                        // }
                        // else if (col_name === 'Excel Upload') {
                        //     if (module_name === 'RateUpdate')
                        //         hmap[key] = yesNoOptions[1]['value'];
                        // }
                        // else if (col_name === 'OTA Selection') {
                        //     if (['Adjustment', 'Promotion', 'Blackout'].indexOf(module_name) > -1)
                        //         hmap[key] = yesNoOptions[1]['value'];
                        // }
                        // else if (col_name === 'Car Class Selection') {
                        //     if (['Adjustment', 'Promotion', 'Blackout', 'UtilizationBasedAdjustment'].indexOf(module_name) > -1)
                        //         hmap[key] = yesNoOptions[1]['value'];
                        // }
                        // else if (col_name === 'Type of Adjustment/ Promotion / Blackout') {
                        //     if (['Adjustment', 'Promotion', 'Blackout'].indexOf(module_name) > -1)
                        //         hmap[key] = typeOfAdjustmentPromotionBlackoutOptions[0]['value'];
                        // }
                    }
                }

                setGrid(hmap);
            });

        setRoleName('');
        setShowFor('');
        resetHmapValues();

        // Yup Validation setValue
        // setting value for Yup validations
        setValue('roleName', '');

        setEditFlag(false);
    }, []);

    useEffect(() => {
        if (companyDetail && companyName) {
            let temp_company_id = companyDetail.filter(
                (value) => value.company_name === companyName
            )[0]._id;
            setcompany_id(temp_company_id);

            fetch(baseURL + "/get_roles_setup_data/" + temp_company_id)
                .then((res) => res.json())
                .then((data) => {
                    console.log(data)
                    if (data.length > 0) {
                        setListOfRoles(data);
                    }
                    else {
                        setListOfRoles([]);
                    }
                })

            setRoleName('');
            setShowFor('');
            resetHmapValues();

            // Yup Validation setValue
            // setting value for Yup validations
            setValue('roleName', '');

            setEditFlag(false);
        }
    }, [companyName]);

    //Option for company name
    const companyNameOptions = companyDetail.map((value) => (
        { value: value.company_name, label: value.company_name }
    ));

    const showForOptions = [{ value: "Corporate Only", label: "Corporate Only" }, { value: "Both Corporate and Affiliate", label: "Both Corporate and Affiliate" }];

    const yesNoOptions = [{ value: "Yes", label: "Yes" }, { value: "No", label: "No" }];

    const accessSpecifierOptions = [{ value: "N/A", label: "N/A" }, { value: "Edit", label: "Edit" }, { value: "View", label: "View" }];

    const accountAccessOptions = [{ value: "Own Corporate + Affiliates", label: "Own Corporate + Affiliates" }, { value: "Only own corporate", label: "Only own corporate" }, { value: "Only own affiliate", label: "Only own affiliate" }];

    const typeOfAdjustmentPromotionBlackoutOptions = [{ value: "Date Based Only", label: "Date Based Only" }, { value: "All", label: "All" }];

    const handleInputChangeCompanyName = characterEntered => {
        setCompanyName(characterEntered.value);
    };

    const handleInputChangeShowFor = (characterEntered) => {
        setShowFor(characterEntered.value);
    };

    const handleInputChangeGrid = (e, key) => {
        let new_key = removeSpaces(key.split("_")[0]) + "_" + key.split("_")[1];
        let hmap = { ...grid }
        hmap[new_key] = e;

        setGrid(hmap);
    };

    const resetHmapValues = () => {
        let hmap = { ...grid }
        let keys = Object.keys(hmap);

        for (let i = 0; i < keys.length; i++) {
            let key = keys[i];
            hmap[key] = '';

            let module_name = key.split('_')[0];
            let col_name = key.split('_')[1];

            if (col_name === 'Access Specifier') {
                hmap[key] = accessSpecifierOptions[0]['value'];
            }
            else if (col_name === 'Account Access') {
                if (['Account', 'AccountLocations', 'OptionalServicesImages', 'OptionalServicesStandard', 'FleetStandard', 'Cars', 'RateID', 'AccessSetup'].indexOf(module_name) <= -1)
                    hmap[key] = accountAccessOptions[2]['value'];
            }
            // else if (col_name === 'Rate Code Selection') {
            //     if (['MinimumRate', 'RateUpdate', 'RateSnapshot', 'Adjustment', 'Promotion', 'Blackout'].indexOf(module_name) > -1)
            //         hmap[key] = yesNoOptions[1]['value'];
            // }
            // else if (col_name === 'Copy Rates') {
            //     if (module_name === 'RateUpdate')
            //         hmap[key] = yesNoOptions[1]['value'];
            // }
            // else if (col_name === 'Days Applicable Selection') {
            //     if (['RateUpdate', 'Adjustment', 'Promotion', 'Blackout'].indexOf(module_name) > -1)
            //         hmap[key] = yesNoOptions[1]['value'];
            // }
            // else if (col_name === 'Excel Upload') {
            //     if (module_name === 'RateUpdate')
            //         hmap[key] = yesNoOptions[1]['value'];
            // }
            // else if (col_name === 'OTA Selection') {
            //     if (['Adjustment', 'Promotion', 'Blackout'].indexOf(module_name) > -1)
            //         hmap[key] = yesNoOptions[1]['value'];
            // }
            // else if (col_name === 'Car Class Selection') {
            //     if (['Adjustment', 'Promotion', 'Blackout', 'UtilizationBasedAdjustment'].indexOf(module_name) > -1)
            //         hmap[key] = yesNoOptions[1]['value'];
            // }
            // else if (col_name === 'Type of Adjustment/ Promotion / Blackout') {
            //     if (['Adjustment', 'Promotion', 'Blackout'].indexOf(module_name) > -1)
            //         hmap[key] = typeOfAdjustmentPromotionBlackoutOptions[0]['value'];
            // }
        }

        setGrid(hmap);
    }

    const submitHandler = () => {
        setSuccessMsg("");
        setLoading(true);
        setValidationMsg("");

        if (showFor === '') {
            setLoading(false)
            setValidationMsg("Please select Show For to proceed...")
            window.scrollTo(0, 0);
            return;
        }
        else {
            let hmap = { ...grid }
            let keys = Object.keys(hmap)
            let finalHmap = {}

            console.log(hmap)
            console.log(keys)
            for (let i = 0; i < keys.length; i++) {
                if (hmap[keys[i]] != '') {
                    finalHmap[keys[i]] = hmap[keys[i]];
                }
            }
            fetch(baseURL + '/save_roles_setup',
                {
                    method: 'POST',
                    headers: { 'Content-type': 'application/json' },
                    body: JSON.stringify({
                        "account_name": companyName.toString(),
                        "account_id": company_id.toString(),
                        "role_id": company_id.toString() + "_" + roleName,
                        "role_name": roleName,
                        "show_for": showFor,
                        "role_definition": finalHmap,
                        "flag": editFlag
                    })
                })
                .then(resp => resp.json())
                .then(data => {
                    setLoading(false)
                    if (data === 'Duplicate Entry') {
                        setValidationMsg('Role Name already exists. Please try again with different Role Name')
                        window.scrollTo(0, 0);
                        return;
                    }
                    else {
                        setSuccessMsg("Roles added/edited successfully")
                        setListOfRoles(data);

                        setRoleName('');
                        setShowFor('');
                        resetHmapValues();

                        // Yup Validation setValue
                        // setting value for Yup validations
                        setValue('roleName', '');

                        setEditFlag(false);
                        window.scrollTo(0, 0);
                    }
                })
                .catch(error => console.log(error))
        }
    }

    const editRole = (i) => {
        Swal.fire({
            title: 'Edit the selected Role?',
            showCancelButton: true,
            confirmButtonText: 'Yes',
        })
            .then((result) => {
                if (result.isConfirmed) {
                    setEditFlag(true);
                    setCreateNewFlag(true);
                    console.log('listOfRoles',listOfRoles)

                    listOfRoles.map((arr, index) => {
                        if (i === index) {
                            setRoleName(arr['role_name']);

                            // Yup Validation setValue
                            // setting value for Yup validations
                            setValue('roleName', arr['role_name']);

                            setShowFor(arr['show_for']);
                            let tempGrid = arr['role_definition'];
                            let hmap = { ...grid }
                            let keys = Object.keys(hmap);

                            for (let j = 0; j < keys.length; j++) {
                                if (tempGrid[keys[j]] != undefined) {
                                    hmap[keys[j]] = tempGrid[keys[j]];
                                }
                                else {
                                    hmap[keys[j]] = '';
                                }
                            }

                            setGrid(hmap);
                        }
                    })
                }
            })
    }

    return (
        <>
            <div className="row pt-2">
                <div className="col-12 mx-3">
                    <span style={{ color: '#1E1E1E', fontSize: '21px' }}>Select Account to give Access Rolewise:</span>
                </div>
            </div>


            <div className="row pt-4 mx-2">
                <div className="col-md-10">
                    {
                        validationMsg ?
                            (<Alert severity="error">{validationMsg}!</Alert>) : ""
                    }

                    {
                        successMsg ?
                            (
                                <div className="alert alert-success alert-dismissible fade show" role="alert">
                                    {successMsg}
                                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => setSuccessMsg('')}></button>
                                </div>
                            )
                            : ""
                    }

                    <div className="row pt-3">
                        <div className="col-md-2 mb-3">
                            <label htmlFor="company_name" className="form-label" style={{ marginTop: 5 }}>
                                Account Name
                            </label>
                        </div>

                        <div className="col-md-4 mb-3">
                            <Select
                                value={{ value: companyName, label: (companyName ? companyName : "Select Account Name") }}
                                inputId="company_name"
                                name="company_name"
                                options={companyNameOptions}
                                onChange={handleInputChangeCompanyName}
                            />
                        </div>

                        <div className="col-md-1 mb-3"></div>

                        <div className="col-md-2 mb-3">
                            <label htmlFor="company_id" className="form-label" style={{ marginTop: 5 }}>
                                Account ID
                            </label>
                        </div>

                        <div className="col-md-2 mb-3">
                            <input
                                type="text"
                                className="form-control"
                                value={company_id}
                                id="company_id"
                                disabled
                            />
                        </div>
                    </div>
                </div>
            </div>


            <div className="row pt-4 mx-2">
                <div className="col-md-10">
                    {
                        createNewFlag ?
                            (<Button variant="contained" disabled={company_id === '' ? true : false} onClick={() => setCreateNewFlag(false)} >- Hide</Button>) :
                            editFlag ? (<Button variant="contained" disabled={company_id === '' ? true : false} onClick={() => setCreateNewFlag(true)} >Edit Role</Button>) :
                                (<Button variant="contained" disabled={company_id === '' ? true : false} onClick={() => setCreateNewFlag(true)} >+ Create New</Button>)
                    }
                </div>
            </div>

            {
                createNewFlag ?
                    <>
                        <form className="row g-3" onSubmit={handleSubmit(submitHandler)}>
                            <div className="row pt-4 mx-2">
                                <div className="col-md-10">
                                    <div className="row pt-3">
                                        <div className="col-md-2 mb-1">
                                            <label htmlFor="roleName" className="form-label" style={{ marginTop: 5 }}>
                                                Role Name *
                                            </label>
                                        </div>

                                        <div className="col-md-4 mb-1">
                                            <input type="text" disabled={editFlag} className={`form-control ${errors.roleName ? 'is-invalid' : ''}`} {...register('roleName')} id="roleName" value={roleName} onChange={(e) => setRoleName(e.target.value)} />
                                            <div className="invalid-feedback">{errors.roleName?.message}</div>
                                        </div>

                                        <div className="col-md-1 mb-3"></div>

                                        <div className="col-md-2 mb-3">
                                            <label htmlFor="showFor" className="form-label" style={{ marginTop: 5 }}>
                                                Show For
                                            </label>
                                        </div>

                                        <div className="col-md-3 mb-3">
                                            <Select
                                                value={{ value: showFor, label: (showFor ? showFor : "Select..") }}
                                                inputId="showFor"
                                                name="showFor"
                                                options={showForOptions}
                                                onChange={handleInputChangeShowFor}
                                                isDisabled={editFlag}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row pt-4">
                                <div className="col-md-11 mb-1 mx-2">
                                    <table className="table table-bordered table-condensed text-center">
                                        <thead>
                                            <tr>
                                                <th scope="col">Module</th>
                                                <th scope="col">Access Specifier</th>
                                                <th scope="col">Account Access </th>
                                            </tr>
                                        </thead>
                                        <tbody className="text-left">
                                            {/* Dashboard */}
                                            <tr>
                                                <td>{modulesList[0]['name']}</td>
                                               <td></td>
                                                <td></td>
                                            </tr>
                                            {/* Activity  Queue*/}
                                            <tr>
                                                <td>{modulesList[1]['name']}</td>
                                                <td></td>
                                                <td></td>
                                            </tr> 
                                            {/* Account*/}
                                            {/* <tr>
                                                <td>{modulesList[2]['name']}</td>
                                                <td></td>
                                                <td></td>
                                            </tr> */}
                                            <tr>
                                                <td>{modulesList[2]['name']}</td>
                                                <td style={{ verticalAlign: 'middle', textAlign: 'center'}}>
                                                    <select value={grid[removeSpaces(modulesList[2]['name']) + "_" + columnList[0]]} style={{ width: '100%', textAlign: 'center' }} onChange={(e) => handleInputChangeGrid(e.target.value, modulesList[2]['name'] + "_" + columnList[0])}>
                                                        {
                                                            accessSpecifierOptions.map((val) => (
                                                                <option value={val.value}>{val.label}</option>))
                                                        }
                                                    </select>
                                                </td>
                                                <td style={{ verticalAlign: 'middle' }}>
                                                    <select value={grid[removeSpaces(modulesList[2]['name']) + "_" + columnList[1]]} style={{ width: '100%', textAlign: 'center' }} onChange={(e) => handleInputChangeGrid(e.target.value, modulesList[2]['name'] + "_" + columnList[1])}>
                                                        {
                                                            accountAccessOptions.map((val) => (
                                                                <option value={val.value}>{val.label}</option>))
                                                        }
                                                    </select>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>{modulesList[3]['name']}</td>
                                                <td style={{ verticalAlign: 'middle', textAlign: 'center'}}>
                                                    <select value={grid[removeSpaces(modulesList[3]['name']) + "_" + columnList[0]]} style={{ width: '100%', textAlign: 'center' }} onChange={(e) => handleInputChangeGrid(e.target.value, modulesList[3]['name'] + "_" + columnList[0])}>
                                                        {
                                                            accessSpecifierOptions.map((val) => (
                                                                <option value={val.value}>{val.label}</option>))
                                                        }
                                                    </select>
                                                </td>
                                                <td style={{ verticalAlign: 'middle' }}>
                                                    <select value={grid[removeSpaces(modulesList[3]['name']) + "_" + columnList[1]]} style={{ width: '100%', textAlign: 'center' }} onChange={(e) => handleInputChangeGrid(e.target.value, modulesList[3]['name'] + "_" + columnList[1])}>
                                                        {
                                                            accountAccessOptions.map((val) => (
                                                                <option value={val.value}>{val.label}</option>))
                                                        }
                                                    </select>
                                                </td>
                                            </tr>
                                            {/* Location */}
                                            <tr>
                                                <td>{modulesList[4]['name']}</td>
                                                <td style={{ verticalAlign: 'middle', textAlign: 'center'}}>
                                                    <select value={grid[removeSpaces(modulesList[4]['name']) + "_" + columnList[0]]} style={{ width: '100%', textAlign: 'center' }} onChange={(e) => handleInputChangeGrid(e.target.value, modulesList[4]['name'] + "_" + columnList[0])}>
                                                        {
                                                            accessSpecifierOptions.map((val) => (
                                                                <option value={val.value}>{val.label}</option>))
                                                        }
                                                    </select>
                                                </td>
                                                <td style={{ verticalAlign: 'middle' }}>
                                                    <select value={grid[removeSpaces(modulesList[4]['name']) + "_" + columnList[1]]} style={{ width: '100%', textAlign: 'center' }} onChange={(e) => handleInputChangeGrid(e.target.value, modulesList[4]['name'] + "_" + columnList[1])}>
                                                        {
                                                            accountAccessOptions.map((val) => (
                                                                <option value={val.value}>{val.label}</option>))
                                                        }
                                                    </select>
                                                </td>
                                            </tr>
                                             {/* Business Hours */}
                                            <tr>
                                                <td>{modulesList[5]['name']}</td>
                                                <td style={{ verticalAlign: 'middle' }}>
                                                    <select value={grid[removeSpaces(modulesList[5]['name']) + "_" + columnList[0]]} style={{ width: '100%', textAlign: 'center' }} onChange={(e) => handleInputChangeGrid(e.target.value, modulesList[5]['name'] + "_" + columnList[0])}>
                                                        {
                                                            accessSpecifierOptions.map((val) => (
                                                                <option value={val.value}>{val.label}</option>))
                                                        }
                                                    </select>
                                                </td>
                                                <td style={{ verticalAlign: 'middle' }}>
                                                    <select value={grid[removeSpaces(modulesList[5]['name']) + "_" + columnList[1]]} style={{ width: '100%', textAlign: 'center' }} onChange={(e) => handleInputChangeGrid(e.target.value, modulesList[5]['name'] + "_" + columnList[1])}>
                                                        {
                                                            accountAccessOptions.map((val) => (
                                                                <option value={val.value}>{val.label}</option>))
                                                        }
                                                    </select>
                                                </td>
                                            </tr>

                                            {/* Policies */}
                                            <tr>
                                                <td>{modulesList[6]['name']}</td>
                                                <td style={{ verticalAlign: 'middle' }}>
                                                    <select value={grid[removeSpaces(modulesList[6]['name']) + "_" + columnList[0]]} style={{ width: '100%', textAlign: 'center' }} onChange={(e) => handleInputChangeGrid(e.target.value, modulesList[6]['name'] + "_" + columnList[0])}>
                                                        {
                                                            accessSpecifierOptions.map((val) => (
                                                                <option value={val.value}>{val.label}</option>))
                                                        }
                                                    </select>
                                                </td>
                                                <td style={{ verticalAlign: 'middle' }}>
                                                    <select value={grid[removeSpaces(modulesList[6]['name']) + "_" + columnList[1]]} style={{ width: '100%', textAlign: 'center' }} onChange={(e) => handleInputChangeGrid(e.target.value, modulesList[6]['name'] + "_" + columnList[1])}>
                                                        {
                                                            accountAccessOptions.map((val) => (
                                                                <option value={val.value}>{val.label}</option>))
                                                        }
                                                    </select>
                                                </td>
                                            </tr>

                                            {/* Tax */}
                                            <tr>
                                                <td>{modulesList[7]['name']}</td>
                                                <td style={{ verticalAlign: 'middle' }}>
                                                    <select value={grid[removeSpaces(modulesList[7]['name']) + "_" + columnList[0]]} style={{ width: '100%', textAlign: 'center' }} onChange={(e) => handleInputChangeGrid(e.target.value, modulesList[7]['name'] + "_" + columnList[0])}>
                                                        {
                                                            accessSpecifierOptions.map((val) => (
                                                                <option value={val.value}>{val.label}</option>))
                                                        }
                                                    </select>
                                                </td>
                                                <td style={{ verticalAlign: 'middle' }}>
                                                    <select value={grid[removeSpaces(modulesList[7]['name']) + "_" + columnList[1]]} style={{ width: '100%', textAlign: 'center' }} onChange={(e) => handleInputChangeGrid(e.target.value, modulesList[7]['name'] + "_" + columnList[1])}>
                                                        {
                                                            accountAccessOptions.map((val) => (
                                                                <option value={val.value}>{val.label}</option>))
                                                        }
                                                    </select>
                                                </td>
                                            </tr>

                                            {/* Optional Services Images */}
                                            <tr>
                                                <td>{modulesList[8]['name']}</td>
                                                <td style={{ verticalAlign: 'middle' }}>
                                                    <select value={grid[removeSpaces(modulesList[8]['name']) + "_" + columnList[0]]} style={{ width: '100%', textAlign: 'center' }} onChange={(e) => handleInputChangeGrid(e.target.value, modulesList[8]['name'] + "_" + columnList[0])}>
                                                        {
                                                            accessSpecifierOptions.map((val) => (
                                                                <option value={val.value}>{val.label}</option>))
                                                        }
                                                    </select>
                                                </td>
                                                <td style={{ verticalAlign: 'middle' }}>
                                                    <select value={grid[removeSpaces(modulesList[8]['name']) + "_" + columnList[1]]} style={{ width: '100%', textAlign: 'center' }} onChange={(e) => handleInputChangeGrid(e.target.value, modulesList[8]['name'] + "_" + columnList[1])}>
                                                        {
                                                            accountAccessOptions.map((val) => (
                                                                <option value={val.value}>{val.label}</option>))
                                                        }
                                                    </select>
                                                </td>
                                            </tr>

                                            {/* Optional Services Standard */}
                                            <tr>
                                                <td>{modulesList[9]['name']}</td>
                                                <td style={{ verticalAlign: 'middle' }}>
                                                    <select value={grid[removeSpaces(modulesList[9]['name']) + "_" + columnList[0]]} style={{ width: '100%', textAlign: 'center' }} onChange={(e) => handleInputChangeGrid(e.target.value, modulesList[9]['name'] + "_" + columnList[0])}>
                                                        {
                                                            accessSpecifierOptions.map((val) => (
                                                                <option value={val.value}>{val.label}</option>))
                                                        }
                                                    </select>
                                                </td>
                                                <td style={{ verticalAlign: 'middle' }}>
                                                    <select value={grid[removeSpaces(modulesList[9]['name']) + "_" + columnList[1]]} style={{ width: '100%', textAlign: 'center' }} onChange={(e) => handleInputChangeGrid(e.target.value, modulesList[9]['name'] + "_" + columnList[1])}>
                                                        {
                                                            accountAccessOptions.map((val) => (
                                                                <option value={val.value}>{val.label}</option>))
                                                        }
                                                    </select>
                                                </td>
                                            </tr>

                                            {/* Optional Services */}
                                            <tr>
                                                <td>{modulesList[10]['name']}</td>
                                                <td style={{ verticalAlign: 'middle' }}>
                                                    <select value={grid[removeSpaces(modulesList[10]['name']) + "_" + columnList[0]]} style={{ width: '100%', textAlign: 'center' }} onChange={(e) => handleInputChangeGrid(e.target.value, modulesList[10]['name'] + "_" + columnList[0])}>
                                                        {
                                                            accessSpecifierOptions.map((val) => (
                                                                <option value={val.value}>{val.label}</option>))
                                                        }
                                                    </select>
                                                </td>
                                                <td style={{ verticalAlign: 'middle' }}>
                                                    <select value={grid[removeSpaces(modulesList[10]['name']) + "_" + columnList[1]]} style={{ width: '100%', textAlign: 'center' }} onChange={(e) => handleInputChangeGrid(e.target.value, modulesList[10]['name'] + "_" + columnList[1])}>
                                                        {
                                                            accountAccessOptions.map((val) => (
                                                                <option value={val.value}>{val.label}</option>))
                                                        }
                                                    </select>
                                                </td>
                                            </tr>

                                            {/* Fleet Standard */}
                                            <tr>
                                                <td>{modulesList[11]['name']}</td>
                                                <td style={{ verticalAlign: 'middle' }}>
                                                    <select value={grid[removeSpaces(modulesList[11]['name']) + "_" + columnList[0]]} style={{ width: '100%', textAlign: 'center' }} onChange={(e) => handleInputChangeGrid(e.target.value, modulesList[11]['name'] + "_" + columnList[0])}>
                                                        {
                                                            accessSpecifierOptions.map((val) => (
                                                                <option value={val.value}>{val.label}</option>))
                                                        }
                                                    </select>
                                                </td>
                                                <td style={{ verticalAlign: 'middle' }}>
                                                    <select value={grid[removeSpaces(modulesList[11]['name']) + "_" + columnList[1]]} style={{ width: '100%', textAlign: 'center' }} onChange={(e) => handleInputChangeGrid(e.target.value, modulesList[11]['name'] + "_" + columnList[1])}>
                                                        {
                                                            accountAccessOptions.map((val) => (
                                                                <option value={val.value}>{val.label}</option>))
                                                        }
                                                    </select>
                                                </td>
                                            </tr>

                                            {/* Fleet Description */}
                                            <tr>
                                                <td>{modulesList[12]['name']}</td>
                                                <td style={{ verticalAlign: 'middle' }}>
                                                    <select value={grid[removeSpaces(modulesList[12]['name']) + "_" + columnList[0]]} style={{ width: '100%', textAlign: 'center' }} onChange={(e) => handleInputChangeGrid(e.target.value, modulesList[12]['name'] + "_" + columnList[0])}>
                                                        {
                                                            accessSpecifierOptions.map((val) => (
                                                                <option value={val.value}>{val.label}</option>))
                                                        }
                                                    </select>
                                                </td>
                                                <td style={{ verticalAlign: 'middle' }}>
                                                    <select value={grid[removeSpaces(modulesList[12]['name']) + "_" + columnList[1]]} style={{ width: '100%', textAlign: 'center' }} onChange={(e) => handleInputChangeGrid(e.target.value, modulesList[12]['name'] + "_" + columnList[1])}>
                                                        {
                                                            accountAccessOptions.map((val) => (
                                                                <option value={val.value}>{val.label}</option>))
                                                        }
                                                    </select>
                                                </td>
                                            </tr>

                                            {/* One Way Rental */}
                                            <tr>
                                                <td>{modulesList[13]['name']}</td>
                                                <td style={{ verticalAlign: 'middle' }}>
                                                    <select value={grid[removeSpaces(modulesList[13]['name']) + "_" + columnList[0]]} style={{ width: '100%', textAlign: 'center' }} onChange={(e) => handleInputChangeGrid(e.target.value, modulesList[13]['name'] + "_" + columnList[0])}>
                                                        {
                                                            accessSpecifierOptions.map((val) => (
                                                                <option value={val.value}>{val.label}</option>))
                                                        }
                                                    </select>
                                                </td>
                                                <td style={{ verticalAlign: 'middle' }}>
                                                    <select value={grid[removeSpaces(modulesList[13]['name']) + "_" + columnList[1]]} style={{ width: '100%', textAlign: 'center' }} onChange={(e) => handleInputChangeGrid(e.target.value, modulesList[13]['name'] + "_" + columnList[1])}>
                                                        {
                                                            accountAccessOptions.map((val) => (
                                                                <option value={val.value}>{val.label}</option>))
                                                        }
                                                    </select>
                                                </td>
                                            </tr>

                                            {/* Reservation Source */}
                                            <tr>
                                                <td>{modulesList[14]['name']}</td>
                                                <td style={{ verticalAlign: 'middle' }}>
                                                    <select value={grid[removeSpaces(modulesList[14]['name']) + "_" + columnList[0]]} style={{ width: '100%', textAlign: 'center' }} onChange={(e) => handleInputChangeGrid(e.target.value, modulesList[14]['name'] + "_" + columnList[0])}>
                                                        {
                                                            accessSpecifierOptions.map((val) => (
                                                                <option value={val.value}>{val.label}</option>))
                                                        }
                                                    </select>
                                                </td>
                                                <td style={{ verticalAlign: 'middle' }}>
                                                    <select value={grid[removeSpaces(modulesList[14]['name']) + "_" + columnList[1]]} style={{ width: '100%', textAlign: 'center' }} onChange={(e) => handleInputChangeGrid(e.target.value, modulesList[14]['name'] + "_" + columnList[1])}>
                                                        {
                                                            accountAccessOptions.map((val) => (
                                                                <option value={val.value}>{val.label}</option>))
                                                        }
                                                    </select>
                                                </td>
                                            </tr>

                                            {/* Rate Code */}
                                            <tr>
                                                <td>{modulesList[15]['name']}</td>
                                                <td style={{ verticalAlign: 'middle' }}>
                                                    <select value={grid[removeSpaces(modulesList[15]['name']) + "_" + columnList[0]]} style={{ width: '100%', textAlign: 'center' }} onChange={(e) => handleInputChangeGrid(e.target.value, modulesList[15]['name'] + "_" + columnList[0])}>
                                                        {
                                                            accessSpecifierOptions.map((val) => (
                                                                <option value={val.value}>{val.label}</option>))
                                                        }
                                                    </select>
                                                </td>
                                                <td style={{ verticalAlign: 'middle' }}>
                                                    <select value={grid[removeSpaces(modulesList[15]['name']) + "_" + columnList[1]]} style={{ width: '100%', textAlign: 'center' }} onChange={(e) => handleInputChangeGrid(e.target.value, modulesList[15]['name'] + "_" + columnList[1])}>
                                                        {
                                                            accountAccessOptions.map((val) => (
                                                                <option value={val.value}>{val.label}</option>))
                                                        }
                                                    </select>
                                                </td>
                                            </tr>

                                            {/* Account Rate Code */}
                                            <tr>
                                                <td>{modulesList[16]['name']}</td>
                                                <td style={{ verticalAlign: 'middle' }}>
                                                    <select value={grid[removeSpaces(modulesList[16]['name']) + "_" + columnList[0]]} style={{ width: '100%', textAlign: 'center' }} onChange={(e) => handleInputChangeGrid(e.target.value, modulesList[16]['name'] + "_" + columnList[0])}>
                                                        {
                                                            accessSpecifierOptions.map((val) => (
                                                                <option value={val.value}>{val.label}</option>))
                                                        }
                                                    </select>
                                                </td>
                                                <td style={{ verticalAlign: 'middle' }}>
                                                    <select value={grid[removeSpaces(modulesList[16]['name']) + "_" + columnList[1]]} style={{ width: '100%', textAlign: 'center' }} onChange={(e) => handleInputChangeGrid(e.target.value, modulesList[16]['name'] + "_" + columnList[1])}>
                                                        {
                                                            accountAccessOptions.map((val) => (
                                                                <option value={val.value}>{val.label}</option>))
                                                        }
                                                    </select>
                                                </td>
                                            </tr>

                                            {/* Account Rate Rules */}
                                            <tr>
                                                <td>{modulesList[17]['name']}</td>
                                                <td style={{ verticalAlign: 'middle' }}>
                                                    <select value={grid[removeSpaces(modulesList[17]['name']) + "_" + columnList[0]]} style={{ width: '100%', textAlign: 'center' }} onChange={(e) => handleInputChangeGrid(e.target.value, modulesList[17]['name'] + "_" + columnList[0])}>
                                                        {
                                                            accessSpecifierOptions.map((val) => (
                                                                <option value={val.value}>{val.label}</option>))
                                                        }
                                                    </select>
                                                </td>
                                                <td style={{ verticalAlign: 'middle' }}>
                                                    <select value={grid[removeSpaces(modulesList[17]['name']) + "_" + columnList[1]]} style={{ width: '100%', textAlign: 'center' }} onChange={(e) => handleInputChangeGrid(e.target.value, modulesList[17]['name'] + "_" + columnList[1])}>
                                                        {
                                                            accountAccessOptions.map((val) => (
                                                                <option value={val.value}>{val.label}</option>))
                                                        }
                                                    </select>
                                                </td>
                                            </tr>

                                            {/* Rate Download */}
                                            <tr>
                                                <td>{modulesList[18]['name']}</td>
                                                <td style={{ verticalAlign: 'middle' }}>
                                                    <select value={grid[removeSpaces(modulesList[18]['name']) + "_" + columnList[0]]} style={{ width: '100%', textAlign: 'center' }} onChange={(e) => handleInputChangeGrid(e.target.value, modulesList[18]['name'] + "_" + columnList[0])}>
                                                        {
                                                            accessSpecifierOptions.map((val) => (
                                                                <option value={val.value}>{val.label}</option>))
                                                        }
                                                    </select>
                                                </td>
                                                <td style={{ verticalAlign: 'middle' }}>
                                                    <select value={grid[removeSpaces(modulesList[18]['name']) + "_" + columnList[1]]} style={{ width: '100%', textAlign: 'center' }} onChange={(e) => handleInputChangeGrid(e.target.value, modulesList[18]['name'] + "_" + columnList[1])}>
                                                        {
                                                            accountAccessOptions.map((val) => (
                                                                <option value={val.value}>{val.label}</option>))
                                                        }
                                                    </select>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>{modulesList[23]['name']}</td>
                                                <td style={{ verticalAlign: 'middle' }}>
                                                    <select value={grid[removeSpaces(modulesList[23]['name']) + "_" + columnList[0]]} style={{ width: '100%', textAlign: 'center' }} onChange={(e) => handleInputChangeGrid(e.target.value, modulesList[23]['name'] + "_" + columnList[0])}>
                                                        {
                                                            accessSpecifierOptions.map((val) => (
                                                                <option value={val.value}>{val.label}</option>))
                                                        }
                                                    </select>
                                                </td>
                                                <td style={{ verticalAlign: 'middle' }}>
                                                    <select value={grid[removeSpaces(modulesList[23]['name']) + "_" + columnList[1]]} style={{ width: '100%', textAlign: 'center' }} onChange={(e) => handleInputChangeGrid(e.target.value, modulesList[23]['name'] + "_" + columnList[1])}>
                                                        {
                                                            accountAccessOptions.map((val) => (
                                                                <option value={val.value}>{val.label}</option>))
                                                        }
                                                    </select>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>{modulesList[19]['name']}</td>
                                                <td style={{ verticalAlign: 'middle' }}>
                                                    <select value={grid[removeSpaces(modulesList[19]['name']) + "_" + columnList[0]]} style={{ width: '100%', textAlign: 'center' }} onChange={(e) => handleInputChangeGrid(e.target.value, modulesList[19]['name'] + "_" + columnList[0])}>
                                                        {
                                                            accessSpecifierOptions.map((val) => (
                                                                <option value={val.value}>{val.label}</option>))
                                                        }
                                                    </select>
                                                </td>
                                                <td style={{ verticalAlign: 'middle' }}>
                                                    <select value={grid[removeSpaces(modulesList[19]['name']) + "_" + columnList[1]]} style={{ width: '100%', textAlign: 'center' }} onChange={(e) => handleInputChangeGrid(e.target.value, modulesList[19]['name'] + "_" + columnList[1])}>
                                                        {
                                                            accountAccessOptions.map((val) => (
                                                                <option value={val.value}>{val.label}</option>))
                                                        }
                                                    </select>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>{modulesList[21]['name']}</td>
                                                <td style={{ verticalAlign: 'middle' }}>
                                                    <select value={grid[removeSpaces(modulesList[21]['name']) + "_" + columnList[0]]} style={{ width: '100%', textAlign: 'center' }} onChange={(e) => handleInputChangeGrid(e.target.value, modulesList[21]['name'] + "_" + columnList[0])}>
                                                        {
                                                            accessSpecifierOptions.map((val) => (
                                                                <option value={val.value}>{val.label}</option>))
                                                        }
                                                    </select>
                                                </td>
                                                <td style={{ verticalAlign: 'middle' }}>
                                                    <select value={grid[removeSpaces(modulesList[21]['name']) + "_" + columnList[1]]} style={{ width: '100%', textAlign: 'center' }} onChange={(e) => handleInputChangeGrid(e.target.value, modulesList[21]['name'] + "_" + columnList[1])}>
                                                        {
                                                            accountAccessOptions.map((val) => (
                                                                <option value={val.value}>{val.label}</option>))
                                                        }
                                                    </select>
                                                </td>
                                            </tr>
                                            {/* <tr>
                                                <td>{modulesList[24]['name']}</td>
                                                <td style={{ verticalAlign: 'middle' }}>
                                                    <select value={grid[removeSpaces(modulesList[24]['name']) + "_" + columnList[0]]} style={{ width: '100%', textAlign: 'center' }} onChange={(e) => handleInputChangeGrid(e.target.value, modulesList[24]['name'] + "_" + columnList[0])}>
                                                        {
                                                            accessSpecifierOptions.map((val) => (
                                                                <option value={val.value}>{val.label}</option>))
                                                        }
                                                    </select>
                                                </td>
                                                <td style={{ verticalAlign: 'middle' }}>
                                                    <select value={grid[removeSpaces(modulesList[24]['name']) + "_" + columnList[1]]} style={{ width: '100%', textAlign: 'center' }} onChange={(e) => handleInputChangeGrid(e.target.value, modulesList[24]['name'] + "_" + columnList[1])}>
                                                        {
                                                            accountAccessOptions.map((val) => (
                                                                <option value={val.value}>{val.label}</option>))
                                                        }
                                                    </select>
                                                </td>
                                            </tr> */}
                                             {/* Competitor Setup */}
                                            {/* <tr>
                                                <td>{modulesList[13]['name']}</td>
                                                <td style={{ verticalAlign: 'middle' }}>
                                                    <select value={grid[removeSpaces(modulesList[13]['name']) + "_" + columnList[0]]} style={{ width: '100%', textAlign: 'center' }} onChange={(e) => handleInputChangeGrid(e.target.value, modulesList[41]['name'] + "_" + columnList[0])}>
                                                        {
                                                            accessSpecifierOptions.map((val) => (
                                                                <option value={val.value}>{val.label}</option>))
                                                        }
                                                    </select>
                                                </td>
                                                <td style={{ verticalAlign: 'middle' }}>
                                                    <select value={grid[removeSpaces(modulesList[13]['name']) + "_" + columnList[1]]} style={{ width: '100%', textAlign: 'center' }} onChange={(e) => handleInputChangeGrid(e.target.value, modulesList[41]['name'] + "_" + columnList[1])}>
                                                        {
                                                            accountAccessOptions.map((val) => (
                                                                <option value={val.value}>{val.label}</option>))
                                                        }
                                                    </select>
                                                </td>
                                            </tr> */}

                                            {/* Minimum Rate Mapping */}
                                            {/* <tr>
                                                <td>{modulesList[10]['name']}</td>
                                                <td style={{ verticalAlign: 'middle' }}>
                                                    <select value={grid[removeSpaces(modulesList[10]['name']) + "_" + columnList[0]]} style={{ width: '100%', textAlign: 'center' }} onChange={(e) => handleInputChangeGrid(e.target.value, modulesList[42]['name'] + "_" + columnList[0])}>
                                                        {
                                                            accessSpecifierOptions.map((val) => (
                                                                <option value={val.value}>{val.label}</option>))
                                                        }
                                                    </select>
                                                </td>
                                                <td style={{ verticalAlign: 'middle' }}>
                                                    <select value={grid[removeSpaces(modulesList[10]['name']) + "_" + columnList[1]]} style={{ width: '100%', textAlign: 'center' }} onChange={(e) => handleInputChangeGrid(e.target.value, modulesList[42]['name'] + "_" + columnList[1])}>
                                                        {
                                                            accountAccessOptions.map((val) => (
                                                                <option value={val.value}>{val.label}</option>))
                                                        }
                                                    </select>
                                                </td>
                                            </tr> */}

                                            {/* Enable Automation */}
                                            {/* <tr>
                                                <td>{modulesList[12]['name']}</td>
                                                <td style={{ verticalAlign: 'middle' }}>
                                                    <select value={grid[removeSpaces(modulesList[12]['name']) + "_" + columnList[0]]} style={{ width: '100%', textAlign: 'center' }} onChange={(e) => handleInputChangeGrid(e.target.value, modulesList[12]['name'] + "_" + columnList[0])}>
                                                        {
                                                            accessSpecifierOptions.map((val) => (
                                                                <option value={val.value}>{val.label}</option>))
                                                        }
                                                    </select>
                                                </td>
                                                <td style={{ verticalAlign: 'middle' }}>
                                                    <select value={grid[removeSpaces(modulesList[12]['name']) + "_" + columnList[1]]} style={{ width: '100%', textAlign: 'center' }} onChange={(e) => handleInputChangeGrid(e.target.value, modulesList[12]['name'] + "_" + columnList[1])}>
                                                        {
                                                            accountAccessOptions.map((val) => (
                                                                <option value={val.value}>{val.label}</option>))
                                                        }
                                                    </select>
                                                </td>
                                            </tr> */}
                                            
                                            {/* Automation SetUp */}
                                            {/* <tr>
                                                <td>{modulesList[25]['name']}</td>
                                                <td style={{ verticalAlign: 'middle' }}>
                                                    <select value={grid[removeSpaces(modulesList[25]['name']) + "_" + columnList[0]]} style={{ width: '100%', textAlign: 'center' }} onChange={(e) => handleInputChangeGrid(e.target.value, modulesList[25]['name'] + "_" + columnList[0])}>
                                                        {
                                                            accessSpecifierOptions.map((val) => (
                                                                <option value={val.value}>{val.label}</option>))
                                                        }
                                                    </select>
                                                </td>
                                                <td style={{ verticalAlign: 'middle' }}>
                                                    <select value={grid[removeSpaces(modulesList[25]['name']) + "_" + columnList[1]]} style={{ width: '100%', textAlign: 'center' }} onChange={(e) => handleInputChangeGrid(e.target.value, modulesList[25]['name'] + "_" + columnList[1])}>
                                                        {
                                                            accountAccessOptions.map((val) => (
                                                                <option value={val.value}>{val.label}</option>))
                                                        }
                                                    </select>
                                                </td>
                                            </tr> */}
                                            
                                            {/* Currency Converter */}
                                            {/* <tr>
                                                <td>{modulesList[14]['name']}</td>
                                                <td style={{ verticalAlign: 'middle' }}>
                                                    <select value={grid[removeSpaces(modulesList[14]['name']) + "_" + columnList[0]]} style={{ width: '100%', textAlign: 'center' }} onChange={(e) => handleInputChangeGrid(e.target.value, modulesList[14]['name'] + "_" + columnList[0])}>
                                                        {
                                                            accessSpecifierOptions.map((val) => (
                                                                <option value={val.value}>{val.label}</option>))
                                                        }
                                                    </select>
                                                </td>
                                                <td style={{ verticalAlign: 'middle' }}>
                                                    <select value={grid[removeSpaces(modulesList[14]['name']) + "_" + columnList[1]]} style={{ width: '100%', textAlign: 'center' }} onChange={(e) => handleInputChangeGrid(e.target.value, modulesList[14]['name'] + "_" + columnList[1])}>
                                                        {
                                                            accountAccessOptions.map((val) => (
                                                                <option value={val.value}>{val.label}</option>))
                                                        }
                                                    </select>
                                                </td>
                                            </tr> */}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="row pt-2">
                                <div className="col-md-10"></div>
                                <div className="col-md-2">
                                    {
                                        loading ?
                                            <button className="btn btn-success" type="button" disabled>
                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{ marginRight: 5 }}></span>
                                                Submit
                                            </button> :
                                            <button type='submit' className="btn btn-success" >
                                                Submit
                                            </button>
                                    }
                                </div>
                            </div>
                        </form>
                    </> : ''
            }

            {
                listOfRoles.length > 0 ?
                    <>
                        <div className="row pt-5 mx-2">
                            <h5><strong>Existing Roles</strong></h5>
                        </div>

                        <div className="row pt-4 mx-2">
                            <div className="col-md-6">
                                <table className="table table-bordered table-condensed text-center">
                                    <thead>
                                        <tr>
                                            <th scope="col">Sr. No.</th>
                                            <th scope="col">Account Name</th>
                                            <th scope="col">Role Name</th>
                                            <th scope="col">Show For</th>
                                            <th scope="col">Edit</th>
                                        </tr>
                                    </thead>
                                    <tbody className="text-left">
                                        {
                                            listOfRoles.map((val, index) => (
                                                <tr>
                                                    <td>{index + 1}</td>
                                                    <td>{val.account_name}</td>
                                                    <td>{val.role_name}</td>
                                                    <td>{val.show_for}</td>
                                                    <td><button className="btn" onClick={(e) => editRole(index)}><EditIcon /></button></td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </> : ''
            }

        </>
    )
}