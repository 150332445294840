import React, { useState, useEffect } from "react";
import Select from "react-select";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import { Modal } from 'react-bootstrap'
import axios from 'axios';
import Swal from 'sweetalert2';
import { baseURL } from '../backend_url';
import { formatDate, formatDate_withTime } from '../commonFunctions';
import { MultiSelect } from "react-multi-select-component";
// import './loader.css';
import './TaxSetup.css';
// import '../../../../src/App.css';
// import '../../.../.../App.css'


export default function UpdateForm({ accessType }) {

  var temp_company_id = "";
  var temp_location_id = "";
  const [companyDetail, setcompanyDetail] = useState([{}]);
  const [companyName, setCompanyName] = useState("");
  const [company_id, setcompany_id] = useState("");
  const [locationDetail, setLocationDetail] = useState([{}]);
  const [locationName, setLocationName] = useState("");
  const [locationId, setLocationId] = useState("");
  const [lgShow1, setLgShow1] = useState(false);
  const [lgShow2, setLgShow2] = useState(false);
  const [lgShow3, setLgShow3] = useState(false);
  const [independentTaxName, setIndependentTaxName] = useState("");
  const [independentType, setIndependentType] = useState("");
  const [independentCurrency, setIndependentCurrency] = useState("");
  const [independentAmount, setIndependentAmount] = useState("");
  const [baseRateTaxName, setBaseRateTaxName] = useState("");
  const [baseRateType, setBaseRateType] = useState("Per Rental");
  const [baseRatePercentage, setBaseRatePercentage] = useState("");
  const [calculatedTaxName, setCalculatedTaxName] = useState("");
  const [calculatedPercentage, setCalculatedPercentage] = useState("");
  const [updateFlag1, setUpdateFlag1] = useState(false);
  const [updateFlag2, setUpdateFlag2] = useState(false);
  const [updateFlag3, setUpdateFlag3] = useState(false);
  const [independentTaxDetail, setIndependentTaxDetail] = useState([]);
  const [baseRateTaxDetail, setBaseRateTaxDetail] = useState([]);
  const [calculatedTaxDetail, setCalculatedTaxDetail] = useState([]);
  const [updateClicked1, setUpdateClicked1] = useState(false);
  const [updateClicked2, setUpdateClicked2] = useState(false);
  const [updateClicked3, setUpdateClicked3] = useState(false);
  const [selected, setSelected] = useState([]);
  const [allTaxesData, setAllTaxesData] = useState([]);
  const [loggedInUserDetail, setLoggedInUserDetail] = useState({});

  const [independentApplisOn, setIndependentAppliesOn] = useState("");
  const [baseRateApplisOn, setBaseRateAppliesOn] = useState("");
  const [calculatedApplisOn, setCalculatedAppliesOn] = useState("");

  const [taxNameDetail, setTaxNameDetail] = useState([]);
  const [parentAccountID, setParentAccountID] = useState("");


  //API call
  useEffect(() => {
    const loggedInUser = sessionStorage.getItem("user_token");
    const foundUser = JSON.parse(loggedInUser);
    setLoggedInUserDetail(foundUser);
    console.log('foundUser tax',foundUser)

    if (foundUser.role === 'SuperAdmin') {

      axios.get(baseURL + "/get_company_details_for_users")
        .then((res) => {
          setcompanyDetail(res.data);
        });
    }
    else {
      fetch(baseURL + "/get_company_details_for_users_accessWise/" + foundUser.access['FeesandTaxes_Account Access'] + "*" + foundUser.account_id + "*" + foundUser.account_type.account_type)
        .then((res) => res.json())
        .then((data) => {

          setcompanyDetail(data);
        });
    }
  }, []);

  //Trigger on Company Name Dropdown Changes , getting Company ID
  useEffect(() => {

    if (companyDetail && companyName) {

      setIndependentCurrency("");
      setLocationDetail([]);
      setIndependentTaxDetail([]);
      setBaseRateTaxDetail([]);
      setCalculatedTaxDetail([]);
      setLocationName("");
      setLocationId("");
      setIndependentAppliesOn("");
      setBaseRateAppliesOn("");
      setCalculatedAppliesOn("");

      let obj_company_detail = companyDetail.filter(
        (value) => value.company_name === companyName
      )[0];
      let temp_company_id = obj_company_detail._id;

      let company_type = obj_company_detail.account_type;
      let parent_id = obj_company_detail.affiliated_to_id

      setcompany_id(temp_company_id);
      setParentAccountID(parent_id);

      // axios.get(baseURL + "/get_company_location/" + temp_company_id)
      //   .then((res) => {
      //     setLocationDetail(allTemp);
      //     setIndependentTaxDetail([]);
      //     setLocationName("");
      //     setLocationId("");

      //   }).catch((e) => {

      //   });

      //API call for getting data from DB
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      };

      fetch(baseURL + "/get_company_location/" + temp_company_id, requestOptions)
        .then((res) => res.json())
        .then((data) => {

          let allTemp = data.res;


          if (loggedInUserDetail.role === 'SuperAdmin') {
            if (allTemp.length > 0) {
              setLocationDetail(allTemp);
              setIndependentTaxDetail([]);
              setBaseRateTaxDetail([]);
              setCalculatedTaxDetail([]);
              setLocationName("");
              setLocationId("");
            }
          }
          else if (company_type === 'Affiliate' && loggedInUserDetail.account_type.account_type === 'Corporate') {

            if (allTemp.length > 0) {
              setLocationDetail(allTemp);
              setIndependentTaxDetail([]);
              setBaseRateTaxDetail([]);
              setCalculatedTaxDetail([]);
              setLocationName("");
              setLocationId("");
            }
          }
          else {
            axios.post(baseURL + "/get_individual_user_details", { 'user_email': loggedInUserDetail.userEmail })
              .then((data) => {
                if (allTemp.length > 0) {

                  allTemp = allTemp.filter((val) => val !== undefined && data.data[0].location_allocated[val.location_id]);

                  setLocationDetail(allTemp);
                  setIndependentTaxDetail([]);
                  setBaseRateTaxDetail([]);
                  setCalculatedTaxDetail([]);
                  setLocationName("");
                  setLocationId("");

                }

              }
              )
          }
        });



    }
  }, [companyName]);

  useEffect(() => {
    if (locationName && locationDetail) {

      setIndependentTaxName("");
      setIndependentType("");
      setIndependentCurrency("");
      setIndependentAmount("");
      setIndependentTaxDetail([]);
      setBaseRatePercentage("");
      setBaseRateType("");
      setBaseRateTaxName("");
      setBaseRateTaxDetail([]);
      setCalculatedTaxName("");
      setCalculatedPercentage("");
      setCalculatedTaxDetail([]);
      setIndependentAppliesOn("");
      setBaseRateAppliesOn("");
      setCalculatedAppliesOn("");

      temp_location_id = locationDetail.filter((loc) => (loc.location_name === locationName)).map((val) => val.location_id)
      setLocationId(temp_location_id);

      const id = temp_location_id.toString() + "||" + "calculated";
      //api to store data in tax collection of db
      axios.get(baseURL + "/get_tax_setup/" + id).then((res) => {

        setCalculatedTaxDetail(res.data.res);
        //geting all taxes starts
        const allTaxTemp = res.data.all_taxes;
        let tempArr = [];
        tempArr.push({ 'value': 'Base Rate', 'label': 'Base Rate' });
        if (allTaxTemp.length > 0) {
          for (let i = 0; i < allTaxTemp.length; i++) {
            tempArr.push({ 'value': allTaxTemp[i].tax_name, 'label': allTaxTemp[i].tax_name });
          }
        }
        setAllTaxesData(tempArr);
        //geting all taxes ends
      }).catch((e) => { });



      axios.get(baseURL + "/get_location_details/" + temp_location_id)
        .then((res) => {

          setIndependentCurrency(res.data.item.currency);
          setUpdateFlag1(!updateFlag1);
          setUpdateFlag2(!updateFlag2);
          setUpdateFlag3(!updateFlag3);


        }).catch((e) => {

        });
      
      let company_account_id = company_id.toString()
      if(parentAccountID != null){
        company_account_id = parentAccountID.toString()
      }
      
      axios.get(baseURL + "/get_optional_services_standard/" + company_account_id.toString())
        .then((res) => {
          setTaxNameDetail(res.data);
        });
    }
  }, [locationName])

  //Option for company name
  const companyNameOptions = companyDetail.map((value) => (

    { value: value.company_name, label: value.company_name }
  ));

  const handleInputChangeCompanyName = characterEntered => {
    setCompanyName(characterEntered.value);
  };

  //Option for Tax name
  const TaxNameOptions = taxNameDetail.filter((val) => val.extraTypeFor === 'Tax').map((value) => (

    { value: value.extraCategory, label: value.extraCategory }
  ));

  const handleInputChangeIndependentTaxName = characterEntered => {
    setIndependentTaxName(characterEntered);
  };
  const handleInputChangeBaseRateTaxName = characterEntered => {
    setBaseRateTaxName(characterEntered);
  };
  const handleInputChangeCalculatedTaxName = characterEntered => {
    setCalculatedTaxName(characterEntered);
  };


  //Option for Location name
  const locationNameOptions = locationDetail.map((value) => (

    { value: value.location_name, 
      label: value.location_name != undefined && value.location_name + " - " + value.location_id.split("-")[1] 
    }
  ));

  const handleInputChangeLocationName = characterEntered => {
    setLocationName(characterEntered.value);
  };

  const handleSubmit1 = (e) => {
    e.preventDefault();

    if (companyName === "") {
      Swal.fire('Please select an account to proceed ahead');
      return;
    }
    if (locationName === "") {
      Swal.fire('Please select a location to proceed ahead');
      return;
    }

    if (independentTaxName == "") {
      Swal.fire('Please enter tax name to proceed ahead');
      return;
    }
    if (independentType === "") {
      Swal.fire('Please select Per Day or Per Rental option to proceed ahead');
      return;
    }
    if (independentCurrency === "") {
      Swal.fire('Currency can\'t be enpty');
      return;
    }
    if (independentAmount === "") {
      Swal.fire('Please enter amount to proceed ahead');
      return;
    }

    if (isNaN(independentAmount)) {
      Swal.fire({
        position: 'middle',
        icon: 'error',
        title: 'Please enter number in amount field',
        showConfirmButton: false,
        timer: 3000
      })
      setIndependentAmount("");
      return;
    }

    if (independentApplisOn === '') {
      Swal.fire({
        position: 'middle',
        icon: 'error',
        title: 'Please select an option of Applies On field',
        showConfirmButton: false,
        timer: 3000
      })
      return;
    }

    Swal.fire({
      title: 'Do you want to add this tax ?',

      showCancelButton: true,
      confirmButtonText: 'Yes',


    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {

        const requestBody = {
          '_id': locationId + "-" + independentTaxName + "-" + "independent",
          'tax_name': independentTaxName,
          'location_id': locationId.toString(),
          'account_id': company_id.toString(),
          'tax_type': 'independent',
          'currency': independentCurrency,
          'per_day_per_rental': independentType,
          'amount': independentAmount,
          'applies_on': independentApplisOn,
          'created_date': new Date(),
          'updated_date': new Date(),
          'created_by': loggedInUserDetail.userEmail,
          'updated_by': loggedInUserDetail.userEmail
        }
        //api to store data in tax collection of db
        axios.post(baseURL + "/save_tax_setup", requestBody).then((res) => {

          setLgShow1(false);

          if (res.data === 'Already Exist') {
            Swal.fire({
              position: 'middle',
              icon: 'error',
              title: 'Tax Name Already Exists',
              showConfirmButton: false,
              timer: 3000
            })
          }
          else {
            Swal.fire({
              position: 'middle',
              icon: 'success',
              title: 'Tax saved successfully',
              showConfirmButton: false,
              timer: 3000
            })
            setUpdateFlag1(!updateFlag1);
            setIndependentTaxName("");
            setIndependentType("");
            setIndependentAmount("");
          }
        })

      }
    })



  }

  const handleSubmit2 = (e) => {
    e.preventDefault();
    if (companyName == "") {
      Swal.fire('Please select an account to proceed ahead');
      return;
    }
    if (locationName == "") {
      Swal.fire('Please select a location to proceed ahead');
      return;
    }

    if (baseRateTaxName == "") {
      Swal.fire('Please enter tax name to proceed ahead');
      return;
    }
    if (baseRateType == "") {
      Swal.fire('Please select Per Day or Per Rental option to proceed ahead');
      return;
    }
    if (baseRatePercentage == "") {
      Swal.fire('Please enter percentage to proceed ahead');
      return;
    }
    if (isNaN(baseRatePercentage)) {
      Swal.fire({
        position: 'middle',
        icon: 'error',
        title: 'Please enter number in amount field',
        showConfirmButton: false,
        timer: 3000
      })
      setBaseRatePercentage("");
      return;
    }
    if (baseRateApplisOn === '') {
      Swal.fire({
        position: 'middle',
        icon: 'error',
        title: 'Please select an option of Applies On field',
        showConfirmButton: false,
        timer: 3000
      })
      return;
    }

    Swal.fire({
      title: 'Do you want to add this tax ?',

      showCancelButton: true,
      confirmButtonText: 'Yes',


    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {

        const requestBody = {
          '_id': locationId + "-" + baseRateTaxName + "-" + "baserate",
          'tax_name': baseRateTaxName,
          'location_id': locationId.toString(),
          'account_id': company_id.toString(),
          'tax_type': 'baserate',
          'per_day_per_rental': baseRateType,
          'percentage': baseRatePercentage,
          'applies_on': baseRateApplisOn,
          'created_date': new Date(),
          'updated_date': new Date(),
          'created_by': loggedInUserDetail.userEmail,
          'updated_by': loggedInUserDetail.userEmail
        }
        //api to store data in tax collection of db
        axios.post(baseURL + "/save_tax_setup", requestBody).then((res) => {

          setLgShow2(false);

          if (res.data === 'Already Exist') {
            Swal.fire({
              position: 'middle',
              icon: 'error',
              title: 'Tax Name Already Exists',
              showConfirmButton: false,
              timer: 3000
            })
          }
          else {
            Swal.fire({
              position: 'middle',
              icon: 'success',
              title: 'Tax saved successfully',
              showConfirmButton: false,
              timer: 3000
            })
            setUpdateFlag2(!updateFlag2);
            setBaseRateTaxName("");
            setBaseRateType("Per Rental");
            setBaseRatePercentage("");
          }


        })
      }
    })

  }

  //handle submit Calculated Tax start
  const handleSubmit3 = (e) => {

    e.preventDefault();
    if (companyName == "") {
      Swal.fire('Please select an account to proceed ahead');
      return;
    }
    if (locationName == "") {
      Swal.fire('Please select a location to proceed ahead');
      return;
    }

    if (calculatedTaxName == "") {
      Swal.fire('Please enter tax name to proceed ahead');
      return;
    }

    if (calculatedPercentage == "") {
      Swal.fire('Please enter percentage to proceed ahead');
      return;
    }
    if (selected.length === 0) {
      Swal.fire('Please select applied taxes to proceed ahead');
      return;
    }
    if (isNaN(calculatedPercentage)) {
      Swal.fire({
        position: 'middle',
        icon: 'error',
        title: 'Please enter number in amount field',
        showConfirmButton: false,
        timer: 3000
      })
      setCalculatedPercentage("");
      return;
    }
    if (calculatedApplisOn === '') {
      Swal.fire({
        position: 'middle',
        icon: 'error',
        title: 'Please select an option of Applies On field',
        showConfirmButton: false,
        timer: 3000
      })
      return;
    }

    Swal.fire({
      title: 'Do you want to add this tax ?',

      showCancelButton: true,
      confirmButtonText: 'Yes',


    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {

        const requestBody = {
          '_id': locationId + "-" + calculatedTaxName + "-" + "calculated",
          'tax_name': calculatedTaxName,
          'location_id': locationId.toString(),
          'account_id': company_id.toString(),
          'tax_type': 'calculated',
          'percentage': calculatedPercentage,
          'applied_taxes': selected,
          'applies_on': calculatedApplisOn,
          'created_date': new Date(),
          'updated_date': new Date(),
          'created_by': loggedInUserDetail.userEmail,
          'updated_by': loggedInUserDetail.userEmail
        }
        //api to store data in tax collection of db
        axios.post(baseURL + "/save_tax_setup", requestBody).then((res) => {

          setLgShow3(false);

          if (res.data === 'Already Exist') {
            Swal.fire({
              position: 'middle',
              icon: 'error',
              title: 'Tax Name Already Exists',
              showConfirmButton: false,
              timer: 3000
            })
          }
          else {
            Swal.fire({
              position: 'middle',
              icon: 'success',
              title: 'Tax saved successfully',
              showConfirmButton: false,
              timer: 3000
            })
            setUpdateFlag3(!updateFlag3);
            setCalculatedTaxName("");
            setCalculatedPercentage("");
            setSelected([]);
          }
        })
      }
    })

  }//handle submit Calculated Tax ends

  //Validation for only number value in amount field
  useEffect(() => {
    if (isNaN(independentAmount) || isNaN(baseRatePercentage) || isNaN(calculatedPercentage)
      || independentAmount < 0 || baseRatePercentage < 0 || calculatedPercentage < 0) {
      Swal.fire({
        position: 'middle',
        icon: 'error',
        title: 'Please enter number in amount field',
        showConfirmButton: false,
        timer: 3000
      })

      setIndependentAmount("");
      setBaseRatePercentage("");
      setCalculatedPercentage("");
    }

  }, [independentAmount, baseRatePercentage, calculatedPercentage])



  //Updating Fee -> Table 1 current instance from db
  useEffect(() => {

    const id = locationId.toString() + "||" + "independent";
    //api to store data in tax collection of db
    axios.get(baseURL + "/get_tax_setup/" + id).then((res) => {

      setIndependentTaxDetail(res.data.res);
      //geting all taxes starts
      const allTaxTemp = res.data.all_taxes;
      let tempArr = [];
      if (allTaxTemp.length > 0) {

        tempArr.push({ 'value': 'Base Rate', 'label': 'Base Rate' });
        for (let i = 0; i < allTaxTemp.length; i++) {
          tempArr.push({ 'value': allTaxTemp[i].tax_name, 'label': allTaxTemp[i].tax_name });
        }

      }

      setAllTaxesData(tempArr);
      setIndependentAppliesOn("");
      //geting all taxes ends
    }).catch((e) => { });

  }, [updateFlag1])

  //Updating State Tax -> Table 2 current instance from db
  useEffect(() => {

    const id = locationId.toString() + "||" + "baserate";
    //api to store data in tax collection of db
    axios.get(baseURL + "/get_tax_setup/" + id).then((res) => {

      setBaseRateTaxDetail(res.data.res);

      //geting all taxes starts
      const allTaxTemp = res.data.all_taxes;
      let tempArr = [];
      if (allTaxTemp.length > 0) {

        tempArr.push({ 'value': 'Base Rate', 'label': 'Base Rate' });
        for (let i = 0; i < allTaxTemp.length; i++) {
          tempArr.push({ 'value': allTaxTemp[i].tax_name, 'label': allTaxTemp[i].tax_name });
        }

      }
      setAllTaxesData(tempArr);
      setBaseRateAppliesOn("");
      //geting all taxes ends

    }).catch((e) => { });

  }, [updateFlag2])

  //Updating Compound Tax -> Table 3 current instance from db
  useEffect(() => {

    const id = locationId.toString() + "||" + "calculated";
    //api to store data in tax collection of db
    axios.get(baseURL + "/get_tax_setup/" + id).then((res) => {

      setCalculatedTaxDetail(res.data.res);
      //geting all taxes starts
      const allTaxTemp = res.data.all_taxes;
      let tempArr = [];
      tempArr.push({ 'value': 'Base Rate', 'label': 'Base Rate' });
      if (allTaxTemp.length > 0) {
        for (let i = 0; i < allTaxTemp.length; i++) {
          tempArr.push({ 'value': allTaxTemp[i].tax_name, 'label': allTaxTemp[i].tax_name });
        }
      }
      setAllTaxesData(tempArr);
      setCalculatedAppliesOn("");
      //geting all taxes ends
    }).catch((e) => { });

  }, [updateFlag3])


  //Handle Delete Independent Tax
  const handleDelete1 = (id) => {
    Swal.fire({
      title: 'Do you want to delete this tax ?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed) {

        axios.post(baseURL + "/delete_tax_setup", {
          "_id": id,
          "updated_date": new Date(),
          "updated_by": loggedInUserDetail.userEmail
        })
          .then((res1) => {

            if (res1.data.toString().indexOf('trying to delete') > -1) {

              Swal.fire({
                position: 'middle',
                icon: 'error',
                text: res1.data.toString(),
                showConfirmButton: false,
                timer: 5000
              })

            } else {
              Swal.fire({
                position: 'middle',
                icon: 'success',
                title: 'Tax deleted successfully',
                showConfirmButton: false,
                timer: 3000
              })
              setUpdateFlag1(!updateFlag1)
            }
          }).catch((e) => { });


      }
    })
  }

  //Handle Delete Base Rate Tax
  const handleDelete2 = (id) => {
    Swal.fire({
      title: 'Do you want to delete this tax ?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed) {

        axios.post(baseURL + "/delete_tax_setup", {
          "_id": id,
          "updated_date": new Date(),
          "updated_by": loggedInUserDetail.userEmail
        })
          .then((res1) => {

            if (res1.data.toString().indexOf('trying to delete') > -1) {

              Swal.fire({
                position: 'middle',
                icon: 'error',
                text: res1.data.toString(),
                showConfirmButton: false,
                timer: 5000
              })

            } else {
              Swal.fire({
                position: 'middle',
                icon: 'success',
                title: 'Tax deleted successfully',
                showConfirmButton: false,
                timer: 3000
              })
              setUpdateFlag2(!updateFlag2)
            }

          }).catch((e) => { });


      }
    })
  }

  //Handle Delete Calculated Tax
  const handleDelete3 = (id) => {
    Swal.fire({
      title: 'Do you want to delete this tax ?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed) {


        axios.post(baseURL + "/delete_tax_setup", {
          "_id": id,
          "updated_date": new Date(),
          "updated_by": loggedInUserDetail.userEmail
        })
          .then((res1) => {
            if (res1.data.toString().indexOf('trying to delete') > -1) {

              Swal.fire({
                position: 'middle',
                icon: 'error',
                text: res1.data.toString(),
                showConfirmButton: false,
                timer: 5000
              })

            } else {
              Swal.fire({
                position: 'middle',
                icon: 'success',
                title: 'Tax deleted successfully',
                showConfirmButton: false,
                timer: 3000
              })
              setUpdateFlag3(!updateFlag3)
            }
          }).catch((e) => { });


      }
    })
  }

  //Handle Update Independent Tax
  const handleUpdateDB1 = (e) => {

    e.preventDefault();
    if (companyName == "") {
      Swal.fire('Please select an account to proceed ahead');
      return;
    }
    if (locationName == "") {
      Swal.fire('Please select a location to proceed ahead');
      return;
    }

    if (independentTaxName == "") {
      Swal.fire('Please enter tax name to proceed ahead');
      return;
    }
    if (independentType == "") {
      Swal.fire('Please select Per Day or Per Rental option to proceed ahead');
      return;
    }
    if (independentCurrency == "") {
      Swal.fire('Currency can\'t be enpty');
      return;
    }
    if (independentAmount == "") {
      Swal.fire('Please enter amount to proceed ahead');
      return;
    }
    if (independentApplisOn === '') {
      Swal.fire({
        position: 'middle',
        icon: 'error',
        title: 'Please select an option of Applies On field',
        showConfirmButton: false,
        timer: 3000
      })
      return;
    }

    const id = locationId + "-" + independentTaxName + "-" + "independent";
    Swal.fire({
      title: 'Do you want to save these changes ?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed) {

        axios.put(baseURL + "/update_tax_setup/" + id, {
          'per_day_per_rental': independentType,
          'amount': independentAmount,
          'applies_on': independentApplisOn,
          'updated_by': loggedInUserDetail.userEmail,
          'updated_date': new Date(),
        })
          .then((res1) => {

            Swal.fire({
              position: 'middle',
              icon: 'success',
              title: 'Tax Updated Successfully',
              showConfirmButton: false,
              timer: 3000
            })
            setLgShow1(false);
            setUpdateFlag1(!updateFlag1);
          }).catch((e) => { });


      }
    })
  }
  //Handle Update Base Rate Tax
  const handleUpdateDB2 = (e) => {

    e.preventDefault();
    if (companyName == "") {
      Swal.fire('Please select an account to proceed ahead');
      return;
    }
    if (locationName == "") {
      Swal.fire('Please select a location to proceed ahead');
      return;
    }

    if (baseRateTaxName == "") {
      Swal.fire('Please enter tax name to proceed ahead');
      return;
    }
    if (baseRateType == "") {
      Swal.fire('Please select Per Day or Per Rental option to proceed ahead');
      return;
    }
    if (baseRatePercentage == "") {
      Swal.fire('Please enter percentage to proceed ahead');
      return;
    }
    if (baseRateApplisOn === '') {
      Swal.fire({
        position: 'middle',
        icon: 'error',
        title: 'Please select an option of Applies On field',
        showConfirmButton: false,
        timer: 3000
      })
      return;
    }
    const id = locationId + "-" + baseRateTaxName + "-" + "baserate";
    Swal.fire({
      title: 'Do you want to save these changes ?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed) {

        axios.put(baseURL + "/update_tax_setup/" + id, {
          'per_day_per_rental': baseRateType,
          'percentage': baseRatePercentage,
          'applies_on': baseRateApplisOn,
          'updated_by': loggedInUserDetail.userEmail,
          'updated_date': new Date(),
        })
          .then((res1) => {
            Swal.fire({
              position: 'middle',
              icon: 'success',
              title: 'Tax Updated Successfully',
              showConfirmButton: false,
              timer: 3000
            })
            setLgShow2(false);
            setUpdateFlag2(!updateFlag2);
          }).catch((e) => { });


      }
    })
  }
  //Handle Update Calculated Tax
  const handleUpdateDB3 = (e) => {
    e.preventDefault();
    if (companyName == "") {
      Swal.fire('Please select an account to proceed ahead');
      return;
    }
    if (locationName == "") {
      Swal.fire('Please select a location to proceed ahead');
      return;
    }

    if (calculatedTaxName == "") {
      Swal.fire('Please enter tax name to proceed ahead');
      return;
    }

    if (calculatedPercentage == "") {
      Swal.fire('Please enter percentage to proceed ahead');
      return;
    }
    if (selected.length === 0) {
      Swal.fire('Please select applied taxes to proceed ahead');
      return;
    }
    if (calculatedApplisOn === '') {
      Swal.fire({
        position: 'middle',
        icon: 'error',
        title: 'Please select an option of Applies On field',
        showConfirmButton: false,
        timer: 3000
      })
      return;
    }
    const id = locationId + "-" + calculatedTaxName + "-" + "calculated";
    Swal.fire({
      title: 'Do you want to save these changes ?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed) {

        axios.put(baseURL + "/update_tax_setup/" + id, {
          'applied_taxes': selected,
          'percentage': calculatedPercentage,
          'applies_on': calculatedApplisOn,
          'updated_by': loggedInUserDetail.userEmail,
          'updated_date': new Date(),
        })
          .then((res1) => {
            Swal.fire({
              position: 'middle',
              icon: 'success',
              title: 'Tax Updated Successfully',
              showConfirmButton: false,
              timer: 3000
            })
            setLgShow3(false);
            setUpdateFlag3(!updateFlag3);
          }).catch((e) => { });


      }
    })
  }

  //Handle Update Independent Tax
  const handleUpdate1 = (val) => {

    setIndependentTaxName(val.tax_name);
    setIndependentAmount(val.amount);
    setIndependentCurrency(val.currency);
    setIndependentType(val.per_day_per_rental);
    setIndependentAppliesOn(val.applies_on)

    setLgShow1(true);
    setUpdateClicked1(true);
  }

  //Handle Update Base Rate Tax
  const handleUpdate2 = (val) => {

    setBaseRateTaxName(val.tax_name);
    setBaseRateType(val.per_day_per_rental);
    setBaseRatePercentage(val.percentage);
    setBaseRateAppliesOn(val.applies_on)


    setLgShow2(true);
    setUpdateClicked2(true);
  }
  //Handle Update Base Rate Tax
  const handleUpdate3 = (val) => {

    setCalculatedTaxName(val.tax_name);
    setCalculatedPercentage(val.percentage);
    setSelected(val.applied_taxes);
    setCalculatedAppliesOn(val.applies_on)

    setLgShow3(true);
    setUpdateClicked3(true);
  }

  useEffect(() => {

    if (lgShow1 === false) {
      setUpdateClicked1(false);
    }
    if (updateClicked1 === false) {
      setIndependentTaxName("");
      setIndependentAmount("");
      setIndependentType("");
    }
  }, [lgShow1])

  useEffect(() => {

    if (lgShow2 === false) {
      setUpdateClicked2(false);
    }
    if (updateClicked2 === false) {
      setBaseRateTaxName("");
      setBaseRatePercentage("");
      setBaseRateType("Per Rental");
    }
  }, [lgShow2])

  useEffect(() => {

    if (lgShow3 === false) {
      setUpdateClicked3(false);
    }
    if (updateClicked3 === false) {
      setCalculatedTaxName("");
      setCalculatedPercentage("");
      setSelected([]);
    }
  }, [lgShow3])


  return (
    <>
      <div className="row pt-2" style={{marginLeft:'-32px',fontSize:'12px'}}>
        <div className="col-8 mx-3">
          <span style={{ color: '#1E1E1E', fontSize: '21px' }}>
            {accessType === "View" ? "Select account and location to view tax: " : "Select account and location to proceed ahead: "}
          </span>
        </div>
      </div>

      <div className="row pt-5"  style={{backgroundColor:'#cfe2f3', marginLeft:'-32px',fontSize:'12px'}}>
        {/* <div className="col-1"></div> */}
        <div className="col-10 mx-3">
          {/* First Row */}
          <div className="row">
            <div className="col-md-2 mb-3">
              <label htmlFor="company_name" className="form-label">
                <b>Account Name</b>
              </label>
            </div>
            <div className="col-md-4 mb-3">
              <Select
                value={{ value: companyName, label: (companyName ? companyName : "Select Account Name") }}
                inputId="company_name"
                name="company_name"
                options={companyNameOptions}
                onChange={handleInputChangeCompanyName}
              />
            </div>
            <div className="col-md-1 mb-3"></div>
            <div className="col-md-2 mb-3">
              {/* <label htmlFor="company_id" className="form-label">
                Account ID
              </label> */}
            </div>
            <div className="col-md-2 mb-3">
              {/* <input
                type="text"
                className="form-control"
                value={company_id}
                id="company_id"
                disabled
              /> */}
            </div>
            <div className="col-md-2 mb-3">
              <label htmlFor="location_name" className="form-label">
                <b>Location Name</b>
              </label>
            </div>
            <div className="col-md-4 mb-3">
              <Select
                value={{ value: locationName, label: (locationName ? locationName : "Select Location Name") }}
                inputId="location_name"
                name="location_name"
                options={locationNameOptions}
                onChange={handleInputChangeLocationName}
              />
            </div>
            <div className="col-md-1 mb-3"></div>
            <div className="col-md-2 mb-3">
              {/* <label htmlFor="company_id" className="form-label">
                Location ID
              </label> */}
            </div>
            <div className="col-md-2 mb-3">
              {/* <input
                type="text"
                className="form-control"
                value={locationId}
                id="location_id"
                disabled
              /> */}
            </div>
          </div>
          {/* Second Row */}
          {locationName ? (<>
            {/* existing Tax table 1 starts */}
            <div className="row pt-4">
              <div className="col-md-12 mx-1" style={{fontSize:'12px'}}>
                <h5 className="mb-3"  style={{fontSize:'22px'}}> <b>Fees</b></h5>
                <table className="table">
                  <thead>
                    <tr> 
                      <th style= {{backgroundColor:'orange'}} scope="col">Tax Name</th>
                      <th style= {{backgroundColor:'orange'}} scope="col">Per Day / Per Rental</th>
                      <th style= {{backgroundColor:'orange'}} scope="col">Currency</th>
                      <th style= {{backgroundColor:'orange'}} scope="col">Amount</th>
                      <th style= {{backgroundColor:'orange'}} scope="col">Applies On</th>
                      <th style= {{backgroundColor:'orange'}} scope="col">Last Updated By</th>
                      <th style= {{backgroundColor:'orange'}} scope="col">Last Updated Date</th>
                      {!(accessType === "View") &&
                        <>
                          <th style= {{backgroundColor:'orange'}} scope="col">Edit</th>
                          <th style= {{backgroundColor:'orange'}} scope="col">Delete</th>
                        </>
                      }
                    </tr>
                  </thead>
                  <tbody>
                    {independentTaxDetail.length > 0 ? independentTaxDetail.map((val, i) => (
                      <tr key={i}>
                        <td>{val.tax_name}</td>
                        <td>{val.per_day_per_rental}</td>
                        <td>{val.currency}</td>
                        <td>{val.amount}</td>
                        <td>{val.applies_on}</td>
                        <td>{val.updated_by}</td>
                        <td>{formatDate_withTime(new Date(val.updated_date))}</td>
                        {
                          !(accessType === "View") &&
                          <>
                            <td><span style={{ cursor: "pointer" }} onClick={() => handleUpdate1(val)}><EditIcon /></span></td>
                            <td><span style={{ cursor: "pointer" }} onClick={() => handleDelete1(val._id)}><DeleteForeverIcon /></span></td>

                          </>
                        }
                      </tr>
                    )) : (
                      <tr>
                        <td colSpan="9">No tax present for selected location</td>
                      </tr>
                    )}
                  </tbody>
                </table>
                {!(accessType === "View") && <button type="submit" style={{fontSize:'12px'}} className="btn btn-success" onClick={() => setLgShow1(true)} >
                  Add New
                </button>}
                <Modal
                  size="lg"
                  show={lgShow1}
                  onHide={() => setLgShow1(false)}
                  aria-labelledby="example-modal-sizes-title-lg"
                  dialogClassName="modal-90w"
                >
                  <Modal.Header style= {{backgroundColor:'orange'}} closeButton >
                    <Modal.Title id="example-modal-sizes-title-lg" >
                      <b>Fees</b>
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body style= {{backgroundColor:'#cfe2f3'}}>
                    <form style={{fontSize:'12px'}}>
                      <table className="table table-borderless">
                        <thead>
                          <tr>
                            <th style= {{backgroundColor:'#cfe2f3'}} scope="col">Tax Name*</th>
                            <th style= {{backgroundColor:'#cfe2f3'}} scope="col">Per Day / Per Rental*</th>
                            <th style= {{backgroundColor:'#cfe2f3'}} scope="col">Currency*</th>
                            <th style= {{backgroundColor:'#cfe2f3'}} scope="col">Amount*</th>
                            <th style= {{backgroundColor:'#cfe2f3'}} scope="col">Applies On*</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td style= {{backgroundColor:'#cfe2f3'}}>
                            {/* <td style= {{backgroundColor:'#cfe2f3'}}> */}
                              {/* <input
                                type="text"
                                className="form-control"
                                value={independentTaxName}
                                onChange={(e) => setIndependentTaxName(e.target.value)}
                                id="tax_name"
                                disabled={updateClicked1 ? true : false}
                              /> */}
                              {/* <Select

                                value={{ value: independentTaxName, label: (independentTaxName ? independentTaxName : "Select Tax Name") }}
                                inputId="tax_name"
                                name="tax_name"
                                options={TaxNameOptions}
                                onChange={handleInputChangeIndependentTaxName}
                                isDisabled={updateClicked1 ? true : false}
                              /> */}
                              <input
                                type="text"
                                className="form-control"
                                value={independentTaxName}
                                onChange={(e) => handleInputChangeIndependentTaxName(e.target.value)}
                                id="tax_name"
                                disabled={updateClicked1 ? true : false}
                              />
                            </td>
                            <td style= {{backgroundColor:'#cfe2f3'}}>
                              <select className="form-select" value={independentType} onChange={(e) => setIndependentType(e.target.value)}>
                                <option hidden>Select one</option>
                                <option value="Per Day">Per Day</option>
                                <option value="Per Rental">Per Rental</option>
                              </select>
                            </td>
                            <td style= {{backgroundColor:'#cfe2f3'}}>
                              <input
                                type="text"
                                className="form-control"
                                value={independentCurrency}
                                id="currency_name"
                                disabled
                              />
                            </td>
                            <td style= {{backgroundColor:'#cfe2f3'}}>
                              <input
                                type="text"
                                className="form-control"
                                value={independentAmount}
                                onChange={(e) => setIndependentAmount(e.target.value)}
                                id="Location_name"
                              />
                            </td>
                            <td style= {{backgroundColor:'#cfe2f3'}}>
                              <select className="form-select" value={independentApplisOn} onChange={(e) => setIndependentAppliesOn(e.target.value)}>
                                <option hidden>Select one</option>
                                <option value="Only General">Only General</option>
                                <option value="Only Government">Only Government</option>
                                <option value="Both General & Government">Both General & Government</option>
                              </select>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div className="row pt-2">
                        <div className="col-10 d-flex-wrap"></div>
                        <div className="col-2 d-flex justify-content-end">
                          {
                            updateClicked1 ? (
                              <button className="btn btn-success" style={{fontSize:'12px'}} onClick={(e) => handleUpdateDB1(e)}>
                                Update
                              </button>
                            ) :
                              (
                                <button className="btn btn-success" style={{fontSize:'12px'}} onClick={(e) => handleSubmit1(e)}>
                                  Save
                                </button>
                              )
                          }
                        </div>
                      </div>
                    </form>
                  </Modal.Body>
                </Modal>
              </div>
            </div>
            {/* existing Tax table 2 starts */}
            <div className="row pt-5">
              <div className="col-md-12 mx-1"  style={{fontSize:'12px'}}>
                <h5 className=" mb-3"  style={{fontSize:'22px'}}><b>State Tax</b></h5>
                <table className="table">
                  <thead>
                    <tr>
                      <th style= {{backgroundColor:'orange'}} scope="col">Tax Name</th>
                      <th style= {{backgroundColor:'orange'}} scope="col">Per Rental</th>
                      <th style= {{backgroundColor:'orange'}} scope="col">Percentage (%)</th>
                      <th style= {{backgroundColor:'orange'}} scope="col">Applies On</th>
                      <th style= {{backgroundColor:'orange'}} scope="col">Last Updated By</th>
                      <th style= {{backgroundColor:'orange'}} scope="col">Last Updated Date</th>
                      {!(accessType === "View") &&
                        <>
                          <th style= {{backgroundColor:'orange'}} scope="col">Edit</th>
                          <th style= {{backgroundColor:'orange'}} scope="col">Delete</th>
                        </>
                      }
                    </tr>
                  </thead>
                  <tbody>
                    {baseRateTaxDetail.length > 0 ? baseRateTaxDetail.map((val) => (
                      <tr>
                        <td>{val.tax_name}</td>
                        <td>{val.per_day_per_rental}</td>
                        <td>{val.percentage}</td>
                        <td>{val.applies_on}</td>
                        <td>{val.updated_by}</td>
                        <td>{formatDate_withTime(new Date(val.updated_date))}</td>
                        {
                          !(accessType === "View") &&
                          <>
                            <td><span style={{ cursor: "pointer" }} onClick={() => handleUpdate2(val)}><EditIcon /></span></td>
                            <td><span style={{ cursor: "pointer" }} onClick={() => handleDelete2(val._id)}><DeleteForeverIcon /></span></td>

                          </>
                        }
                      </tr>
                    ))
                      : (
                        <tr>
                          <td colSpan="8">No tax present for selected location</td>
                        </tr>
                      )}
                  </tbody>
                </table>
                {!(accessType === "View") && <button type="submit" style={{fontSize:'12px'}} className="btn btn-success" onClick={() => setLgShow2(true)} >
                  Add New
                </button>}
                <Modal
                  size="lg"
                  show={lgShow2}
                  onHide={() => setLgShow2(false)}
                  aria-labelledby="example-modal-sizes-title-lg"
                  dialogClassName="modal-90w"
                >
                  <Modal.Header style= {{backgroundColor:'orange'}} closeButton >
                    <Modal.Title id="example-modal-sizes-title-lg" >
                      State Tax
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body style= {{backgroundColor:'#cfe2f3'}}>
                    <form style={{fontSize:'12px'}}>
                      <table className="table table-borderless">
                        <thead>
                          <tr>
                            <th style= {{backgroundColor:'#cfe2f3'}} scope="col">Tax Name*</th>
                            <th style= {{backgroundColor:'#cfe2f3'}} scope="col">Per Rental*</th>
                            <th style= {{backgroundColor:'#cfe2f3'}} scope="col">Percentage (%)*</th>
                            <th style= {{backgroundColor:'#cfe2f3'}} scope="col">Applies On*</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td style= {{backgroundColor:'#cfe2f3'}}>
                              <input
                                type="text"
                                className="form-control"
                                value={baseRateTaxName}
                                onChange={(e) => handleInputChangeBaseRateTaxName(e.target.value)}
                                isDisabled={updateClicked2 ? true : false}
                                id="tax_name"
                              />
                            </td>
                            <td style= {{backgroundColor:'#cfe2f3'}}>
                              <input
                                type="text"
                                className="form-control"
                                value={baseRateType}
                                onChange={(e) => setBaseRateType(e.target.value)}
                                disabled
                              />
                            </td>
                            <td style= {{backgroundColor:'#cfe2f3'}}>
                              <input
                                type="text"
                                className="form-control"
                                value={baseRatePercentage}
                                onChange={(e) => setBaseRatePercentage(e.target.value)}
                                id="Location_name"
                              />
                            </td>
                            <td style= {{backgroundColor:'#cfe2f3'}}>
                              <select className="form-select" value={baseRateApplisOn} onChange={(e) => setBaseRateAppliesOn(e.target.value)}>
                                <option hidden>Select one</option>
                                <option value="Only General">Only General</option>
                                <option value="Only Government">Only Government</option>
                                <option value="Both General & Government">Both General & Government</option>
                              </select>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div className="row pt-2">
                        <div className="col-10 d-flex-wrap"></div>
                        <div className="col-2 d-flex justify-content-end">
                          {
                            updateClicked2 ? (
                              <button className="btn btn-success" style={{fontSize:'12px'}} onClick={(e) => handleUpdateDB2(e)}>
                                Update
                              </button>
                            ) :
                              (
                                <button className="btn btn-success" style={{fontSize:'12px'}} onClick={(e) => handleSubmit2(e)}>
                                  Save
                                </button>
                              )
                          }
                        </div>
                      </div>
                    </form>
                  </Modal.Body>
                </Modal>
              </div>
            </div>
            {/* existing Tax table 3 starts */}
            <div className="row pt-5">
              <div className="col-md-12 mx-1" style={{fontSize:'12px'}}>
                <h5 className="mb-3" style={{fontSize:'22px'}}><b>Compound Tax</b></h5>
                <table className="table">
                  <thead>
                    <tr>
                      <th style= {{backgroundColor:'orange'}} scope="col">Tax Name</th>
                      <th style= {{backgroundColor:'orange'}} scope="col">Percentage (%)</th>
                      <th style= {{backgroundColor:'orange'}} scope="col">Applies on Sum Of	</th>
                      <th style= {{backgroundColor:'orange'}} scope="col">Applies On</th>
                      <th style= {{backgroundColor:'orange'}} scope="col">Last Updated By</th>
                      <th style= {{backgroundColor:'orange'}} scope="col">Last Updated Date</th>
                      {!(accessType === "View") &&
                        <>
                          <th style= {{backgroundColor:'orange'}} scope="col">Edit</th>
                          <th style= {{backgroundColor:'orange'}} scope="col">Delete</th>
                        </>
                      }
                    </tr>
                  </thead>
                  <tbody>
                    {calculatedTaxDetail.length > 0 ? calculatedTaxDetail.map((val) => (
                      <tr>
                        <td>{val.tax_name}</td>
                        <td>{val.percentage}</td>
                        <td>{val.applied_taxes.map((tax_val, j) => j + 1 < val.applied_taxes.length ? tax_val.value + ", " : tax_val.value)}</td>
                        <td>{val.applies_on}</td>
                        <td>{val.updated_by}</td>
                        <td>{formatDate_withTime(new Date(val.updated_date))}</td>
                        {
                          !(accessType === "View") &&
                          <>
                            <td><span style={{ cursor: "pointer" }} onClick={() => handleUpdate3(val)}><EditIcon /></span></td>
                            <td><span style={{ cursor: "pointer" }} onClick={() => handleDelete3(val._id)}><DeleteForeverIcon /></span></td>

                          </>
                        }
                      </tr>
                    ))
                      : (
                        <tr>
                          <td colSpan="8">No tax present for selected location</td>
                        </tr>
                      )}
                  </tbody>
                </table>
                {!(accessType === "View") && <button type="submit" style={{fontSize:'12px'}} className="btn btn-success" onClick={() => setLgShow3(true)} >
                  Add New
                </button>}
                <Modal
                  size="lg"
                  show={lgShow3}
                  onHide={() => setLgShow3(false)}
                  aria-labelledby="example-modal-sizes-title-lg"
                  dialogClassName="modal-90w"
                >
                  <Modal.Header  style= {{backgroundColor:'orange'}}closeButton >
                    <Modal.Title id="example-modal-sizes-title-lg" >
                      Compound Tax
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body style= {{backgroundColor:'#cfe2f3'}}>
                    <form style={{fontSize:'12px'}}>
                      <table className="table table-borderless">
                        <thead>
                          <tr>
                            <th style= {{backgroundColor:'#cfe2f3'}} scope="col">Tax Name*</th>
                            <th style= {{backgroundColor:'#cfe2f3'}} scope="col">Percentage (%)*</th>
                            <th style= {{backgroundColor:'#cfe2f3'}} scope="col">Applies On Sum of*</th>
                            <th style= {{backgroundColor:'#cfe2f3'}} scope="col">Applies On*</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td style= {{backgroundColor:'#cfe2f3'}}>
                              <input
                                type="text"
                                className="form-control"
                                value={calculatedTaxName}
                                onChange={(e) => handleInputChangeCalculatedTaxName(e.target.value)}
                                isDisabled={updateClicked3 ? true : false}
                                id="tax_name"
                              />
                            </td>
                            <td style= {{backgroundColor:'#cfe2f3'}}>
                              <input
                                type="text"
                                className="form-control"
                                id="Location_name"
                                value={calculatedPercentage}
                                onChange={(e) => setCalculatedPercentage(e.target.value)}
                              />
                            </td>
                            <td style= {{backgroundColor:'#cfe2f3'}}>
                              <MultiSelect
                                options={allTaxesData}
                                value={selected}
                                onChange={setSelected}
                                labelledBy="Select"
                              />
                            </td>
                            <td style= {{backgroundColor:'#cfe2f3'}}>
                              <select className="form-select" value={calculatedApplisOn} onChange={(e) => setCalculatedAppliesOn(e.target.value)}>
                                <option hidden>Select one</option>
                                <option value="Only General">Only General</option>
                                <option value="Only Government">Only Government</option>
                                <option value="Both General & Government">Both General & Government</option>
                              </select>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div className="row pt-2">
                        <div className="col-10 d-flex-wrap"></div>
                        <div className="col-2 d-flex justify-content-end">
                          {
                            updateClicked3 ? (
                              <button className="btn btn-success" style={{fontSize:'12px'}} onClick={(e) => handleUpdateDB3(e)}>
                                Update
                              </button>
                            ) :
                              (
                                <button className="btn btn-success" style={{fontSize:'12px'}} onClick={(e) => handleSubmit3(e)}>
                                  Save
                                </button>
                              )
                          }
                        </div>
                      </div>

                    </form>
                  </Modal.Body>
                </Modal>
              </div>
            </div>
          </>) : ""}
          <div className="col-md-10 mb-4 ">
          </div>
        </div>
      </div>
    </>
  );
}
